import classNames from 'classnames/bind';
import React, { useCallback, useMemo } from 'react';

import CountryUtils from 'src/utils/CountryUtils';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface Props {
  selectedCountry: string | null;
  onCountryChange?: (value?: string) => void;
}

const CountrySelector = ({ selectedCountry, onCountryChange }: Props) => {
  const handleCountryChange = useCallback(
    event => {
      const country = event.currentTarget.value;
      onCountryChange?.(country);
    },
    [onCountryChange],
  );

  const countryComponents = useMemo(
    () =>
      CountryUtils.findAll().map(country => {
        return (
          <option key={country.countryName} value={country.countryName}>
            {country.countryName}
          </option>
        );
      }),
    [],
  );

  return (
    <div className={cx('container')}>
      <div className={cx('selectbox')}>
        <label htmlFor="country">{selectedCountry}</label>
        <select
          className={cx('country')}
          name="country"
          value={selectedCountry || undefined}
          onChange={handleCountryChange}
        >
          {countryComponents}
        </select>
      </div>
    </div>
  );
};

export default CountrySelector;
