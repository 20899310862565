import { Category } from '@bringk/shared';

import MackPackSheetTypeImg from 'src/images/cosmetic/box/maskpack_sheettype.png';
import MackPackSingleBoxImg from 'src/images/cosmetic/box/maskpack_singlebox.png';
import PlasticJarNormalImg from 'src/images/cosmetic/box/plastic_jar_normal.png';
import PlasticJarSmallImg from 'src/images/cosmetic/box/plastic_jar_small.png';
import SerumImg from 'src/images/cosmetic/box/serum.png';
import SingleBoxImg from 'src/images/cosmetic/box/singlebox.png';
import SunCushionImg from 'src/images/cosmetic/box/suncushion_box.png';
import Toner210mlImg from 'src/images/cosmetic/box/toner_210ml.png';
import Toner400mlImg from 'src/images/cosmetic/box/toner_400ml.png';
import TubeImg from 'src/images/cosmetic/box/tube.png';
import ImageRect from 'src/pages/order/summary/ContainerInfo/imageRect';

export const getImagePaths = (): string[] => {
  return [
    MackPackSheetTypeImg,
    MackPackSingleBoxImg,
    PlasticJarNormalImg,
    PlasticJarSmallImg,
    SerumImg,
    SingleBoxImg,
    SunCushionImg,
    Toner210mlImg,
    Toner400mlImg,
    TubeImg,
  ];
};

const getImagePath = (category: Category): string | null => {
  switch (category) {
    case Category.SKIN_CARE_TONER_WATER:
    case Category.SKIN_CARE_LOTION:
    case Category.SKIN_CARE_CLEANSING_WATER:
      return Toner400mlImg;
    case Category.SKIN_CARE_CLEANSING_LIP_AND_EYE:
    case Category.SKIN_CARE_CLEANSING_EYE:
    // case Category.SKIN_CARE_CLEANSING_MASCARA:
    case Category.SKIN_CARE_SUN_BLOCK:
    case Category.SKIN_CARE_SUN_STICK:
      return Toner210mlImg;
    case Category.SKIN_CARE_ESSENCE:
    case Category.SKIN_CARE_OIL:
      return SerumImg;
    case Category.SKIN_CARE_CREAM_FACE:
    case Category.SKIN_CARE_CREAM_NECK:
      return PlasticJarNormalImg;
    case Category.SKIN_CARE_CREAM_EYE:
    case Category.SKIN_CARE_LIP_SCRUB:
    case Category.SKIN_CARE_LIP_MOISTURIZER:
    case Category.SKIN_CARE_LIP_MASK:
      return PlasticJarSmallImg;
    case Category.SKIN_CARE_MASK_SHEET:
      return MackPackSheetTypeImg;
    case Category.SKIN_CARE_MASK_WASH_OFF:
    case Category.SKIN_CARE_MASK_SLEEPING:
    case Category.SKIN_CARE_MASK_PEEL_OFF:
    case Category.SKIN_CARE_CLEANSING_FOAM:
    case Category.SKIN_CARE_PEELING:
    case Category.SKIN_CARE_SUN_SPRAY:
    case Category.SKIN_CARE_MIST_MOISTURIZER:
      return TubeImg;
    case Category.SKIN_CARE_CLEANSING_OIL:
    case Category.SKIN_CARE_CLEANSING_MILK:
      return Toner400mlImg;
    case Category.SKIN_CARE_MASK_NOSE:
      return MackPackSheetTypeImg;
    case Category.SKIN_CARE_TONER_PAD:
      return SingleBoxImg;
    case Category.SKIN_CARE_SUN_CUSHION:
      return SunCushionImg;
    default:
      return null;
  }
};

const getImageRect = (imagePath: string): ImageRect | null => {
  switch (imagePath) {
    case MackPackSheetTypeImg: // 'src/images/container/maskpack_sheettype.png';
      return { x: 92, y: 115, width: 131, height: 104 };
    case MackPackSingleBoxImg: // 'src/images/container/maskpack_singlebox.png';
      return { x: 131, y: 150, width: 135, height: 105 };
    case PlasticJarNormalImg: // 'src/images/container/plastic_jar_normal.png';
      return { x: 166, y: 113, width: 254, height: 198 };
    case PlasticJarSmallImg: // 'src/images/container/plastic_jar_small.png';
      return { x: 153, y: 163, width: 119, height: 92 };
    case SerumImg: // 'src/images/container/serum.png';
      return { x: 159, y: 120, width: 137, height: 106 };
    case SingleBoxImg: // 'src/images/container/singlebox.png';
      return { x: 144, y: 114, width: 242, height: 185 };
    case Toner210mlImg: // 'src/images/container/toner_210ml.png';
      return { x: 110, y: 135, width: 136, height: 105 };
    case Toner400mlImg: // 'src/images/container/toner_400ml.png';
      return { x: 109, y: 135, width: 138, height: 107 };
    case TubeImg: // 'src/images/container/tube.png';
      return { x: 77, y: 129, width: 117, height: 91 };
    case SunCushionImg:
      return { x: 132, y: 169, width: 285, height: 203 };
    default:
      return null;
  }
};

export const getBoxInfo = (
  category: Category,
): { imagePath: string; imageRect: ImageRect } | null => {
  const imagePath = getImagePath(category);
  const imageRect = (imagePath && getImageRect(imagePath)) || null;

  if (imagePath && imageRect) {
    return {
      imagePath,
      imageRect,
    };
  } else {
    return null;
  }
};
