import classNames from 'classnames/bind';
import React from 'react';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface Props {
  message: string;
  required: boolean;
}

const Label = (props: Props) => {
  return (
    <label className={cx('label')}>
      {props.message}
      {props.required && <span className={cx('required')}> *</span>}
    </label>
  );
};

Label.defaultProps = {
  required: false,
};

export default Label;
