import { plainToClass } from 'class-transformer';
import { observable } from 'mobx';
import { actionAsync, task } from 'mobx-utils';

import { Template, TemplateItem, Product, Producer, Database } from '@bringk/shared';

import AxiosUtils from 'src/utils/AxiosUtils';
import TemplateItemUtils from 'src/utils/TemplateItemUtils';

class OrderHistoryStore {
  @observable id?: string;
  @observable product: Product;
  @observable producer: Producer;
  @observable template?: Template;
  @observable requiredTemplateItems: TemplateItem[];
  @observable unrequiredTemplateItems: TemplateItem[];

  constructor() {
    this.id = undefined;
    this.product = new Product();
    this.producer = new Producer();
    this.template = undefined;
    this.requiredTemplateItems = [];
    this.unrequiredTemplateItems = [];
  }

  @actionAsync
  get = async (orderId: string) => {
    const response = await task(AxiosUtils.get(`/orders/${orderId}`));
    const order = response.data.contents;
    this.id = order.id;
    this.product = plainToClass(Product, order.product as Product);
    this.producer = plainToClass(Producer, order.producer as Producer);

    const template = Database.templates.get(this.product.category!);
    if (!template) {
      const message = `template of ${this.product.category!} not exists.`;
      throw new Error(message);
    }
    this.template = template;
    this.requiredTemplateItems = TemplateItemUtils.sort(template.items).filter(
      templateItem => templateItem.isRequired,
    );
    this.unrequiredTemplateItems = TemplateItemUtils.sort(template.items).filter(
      templateItem => !templateItem.isRequired,
    );
  };
}

export default OrderHistoryStore;
