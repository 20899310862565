// import classNames from 'classnames';
import React from 'react';

import DebugImageRects from 'src/components/DebugImageRects/index';
import logo from 'src/logo.svg';

// import styles from './index.module.scss';
// const cx = classNames.bind(styles);

interface Props {}

const DebugPage = (props: Props) => {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Profiles : {process.env.REACT_APP_PROFILES}</p>
        <p>API : {process.env.REACT_APP_API_ROOT}</p>
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
      <DebugImageRects />
    </div>
  );
};

export default DebugPage;
