"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const TemplateItemType_1 = require("../TemplateItemType");
const AbstractTemplateItemWorker_1 = require("./AbstractTemplateItemWorker");
const ValidationChainer_1 = require("./ValidationChainer");
class ProducerInformationWorker extends AbstractTemplateItemWorker_1.AbstractTemplateItemWorker {
    support(templateItem) {
        return templateItem.type === TemplateItemType_1.TemplateItemType.PRODUCER_INFORMATION;
    }
    initialize(templateItem, producer, product) { }
    validate(templateItem, producer, product) {
        ValidationChainer_1.ValidationChainer.property(producer.name)
            .customError('ProducerNameRequired')
            .propertyName('Producer name')
            .required()
            .string()
            .stringLimit();
        ValidationChainer_1.ValidationChainer.property(producer.email)
            .customError('ProducerEmailRequired')
            .propertyName('Producer email')
            .required()
            .string()
            .stringLimit();
        ValidationChainer_1.ValidationChainer.property(producer.country)
            .customError('ProducerCountryRequired')
            .propertyName('Producer country')
            .required()
            .string()
            .stringLimit();
        ValidationChainer_1.ValidationChainer.property(producer.telephone)
            .customError('ProducerTelephoneRequired')
            .propertyName('Producer telephone')
            .required()
            .string()
            .stringLimit();
        ValidationChainer_1.ValidationChainer.property(producer.businessRegistrationNumber)
            .customError('ProducerBusinessRegistrationNumberRequired')
            .propertyName('Producer business registration number')
            .required()
            .string()
            .stringLimit();
        ValidationChainer_1.ValidationChainer.property(producer.businessCardAttachment)
            .customError('ProducerBusinessCardImageRequired')
            .propertyName('Producer business card image')
            .required()
            .string()
            .stringLimit();
        ValidationChainer_1.ValidationChainer.property(producer.acceptance)
            .customError('ProducerAcceptanceRequired')
            .propertyName('Producer acceptance')
            .required();
    }
}
exports.ProducerInformationWorker = ProducerInformationWorker;
