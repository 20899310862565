import { useCallback, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import useFirebaseStorage from 'src/hooks/useFirebaseStorage';
import FileUtils from 'src/utils/FileUtils';
import FirebaseApplication from 'src/utils/FirebaseApplication';

export type OnUploadSuccess = (storagePath: string) => void;
export type OnUploadFailure = (storagePath: string) => void;

interface Props {
  key?: string; // 컴포넌트가 재사용되는데 defaultStoragePath 가 업데이트되어야 할 경우 고유키를 사용
  defaultStoragePath?: string | null; // 상태 복구시에 사용할 storage path
  storagePathSuffix: string; // 새로 path 를 발급할 때 앞에 붙일 path
  placeholderImageSrc?: string; // 아무 이미지도 없을 경우 보여줄 이미지
  onUploadSuccess?: OnUploadSuccess;
  onUploadFailure?: OnUploadFailure;
}

const useImageUploader = ({
  defaultStoragePath,
  storagePathSuffix,
  placeholderImageSrc,
  onUploadSuccess,
  onUploadFailure,
}: Props) => {
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [tempImageSrc, setTempImageSrc] = useState<string>('');
  const [storagePath, setStoragePath] = useState<string | null>(defaultStoragePath || null);
  const imageSrc = useFirebaseStorage(storagePath).url || tempImageSrc || placeholderImageSrc;

  useEffect(() => {
    setStoragePath(defaultStoragePath || null);
    setTempImageSrc('');
  }, [defaultStoragePath, setStoragePath, setTempImageSrc]);

  const imageUploadTask = useCallback(
    async (files: FileList | null) => {
      const compressResult = await FileUtils.compressMulti(files);
      if (!compressResult || !compressResult[0]) return;
      const imageBase64 = compressResult[0].base64;
      const imageFile = compressResult[0].compressedFile;
      setTempImageSrc(imageBase64);
      setStoragePath(null);
      try {
        const newStoragePath = await FirebaseApplication.storage()
          .ref()
          .child(`${storagePathSuffix}${uuid()}`)
          .put(imageFile)
          .then(snapshot => snapshot.metadata.fullPath);
        setStoragePath(newStoragePath);
        onUploadSuccess?.(newStoragePath);
      } catch (error) {
        onUploadFailure?.(error);
      }
    },
    [storagePathSuffix, setTempImageSrc, setStoragePath, onUploadSuccess, onUploadFailure],
  );

  const handleInputChanged = useCallback(
    async (event: React.FormEvent<HTMLInputElement>) => {
      setIsUploading(true);
      await imageUploadTask(event.currentTarget.files);
      setIsUploading(false);
    },
    [setIsUploading, imageUploadTask],
  );

  return {
    imageSrc,
    isLoading: isUploading,
    handleInputChanged,
  };
};

export default useImageUploader;
