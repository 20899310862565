import classNames from 'classnames/bind';
import { observer } from 'mobx-react';
import React, { useCallback, useState } from 'react';

import { TemplateItem, ArrayUtils, Database } from '@bringk/shared';

import IngredientPickerComponent from 'src/components/IngredientPicker';
import SelectedLists from 'src/components/IngredientPicker/selectedLists';
import useFormat from 'src/hooks/useFormat';
import useStore from 'src/hooks/useStore';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface Props {
  templateItem: TemplateItem;
}

const MAIN_PROPERTY = 'mainConceptIngredients';
const SUB_PROPERTY = 'subConceptIngredients';

const IngredientTemplate = ({ templateItem }: Props) => {
  const { orderStore } = useStore();
  const { formatKeyword } = useFormat();
  const targetProperty = templateItem.property;
  const selectedMainIds: string[] = orderStore.product[MAIN_PROPERTY] || [];
  const selectedSubIds: string[] = orderStore.product[SUB_PROPERTY] || [];

  const handleIngredientIdsChanged = useCallback(
    (mainIds: string[], subIds: string[]) => {
      orderStore.setProductProperty(MAIN_PROPERTY, mainIds);
      orderStore.setProductProperty(SUB_PROPERTY, subIds);
    },
    [orderStore],
  );

  const [isVisible, setVisible] = useState(false);

  const db = Database.ingredients;
  const getIngredients = useCallback(
    async (ids: string[]) => ArrayUtils.filterNotNull(ids.map(id => db.get(id))),
    [db],
  );

  const handleShow = useCallback(() => setVisible(isVisible => !isVisible), [setVisible]);
  return (
    <div className={cx('container')}>
      <div onClick={handleShow}>
        <SelectedLists
          {...{
            getIngredients,
            selectedMainIds,
            selectedSubIds,
            isViewOnly: true,
            onPlaceholderClick: handleShow,
          }}
        />
      </div>
      <button className={cx('select-button')} onClick={handleShow}>
        {formatKeyword('SelectIngredients')}
      </button>
      {isVisible && (
        <IngredientPickerComponent
          category={orderStore.product.category}
          onClose={(mainIds, subIds) => {
            handleIngredientIdsChanged(mainIds, subIds);
            setVisible(false);
          }}
          {...{ selectedMainIds, selectedSubIds }}
        />
      )}
    </div>
  );
};

export default observer(IngredientTemplate);
