"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const TemplateItemType_1 = require("../TemplateItemType");
const AbstractTemplateItemWorker_1 = require("./AbstractTemplateItemWorker");
const ValidationChainer_1 = require("./ValidationChainer");
class SheetShapeWorker extends AbstractTemplateItemWorker_1.AbstractTemplateItemWorker {
    support(templateItem) {
        return templateItem.type === TemplateItemType_1.TemplateItemType.SHEET_SHAPE;
    }
    initialize(templateItem, producer, product) {
        product.sheetShape = null;
    }
    validate(templateItem, producer, product) {
        ValidationChainer_1.ValidationChainer.property(product.sheetShape)
            .propertyName('sheet shape')
            .stringOptional()
            .stringLimit();
    }
}
exports.SheetShapeWorker = SheetShapeWorker;
