import classNames from 'classnames/bind';
import { observer } from 'mobx-react';
import React, { useCallback, useMemo } from 'react';

import { ArrayUtils } from '@bringk/shared';

import useFormat from 'src/hooks/useFormat';
import useOrderStep from 'src/hooks/useOrderStep';
import useOrderTemplate from 'src/hooks/useOrderTemplate';
import useStore from 'src/hooks/useStore';
import { ReactComponent as CheckSvg } from 'src/images/svg/icon_check_small.svg';
import { ReactComponent as PrevSvg } from 'src/images/svg/icon_navigation_back.svg';
import { ReactComponent as NextSvg } from 'src/images/svg/icon_navigation_next.svg';
import OrderStepTitle from 'src/pages/order/title/index';
import { CategoryUtils } from 'src/utils/CategoryUtils';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface Props {
  stepId: number;
  subStepId: number;
  isFirst: boolean;
  isFinal: boolean;
  handlePrevClick: () => void;
  handleNextClick: () => void;
}

const WIDTH_PER_LEVEL_LARGE = 125;
const WIDTH_PER_LEVEL_MEDIUM = 90;
const WIDTH_PER_LEVEL_SMALL = 70;
const ITEM_RADIUS = 12;

const calcPercentage = (
  stepId: number,
  subStepId: number,
  subStepMax: number,
  widthPerLevel: number,
) => {
  if (stepId === 1) {
    return 0;
  }
  const main = (stepId - 1) * widthPerLevel;
  const sub = (subStepId / subStepMax) * (widthPerLevel - ITEM_RADIUS * 2) + ITEM_RADIUS;
  return main + (Number.isNaN(sub) ? 0 : sub);
};

const Navigation = (props: Props) => {
  const { orderStore } = useStore();
  const category = orderStore.product.category || null;
  const { getSubStepMaxId } = useOrderTemplate(category);
  const { format, formatKeyword } = useFormat();
  const { setStep } = useOrderStep();
  const { stepId, subStepId } = props;

  const subStepMaxId = getSubStepMaxId(stepId);

  const percentageLarge = useMemo(() => {
    return calcPercentage(stepId, subStepId, subStepMaxId, WIDTH_PER_LEVEL_LARGE);
  }, [stepId, subStepId, subStepMaxId]);
  const percentageMedium = useMemo(() => {
    return calcPercentage(stepId, subStepId, subStepMaxId, WIDTH_PER_LEVEL_MEDIUM);
  }, [stepId, subStepId, subStepMaxId]);
  const percentageSmall = useMemo(() => {
    return calcPercentage(stepId, subStepId, subStepMaxId, WIDTH_PER_LEVEL_SMALL);
  }, [stepId, subStepId, subStepMaxId]);

  const handleCategoryStep = useCallback(
    (stepId: number) => () => {
      if (stepId <= 1) {
        setStep(1, -1);
      } else if (CategoryUtils.isLast(category)) {
        setStep(stepId, 0);
      }
    },
    [category, setStep],
  );

  return (
    <div className={cx('container')}>
      <div
        className={cx('button', 'prev', { disable: props.isFirst })}
        onClick={props.handlePrevClick}
      >
        <PrevSvg />
        {formatKeyword('Prev')}
      </div>

      {stepId === 4 ? (
        <OrderStepTitle title={format('Texts.OrderCheckUp')} />
      ) : (
        <div className={cx('main-navigation')}>
          <nav className={cx('navigation')}>
            <div className={cx('progress-frame')}>
              <div className={cx('progress', 'large')} style={{ width: percentageLarge }} />
              <div className={cx('progress', 'medium')} style={{ width: percentageMedium }} />
              <div className={cx('progress', 'small')} style={{ width: percentageSmall }} />
            </div>
            <li>
              {ArrayUtils.numberStream(4)
                .map(i => i + 1)
                .map(id => (
                  <ul key={id} className={cx('item', { selected: stepId >= id })}>
                    <div
                      className={cx('level', { progressed: stepId >= id })}
                      onClick={handleCategoryStep(id)}
                    >
                      {id < stepId ? <CheckSvg /> : <>{id}</>}
                    </div>
                    <span className={cx('text')}>{format(`Steps.0${id}.Message`)}</span>
                  </ul>
                ))}
            </li>
          </nav>
        </div>
      )}

      <div
        className={cx('button', 'next', { disable: props.isFinal })}
        onClick={props.handleNextClick}
      >
        {props.isFinal ? formatKeyword('Done') : formatKeyword('Next')}
        <NextSvg />
      </div>
    </div>
  );
};

export default observer(Navigation);
