import React, { useCallback } from 'react';

import { SelectableItem } from '@bringk/shared';

import CheckBox from 'src/components/CheckBox';

interface OwnProps {
  selectedItemKeys: string[];
  selectableItems: SelectableItem[];
  handleItemAdded: (itemKey: string) => void;
  handleItemRemoved: (itemKey: string) => void;
  customItemCreator?: (itemKey: string, itemLabel: string) => JSX.Element;
}

type Props = OwnProps;

const CheckBoxes = ({
  selectedItemKeys,
  selectableItems,
  handleItemAdded,
  handleItemRemoved,
  customItemCreator,
}: Props) => {
  const onClicked = useCallback(
    (clickedItemKey: string, isSelected: boolean) =>
      isSelected ? handleItemAdded(clickedItemKey) : handleItemRemoved(clickedItemKey),
    [handleItemAdded, handleItemRemoved],
  );

  return (
    <>
      {selectableItems.map(selectableItem => {
        const { key, label, tooltip } = selectableItem;
        const isChecked = selectedItemKeys.includes(key);
        return (
          <CheckBox
            key={key}
            {...{
              isChecked,
              label: customItemCreator?.(key, label) || label,
              tooltip,
            }}
            onChanged={isSelected => onClicked(key, isSelected)}
          />
        );
      })}
    </>
  );
};

export default CheckBoxes;
