import classNames from 'classnames/bind';
import React from 'react';
import { ChromePicker, ChromePickerProps } from 'react-color';

import styles from './index.module.scss';
import './index.override.scss';

const cx = classNames.bind(styles);

interface Props extends ChromePickerProps {}

const ColorPickerComponent = (props: Props) => {
  return (
    <div className={cx('container')}>
      <ChromePicker {...props} />
    </div>
  );
};

export default ColorPickerComponent;
