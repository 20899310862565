"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class ArrayUtils {
    static isEmpty(array) {
        return !array || !array.length;
    }
    static isNotEmpty(array) {
        return !this.isEmpty(array);
    }
    static getLast(array) {
        if (this.isEmpty(array))
            return null;
        return array[array.length - 1];
    }
    static getLastIndex(array) {
        if (this.isEmpty(array))
            return 0;
        else
            return array.length - 1;
    }
    static shuffle(a) {
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
    }
    static numberStream(length) {
        return [...Array(length).keys()];
    }
    static booleanStream(length, init) {
        return new Array(length).fill(init);
    }
    static enumKeyStream(enumertator) {
        return Object.keys(enumertator)
            .filter(key => !Number(key))
            .map(key => key);
    }
    static allShallowEqual(array1, array2) {
        return (array1.length === array2.length &&
            this.numberStream(array1.length).every(offset => array1[offset] === array2[offset]));
    }
    static filterNotNull(array) {
        return array.filter((item) => item !== null);
    }
    static filterNotUndefined(array) {
        return array.filter((item) => item !== undefined);
    }
}
exports.ArrayUtils = ArrayUtils;
