import React, { useContext } from 'react';

import { TemplateItem, TemplateItemType } from '@bringk/shared';

import ReceiptRecordKeyValue from 'src/components/ReceiptRecordKeyValue/index';
import useFormat from 'src/hooks/useFormat';
import KeyValueInfo from 'src/pages/order/summary/KeyValueInfo';
import TargetImage from 'src/pages/order/templates/TargetImage';
import { getContainerInfo } from 'src/utils/ContainerImagesUtils';
import AbstractTemplateItemSupporter from 'src/utils/template/AbstractTemplateItemSupporter';
import TemplateItemUtils from 'src/utils/TemplateItemUtils';

import { OrderSourceContext } from './OrderSource';

interface Props {
  templateItem: TemplateItem;
}

const TargetContainerSummaryComponent = () => {
  const { formatKeyword } = useFormat();
  const { product } = useContext(OrderSourceContext);
  const contents = [
    { key: formatKeyword('Image'), value: product.containerImage },
    { key: formatKeyword('Color'), value: product.containerColor },
    { key: formatKeyword('ReferenceBrandName'), value: product.containerRefBrand },
    {
      key: formatKeyword('ReferenceProductName'),
      value: product.containerRefProduct,
    },
  ].filter(content => Boolean(content.value));
  return <KeyValueInfo contents={contents} />;
};

const TargetContainerReceiptComponent = ({ templateItem }: Props) => {
  const { format, formatKeyword } = useFormat();
  const { product } = useContext(OrderSourceContext);
  const contents = [
    { key: formatKeyword('Image'), value: product.containerImage },
    { key: formatKeyword('Color'), value: product.containerColor },
    { key: formatKeyword('ReferenceBrandName'), value: product.containerRefBrand },
    {
      key: formatKeyword('ReferenceProductName'),
      value: product.containerRefProduct,
    },
  ].filter(content => Boolean(content.value));
  const shortTitleId = TemplateItemUtils.getShortTitle(templateItem.type);
  return <ReceiptRecordKeyValue label={format(shortTitleId)} contents={contents} />;
};

export default class TargetContainerSupporter extends AbstractTemplateItemSupporter {
  support(templateItemType: TemplateItemType): boolean {
    return templateItemType === TemplateItemType.TARGET_CONTAINER;
  }
  render(templateItem: TemplateItem): React.ReactNode {
    return (
      <TargetImage
        templateItem={templateItem}
        imageProperty={'containerImage'}
        colorProperty={'containerColor'}
        refBrandProperty={'containerRefBrand'}
        refProductProperty={'containerRefProduct'}
        getSuggestionImagePath={getContainerInfo}
      />
    );
  }
  renderSummary(): React.ReactNode | null {
    return <TargetContainerSummaryComponent />;
  }
  renderReceipt(templateItem: TemplateItem): React.ReactNode | null {
    return <TargetContainerReceiptComponent templateItem={templateItem} />;
  }
}
