import classNames from 'classnames/bind';
import React from 'react';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface Props {
  label: string;
  value: any;
  sequence?: boolean;
  description?: string;
}

const ReceiptRecord = (props: Props) => {
  return (
    <div className={cx('receipt-record')}>
      <div className={cx('record-key')}>{props.label}</div>
      <div className={cx('record-value')}>
        {props.value && Array.isArray(props.value)
          ? props.value
              .filter(value => Boolean(value))
              .map((value: any, index: number) => {
                return (
                  <div className={cx('record-value-element')} key={value}>
                    {props.sequence ? (
                      <>
                        {`${index + 1}. `}
                        {value}
                      </>
                    ) : (
                      value
                    )}
                  </div>
                );
              })
          : props.value && <div className={cx('record-value-element')}>{props.value}</div>}
        {props.description && <div className={cx('description')}>{`* ${props.description}`}</div>}
      </div>
    </div>
  );
};

export default ReceiptRecord;
