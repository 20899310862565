"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class CONSTANTS {
}
exports.CONSTANTS = CONSTANTS;
CONSTANTS.BUCKET = 'bringk.appspot.com';
CONSTANTS.COLLECTION_ORDERS = 'orders';
CONSTANTS.COLLECTION_PRODUCTS = 'products';
CONSTANTS.COLLECTION_PRODUCERS = 'producers';
CONSTANTS.MAX_STRING_LENGTH = 60;
CONSTANTS.MAX_TEXT_LENGTH = 1000;
