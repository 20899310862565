import classNames from 'classnames/bind';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { CONSTANTS } from '@bringk/shared';

import useFormat from 'src/hooks/useFormat';
import { ReactComponent as NoteSvg } from 'src/images/svg/icon_note.svg';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface Props {}

const OrderSearch = (props: Props) => {
  const { format } = useFormat();
  const [orderId, setOrderId] = useState<string>();
  const history = useHistory();

  const handleSearchValueChanged = useCallback(event => {
    setOrderId(event.currentTarget.value);
  }, []);

  const onSearchClick = useCallback(() => {
    history.push(`/history/orders/${orderId}`);
  }, [history, orderId]);

  return (
    <div className={cx('container')}>
      <div className={cx('title')}>
        <div className={cx('icon')}>
          <NoteSvg />
        </div>
        <div className={cx('text')}>{format('Keywords.OrderHistory')}</div>
      </div>
      <div className={cx('search-container')}>
        <input
          type="text"
          className={cx('input')}
          maxLength={CONSTANTS.MAX_STRING_LENGTH}
          value={orderId}
          onChange={handleSearchValueChanged}
          placeholder={format('Texts.SearchOrder')}
          {...props}
        ></input>
        <div className={cx('search-button')} onClick={onSearchClick}>
          {format('Keywords.Search')}
        </div>
      </div>
    </div>
  );
};

export default OrderSearch;
