"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const TemplateItemType_1 = require("../TemplateItemType");
const AbstractTemplateItemWorker_1 = require("./AbstractTemplateItemWorker");
const ValidationChainer_1 = require("./ValidationChainer");
class SunscreenTypeWorker extends AbstractTemplateItemWorker_1.AbstractTemplateItemWorker {
    support(templateItem) {
        return templateItem.type === TemplateItemType_1.TemplateItemType.SUNSCREEN_TYPE;
    }
    initialize(templateItem, producer, product) {
        product.sunscreenType = null;
    }
    validate(templateItem, producer, product) {
        ValidationChainer_1.ValidationChainer.property(product.sunscreenType)
            .propertyName('sunscreen')
            .stringOptional()
            .stringLimit();
    }
}
exports.SunscreenTypeWorker = SunscreenTypeWorker;
