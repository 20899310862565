import classNames from 'classnames/bind';
import { observer } from 'mobx-react';
import React from 'react';

import { ArrayUtils, TemplateItemType } from '@bringk/shared';

import useStore from 'src/hooks/useStore';
import { ReactComponent as BoxSvg } from 'src/images/svg/icon_color_box.svg';
import { ReactComponent as CaseSvg } from 'src/images/svg/icon_color_case.svg';
import { ReactComponent as LiquidSvg } from 'src/images/svg/icon_color_liquid.svg';

import styles from './colors.module.scss';
const cx = classNames.bind(styles);

interface Props {
  onEditClicked?: (templateItemType?: TemplateItemType) => void;
}

const Colors = ({ onEditClicked }: Props) => {
  // TODO: 프리뷰 : 메인 서브 성분 및 특징
  // TODO: 그 아래 : 무첨가 희망 성분
  const { orderStore } = useStore();

  const { boxColor, containerColor, contentColor } = orderStore.product;

  const colors = [boxColor, containerColor, contentColor];
  const svgs = [BoxSvg, CaseSvg, LiquidSvg];

  return (
    <>
      {ArrayUtils.numberStream(3).map(offset => {
        const Svg = svgs[offset];
        return (
          <Svg
            key={offset}
            className={cx('item')}
            style={{
              width: 20,
              height: 20,
              fill: colors[offset] || '#ffffff',
              stroke: '#b2b2b2',
              strokeWidth: '1.5px',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
            onClick={() => {
              switch (offset) {
                case 0:
                  onEditClicked?.(TemplateItemType.TARGET_BOX);
                  break;
                case 1:
                  onEditClicked?.(TemplateItemType.TARGET_CONTAINER);
                  break;
                case 2:
                  onEditClicked?.(TemplateItemType.COLOR);
                  break;
              }
            }}
          />
        );
      })}
    </>
  );
};

export default observer(Colors);
