import React, { useContext } from 'react';

import { TemplateItem, ArrayUtils, TemplateItemType, Database } from '@bringk/shared';

import ReceiptIngredientsRecord from 'src/components/ReceiptIngredientsRecord/index';
import useFormat from 'src/hooks/useFormat';
import TextInfo from 'src/pages/order/summary/TextInfo';
import Ingredient from 'src/pages/order/templates/Ingredient';
import { CategoryUtils } from 'src/utils/CategoryUtils';
import AbstractTemplateItemSupporter from 'src/utils/template/AbstractTemplateItemSupporter';

import { OrderSourceContext } from './OrderSource';

const IngredientsSummaryComponent = () => {
  const { formatPrefix, formatKeyword } = useFormat();
  const {
    product: { mainConceptIngredients, subConceptIngredients },
  } = useContext(OrderSourceContext);
  if (
    ArrayUtils.isNotEmpty(mainConceptIngredients) ||
    ArrayUtils.isNotEmpty(subConceptIngredients)
  ) {
    return (
      <>
        <TextInfo bold value={`* ${formatKeyword('MainConceptIngredients')}`} />
        <TextInfo
          sequence
          value={mainConceptIngredients?.map(id => formatPrefix('Ingredients.', id))}
        />
        <div style={{ height: '10px' }} />
        <TextInfo bold value={`* ${formatKeyword('SubConceptIngredients')}`} />
        <TextInfo
          sequence
          value={subConceptIngredients?.map(id => formatPrefix('Ingredients.', id))}
        />
      </>
    );
  }
  return null;
};

const IngredientsReceiptComponent = () => {
  const { formatPrefix, formatKeyword } = useFormat();
  const { product } = useContext(OrderSourceContext);
  const { category, features, mainConceptIngredients, subConceptIngredients } = product;

  const createReceiptValue = (ingredientIds?: string[] | null) => {
    const topCategory = CategoryUtils.getTopParent(category);
    if (!topCategory || !ingredientIds) return;
    return ingredientIds.map(ingredientId => {
      const name = formatPrefix('Ingredients.', ingredientId);
      const groupIds = Database.ingredients
        .getGroups(topCategory)
        .filter(group => group.ingredientIds?.includes(ingredientId))
        .map(group => group.id);
      return {
        name,
        hints: groupIds.map(groupId => {
          const isMatchedFeature = features?.includes(groupId);
          return {
            hint: formatPrefix('IngredientGroupFilters.', groupId),
            matched: isMatchedFeature,
          };
        }),
      };
    });
  };

  return (
    <>
      <ReceiptIngredientsRecord
        sequence
        label={formatKeyword('MainConceptIngredients')}
        value={createReceiptValue(mainConceptIngredients)}
        description={product.description.mainConceptIngredients}
      />
      <ReceiptIngredientsRecord
        sequence
        label={formatKeyword('SubConceptIngredients')}
        value={createReceiptValue(subConceptIngredients)}
        description={product.description.subConceptIngredients}
      />
    </>
  );
};

export default class IngredientsSupporter extends AbstractTemplateItemSupporter {
  support(templateItemType: TemplateItemType): boolean {
    return templateItemType === TemplateItemType.INGREDIENTS;
  }
  render(templateItem: TemplateItem): React.ReactNode {
    return <Ingredient templateItem={templateItem} />;
  }
  renderSummary(): React.ReactNode | null {
    return <IngredientsSummaryComponent />;
  }
  renderReceipt(): React.ReactNode | null {
    return <IngredientsReceiptComponent />;
  }
}
