import React, { useContext } from 'react';

import { TemplateItem, ArrayUtils, TemplateItemType } from '@bringk/shared';

import ReceiptRecord from 'src/components/ReceiptRecord/index';
import useFormat from 'src/hooks/useFormat';
import TextInfo from 'src/pages/order/summary/TextInfo';
import Features from 'src/pages/order/templates/Features';
import AbstractTemplateItemSupporter from 'src/utils/template/AbstractTemplateItemSupporter';

import { OrderSourceContext } from './OrderSource';

const FeaturesSummaryComponent = () => {
  const { formatPrefix } = useFormat();
  const {
    product: { features },
  } = useContext(OrderSourceContext);
  if (ArrayUtils.isNotEmpty(features)) {
    return (
      <>
        <TextInfo
          sequence
          value={features?.map(id => formatPrefix('IngredientGroupFilters.', id))}
        />
      </>
    );
  }
  return null;
};

const FeaturesReceiptComponent = () => {
  const { formatPrefix, formatKeyword } = useFormat();
  const {
    product: { features },
  } = useContext(OrderSourceContext);
  return (
    <>
      <ReceiptRecord
        sequence
        label={formatKeyword('Features')}
        value={features?.map(id => formatPrefix('IngredientGroupFilters.', id))}
      />
    </>
  );
};

export default class FeaturesSupporter extends AbstractTemplateItemSupporter {
  support(templateItemType: TemplateItemType): boolean {
    return templateItemType === TemplateItemType.FEATURES;
  }
  render(templateItem: TemplateItem): React.ReactNode {
    return <Features templateItem={templateItem} />;
  }
  renderSummary(): React.ReactNode | null {
    return <FeaturesSummaryComponent />;
  }
  renderReceipt(): React.ReactNode | null {
    return <FeaturesReceiptComponent />;
  }
  getTitleId(): string {
    return 'Order.Template.Features.Title';
  }
  getShortTitleId(): string {
    return 'Order.Template.Features.ShortTitle';
  }
}
