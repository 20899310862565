import classNames from 'classnames/bind';
import React from 'react';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface Props {
  onChange: (value: string) => void;
}

const ColorPallet = (props: Props) => {
  return (
    <div className={cx('container')}>
      <div className={cx('pallet-00', 'cell')} onClick={() => props.onChange('#ffe8e8')} />
      <div className={cx('pallet-01', 'cell')} onClick={() => props.onChange('#feffed')} />
      <div className={cx('pallet-02', 'cell')} onClick={() => props.onChange('#e5ffd8')} />
      <div className={cx('pallet-03', 'cell')} onClick={() => props.onChange('#e6ebff')} />
      <div className={cx('pallet-04', 'cell')} onClick={() => props.onChange('#efe2ff')} />
      <div className={cx('pallet-05', 'cell')} onClick={() => props.onChange('#efefef')} />
    </div>
  );
};

export default ColorPallet;
