import classNames from 'classnames/bind';
import { observer } from 'mobx-react';
import React from 'react';

import { Category } from '@bringk/shared';

import CategoryCard from 'src/components/CategoryCard';
import useFormat from 'src/hooks/useFormat';
import OrderStepPageNumber from 'src/pages/order/pageNumber';
import OrderStepTitle from 'src/pages/order/title';
import { CategoryUtils } from 'src/utils/CategoryUtils';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface Props {
  subStepId: number;
  candidateCategory: Category | null;
  onCandidateCategoryChanged?: (category: Category) => void;
}

const OrderStep01 = ({ subStepId, candidateCategory, onCandidateCategoryChanged }: Props) => {
  const { format, formatPrefix, formatKeyword } = useFormat();
  const selectedCategory = CategoryUtils.getParentsAndSelf(candidateCategory)[subStepId - 1];

  return (
    <div className={cx('container')}>
      <div className={cx('title')}>
        <OrderStepTitle
          title={format('Order.CategoryTitle', {
            category: selectedCategory
              ? formatPrefix('Category.', selectedCategory)
              : formatKeyword('Cosmetics'),
          })}
        />
      </div>
      <div className={cx('categories')}>
        {CategoryUtils.getChildren(selectedCategory).map(category => {
          return (
            <CategoryCard
              key={category.valueOf()}
              category={category}
              selected={CategoryUtils.getParentsAndSelf(candidateCategory).includes(category)}
              onClick={() => onCandidateCategoryChanged?.(category)}
            />
          );
        })}
        {/* Coming soon */}
        {!selectedCategory && (
          <>
            <CategoryCard selected={false} comingSoon={true} category={Category.MAKE_UP} />
            <CategoryCard selected={false} comingSoon={true} category={Category.ETC} />
          </>
        )}
      </div>
      <OrderStepPageNumber current={CategoryUtils.getDepth(selectedCategory)} />
    </div>
  );
};

export default observer(OrderStep01);
