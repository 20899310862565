import { getTimeZones } from '@vvo/tzdb';
import { isNull, reject } from 'lodash';
import React, { useContext, useMemo } from 'react';

import { TemplateItem, TemplateItemType } from '@bringk/shared';

import ReceiptRecord from 'src/components/ReceiptRecord/index';
import useFormat from 'src/hooks/useFormat';
import TextInfo from 'src/pages/order/summary/TextInfo';
import TargetLaunchDate from 'src/pages/order/templates/TargetLaunchDate';
import AbstractTemplateItemSupporter from 'src/utils/template/AbstractTemplateItemSupporter';
import TemplateItemUtils from 'src/utils/TemplateItemUtils';
import TimeUtils from 'src/utils/TimeUtils';

import { OrderSourceContext } from './OrderSource';

interface Props {
  templateItem: TemplateItem;
}

const TargetLaunchDateSummaryComponent = ({ templateItem }: Props) => {
  const launchDate = useLaunchDateText();
  const timeZone = useTimeZoneText();
  const texts = reject([launchDate, timeZone], isNull);
  return (
    <>
      {texts.map((text, offset) => (
        <TextInfo key={`${templateItem.type}_${text}_${offset}`} value={text} />
      ))}
    </>
  );
};

const TargetLaunchDateReceiptComponent = ({ templateItem }: Props) => {
  const { format } = useFormat();
  const launchDate = useLaunchDateText();
  const timeZone = useTimeZoneText();
  const shortTitleId = TemplateItemUtils.getShortTitle(templateItem.type);
  return (
    <ReceiptRecord
      label={format(shortTitleId)}
      value={launchDate}
      description={timeZone || undefined}
    />
  );
};

const useLaunchDateText = (): string | null => {
  const {
    product: { targetLaunchDate },
  } = useContext(OrderSourceContext);

  return useMemo(() => (targetLaunchDate > 0 ? TimeUtils.yyyymmdd(targetLaunchDate) : null), [
    targetLaunchDate,
  ]);
};

const useTimeZoneText = (): string | null => {
  const {
    product: {
      description: { targetLaunchDate: timeZoneKey },
    },
  } = useContext(OrderSourceContext);
  return useMemo(
    () => getTimeZones().find(timeZone => timeZone.name === timeZoneKey)?.currentTimeFormat || null,
    [timeZoneKey],
  );
};

export default class TargetLaunchDateSupporter extends AbstractTemplateItemSupporter {
  support(templateItemType: TemplateItemType): boolean {
    return templateItemType === TemplateItemType.TARGET_LAUNCH_DATE;
  }
  render(templateItem: TemplateItem): React.ReactNode {
    return <TargetLaunchDate templateItem={templateItem} />;
  }
  renderSummary(templateItem: TemplateItem): React.ReactNode | null {
    return <TargetLaunchDateSummaryComponent templateItem={templateItem} />;
  }
  renderReceipt(templateItem: TemplateItem): React.ReactNode | null {
    return <TargetLaunchDateReceiptComponent templateItem={templateItem} />;
  }
  getTitleId(): string {
    return 'Order.Template.TargetLaunchDate.Title';
  }
  getShortTitleId(): string {
    return 'Order.Template.TargetLaunchDate.ShortTitle';
  }
}
