"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class ObjectUtils {
}
exports.ObjectUtils = ObjectUtils;
ObjectUtils.asPlainObject = (object) => {
    return JSON.parse(JSON.stringify(object));
};
ObjectUtils.removeUndefinedProperty = (object) => {
    return JSON.parse(JSON.stringify(object));
};
