import classNames from 'classnames/bind';
import { observer } from 'mobx-react';
import React, { useCallback, useMemo, useState } from 'react';

import { TemplateItemType } from '@bringk/shared';

import ScrollableContainer from 'src/components/ScrollableContainer/index';
import TitledPane from 'src/components/TitledPane';
import useFormat from 'src/hooks/useFormat';
import useOrderStep from 'src/hooks/useOrderStep';
import useOrderTemplate from 'src/hooks/useOrderTemplate';
import useStore from 'src/hooks/useStore';
import { ReactComponent as BackDoubleSvg } from 'src/images/svg/icon_navigation_back_double.svg';
import { ReactComponent as NoteSvg } from 'src/images/svg/icon_note.svg';
import { ReactComponent as XSvg } from 'src/images/svg/icon_x.svg';
import CategoryInfo from 'src/pages/order/summary/CategoryInfo';
import { CategoryUtils } from 'src/utils/CategoryUtils';
import TemplateItemUtils from 'src/utils/TemplateItemUtils';

import ContainerInfo from './ContainerInfo';
import styles from './index.module.scss';
import TopInfo from './TopInfo';

const cx = classNames.bind(styles);

interface Props {
  stepId: number;
  subStepId: number;
}

const OrderSummary = (props: Props) => {
  const [isOpen, setToggle] = useState<boolean>(true);
  const { format } = useFormat();
  const { setStep } = useOrderStep();
  const { orderStore } = useStore();
  const { template, getStepByType, getTemplateItemByType } = useOrderTemplate(
    orderStore.product.category,
  );
  const category = orderStore.product.category || null;
  const categories = useMemo(() => CategoryUtils.parseAll(category), [category]);

  const onToggleClicked = useCallback(() => {
    setToggle(!isOpen);
  }, [isOpen]);

  const onEditClicked = useCallback(
    (templateItemType?: TemplateItemType) => {
      if (!templateItemType) {
        setStep(1, 0);
        return;
      }
      if (template) {
        const targetTemplateItem = template.findTemplateItem(templateItemType);
        const step = targetTemplateItem && getStepByType(targetTemplateItem.type);
        if (step) {
          setStep(step.stepId, step.subStepId);
        }
      }
    },
    [template, setStep, getStepByType],
  );

  const templateTypes = useMemo(
    () => TemplateItemUtils.sort(template?.items ?? []).map(item => item.type),
    [template],
  );

  return (
    <div className={cx('container', { open: isOpen })}>
      <div className={cx('header', { open: isOpen })}>
        <div className={cx('title')}>{format('Order.SummaryTitle')}</div>
        <div className={cx('icon')}>
          {isOpen ? (
            <>
              <BackDoubleSvg className={cx('close', { open: isOpen })} onClick={onToggleClicked} />
              <XSvg className={cx('close-media', { open: isOpen })} onClick={onToggleClicked} />
            </>
          ) : (
            <NoteSvg className={cx('open', { open: isOpen })} onClick={onToggleClicked} />
          )}
        </div>
      </div>
      <ScrollableContainer gradientColor={'#fefefe'}>
        <div className={cx('information', { open: isOpen })}>
          <ContainerInfo {...{ onEditClicked }} />
          <TopInfo {...{ onEditClicked }} />
          <TitledPane
            title={format('Keywords.Category')}
            visible={categories.length > 0}
            handleEdit={() => onEditClicked()}
          >
            <CategoryInfo categories={categories} />
          </TitledPane>
          {templateTypes.map(templateItemType => {
            const component = TemplateItemUtils.renderSummary(
              getTemplateItemByType(templateItemType),
            );
            return (
              <TitledPane
                title={format(TemplateItemUtils.getShortTitle(templateItemType))}
                key={templateItemType}
                visible={component !== null}
                handleEdit={() => onEditClicked(templateItemType)}
              >
                {component}
              </TitledPane>
            );
          })}
        </div>
      </ScrollableContainer>
    </div>
  );
};

export default observer(OrderSummary);
