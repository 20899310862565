import classNames from 'classnames/bind';
import { observer } from 'mobx-react';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';

import { TemplateItemWorkers, ValidationError } from '@bringk/shared';

import Receipt from 'src/components/Receipt';
import useFormat from 'src/hooks/useFormat';
import useOrderTemplate from 'src/hooks/useOrderTemplate';
import useOrderTemporarySave from 'src/hooks/useOrderTemporarySave';
import useStore from 'src/hooks/useStore';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface Props {}

const OrderStep04 = (props: Props) => {
  const history = useHistory();
  const { format, formatPrefix } = useFormat();
  const { orderStore } = useStore();
  const { template, requiredTemplateItems, unrequiredTemplateItems } = useOrderTemplate(
    orderStore.product.category,
  );
  const { clearData } = useOrderTemporarySave();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>();

  const onOrderClick = useCallback(async () => {
    if (!template) {
      setErrorMessage(formatPrefix('Errors.', 'CategoryInvalid'));
      return;
    }
    setIsLoading(true);
    await TemplateItemWorkers.validateAsync(template, orderStore.producer, orderStore.product)
      .then(() => orderStore.post())
      .then(() => {
        const { id } = orderStore;
        orderStore.clear();
        clearData();
        history.push(`/history/orders/${id}`);
      })
      .catch(error => {
        if (error instanceof ValidationError)
          setErrorMessage(formatPrefix('Errors.', error.message, error.payload));
        else setErrorMessage(formatPrefix('Errors.', error.message));
      })
      .finally(() => setIsLoading(false));
  }, [template, orderStore, formatPrefix, clearData, history, setIsLoading]);

  return (
    <div className={cx('container')}>
      <Receipt
        product={orderStore.product}
        producer={orderStore.producer}
        requiredTemplateItemTypes={requiredTemplateItems.map(templateItem => templateItem.type)}
        unrequiredTemplateItemTypes={unrequiredTemplateItems.map(templateItem => templateItem.type)}
      />
      {Boolean(errorMessage) && <div className={cx('order-error')}>{errorMessage}</div>}
      <div className={cx('order')}>
        <div className={cx('order-button')} onClick={onOrderClick}>
          {format('Keywords.Order')}
        </div>
      </div>
      <Dimmer active={isLoading}>
        <Loader size={'huge'} />
      </Dimmer>
    </div>
  );
};

export default observer(OrderStep04);
