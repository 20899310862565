import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames/bind';
import React, { useState, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';

import SettingDropdown from 'src/components/SettingDropdown';
import logo from 'src/images/logo.png';

import styles from './index.module.scss';
const cx = classNames.bind(styles);

interface Props {}

const NavigationHeader = (props: Props) => {
  const [isNavListActive, setIsNavListActive] = useState<boolean>(false);
  const headerRef = useRef<HTMLHeadElement>(null);

  const handleNavListInactive = useCallback(() => {
    setIsNavListActive(false);
  }, []);

  return (
    <div className={cx('container')}>
      <header className={cx('header')} ref={headerRef}>
        <div className={cx('row')}>
          <div className={cx('icon-container')}>
            <a className={cx('logo')} href="/">
              <img className={cx('image')} alt="BRING-K" src={logo} />
            </a>
            <div className={cx('menu-bar')} onClick={() => setIsNavListActive(!isNavListActive)}>
              <FontAwesomeIcon icon={faBars} size="lg" />
            </div>
          </div>
          <div className={cx('menu', { navListActive: isNavListActive })}>
            <nav>
              <ul className={cx('list')}>
                <li className={cx('item')}>
                  <Link className="link" to="/" onClick={handleNavListInactive}>
                    Home
                  </Link>
                </li>
                <li className={cx('item')}>
                  <Link className="link" to="/about" onClick={handleNavListInactive}>
                    About
                  </Link>
                </li>
                <li className={cx('item')}>
                  <Link className="link" to="/order" onClick={handleNavListInactive}>
                    Order
                  </Link>
                </li>
                <div className={cx('setting-icon')}>
                  <SettingDropdown pointing="top right" />
                </div>
                <div className={cx('setting-text')}>
                  <SettingDropdown pointing="top left" text="Setting" />
                </div>
              </ul>
            </nav>
          </div>
        </div>
      </header>
    </div>
  );
};

export default NavigationHeader;
