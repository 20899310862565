import { observer } from 'mobx-react';
import React, { useCallback, useMemo } from 'react';

import { TemplateItem } from '@bringk/shared';

import ItemPreviewImage from 'src/components/ItemPreviewImage';
import RadioButtons from 'src/components/RadioButtons';
import useStore from 'src/hooks/useStore';

interface Props {
  templateItem: TemplateItem;
  selectableItems: any[];
}

const RadioButtonsWrapper = ({ templateItem, selectableItems }: Props) => {
  const { orderStore } = useStore();
  const targetProperty = useMemo(() => templateItem.property, [templateItem]);

  const selectedItemKey: any = useMemo(() => {
    return orderStore.product[targetProperty];
  }, [orderStore.product, targetProperty]);

  const selectedImage = useMemo(() => {
    const image = selectableItems.find(item => item.key === selectedItemKey)?.image;
    return image && `/static/gif/${image}`;
  }, [selectableItems, selectedItemKey]);

  const handleItemSelected = useCallback(
    (itemKey?: string) => {
      orderStore.setProductProperty(targetProperty, itemKey);
    },
    [targetProperty, orderStore],
  );

  return (
    <>
      {selectedImage && <ItemPreviewImage src={selectedImage} />}
      <RadioButtons
        {...{
          selectedItemKey,
          handleItemSelected,
          selectableItems,
        }}
      />
    </>
  );
};

export default observer(RadioButtonsWrapper);
