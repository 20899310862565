/* eslint-disable import/order */
import React from 'react';

import styles from './index.module.scss';

import classNames from 'classnames/bind';
import OrderSearch from './orderSearch';

const cx = classNames.bind(styles);

interface Props {}

const HistoryPage = (props: Props) => {
  return (
    <div className={cx('container')}>
      <OrderSearch />
    </div>
  );
};

export default HistoryPage;
