import React, { useContext } from 'react';

import { TemplateItem, TemplateItemType } from '@bringk/shared';

import ReceiptRecord from 'src/components/ReceiptRecord/index';
import useFormat from 'src/hooks/useFormat';
import TextInfo from 'src/pages/order/summary/TextInfo';
import OtherRequest from 'src/pages/order/templates/OtherRequest';
import AbstractTemplateItemSupporter from 'src/utils/template/AbstractTemplateItemSupporter';
import TemplateItemUtils from 'src/utils/TemplateItemUtils';

import { OrderSourceContext } from './OrderSource';

interface Props {
  templateItem: TemplateItem;
}

const OtherRequestSummaryComponent = () => {
  const {
    product: { otherRequests: value },
  } = useContext(OrderSourceContext);
  if (Boolean(value)) {
    return <TextInfo value={value} />;
  }
  return null;
};

const OtherRequestReceiptComponent = ({ templateItem }: Props) => {
  const { format } = useFormat();
  const {
    product: {
      otherRequests: value,
      description: { otherRequests: description },
    },
  } = useContext(OrderSourceContext);
  const shortTitleId = TemplateItemUtils.getShortTitle(templateItem.type);
  return <ReceiptRecord label={format(shortTitleId)} value={value} description={description} />;
};

export default class OtherRequestSupporter extends AbstractTemplateItemSupporter {
  support(templateItemType: TemplateItemType): boolean {
    return templateItemType === TemplateItemType.OTHER_REQUESTS;
  }
  render(templateItem: TemplateItem): React.ReactNode {
    return <OtherRequest templateItem={templateItem} />;
  }
  renderSummary(): React.ReactNode | null {
    return <OtherRequestSummaryComponent />;
  }
  renderReceipt(templateItem: TemplateItem): React.ReactNode | null {
    return <OtherRequestReceiptComponent templateItem={templateItem} />;
  }
}
