import classNames from 'classnames/bind';
import { observer } from 'mobx-react';
import React, { useCallback, useMemo, useState } from 'react';
import { ColorResult } from 'react-color';
import { Loader } from 'semantic-ui-react';
import { v4 as uuid } from 'uuid';

import { Tooltip, TemplateItem, Category } from '@bringk/shared';

import ColorPallet from 'src/components/ColorPallet/index';
import ColorPickerComponent from 'src/components/ColorPicker/index';
import InputText from 'src/components/InputText/index';
import useFormat from 'src/hooks/useFormat';
import useImageUploader from 'src/hooks/useImageUploader';
import useStore from 'src/hooks/useStore';
import { ReactComponent as IconUpload } from 'src/images/svg/icon_upload.svg';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface Props {
  templateItem: TemplateItem;
  imageProperty: string;
  colorProperty: string;
  refBrandProperty: string;
  refProductProperty: string;
  tooltips?: Tooltip[];
  getSuggestionImagePath?: (category: Category) => { imagePath: string } | null;
}

const TargetImage = (props: Props) => {
  const { formatPrefix, formatKeyword } = useFormat();
  const { orderStore } = useStore();
  const inputUid = useMemo(() => uuid(), []);
  const [customImagePath, setCustomImagePath] = useState<string>();
  const color =
    orderStore.product[props.colorProperty] !== null && orderStore.product[props.colorProperty];

  const suggestImage = useMemo(() => {
    if (orderStore.product.category)
      return props.getSuggestionImagePath?.(orderStore.product.category)?.imagePath;
  }, [props.getSuggestionImagePath, orderStore.product.category]);

  const {
    isLoading: isLoadingImage,
    imageSrc: previewImageSrc,
    handleInputChanged,
  } = useImageUploader({
    defaultStoragePath: orderStore.product[props.imageProperty],
    storagePathSuffix: '/customers/',
    onUploadSuccess: useCallback(
      imagePath => {
        setCustomImagePath(imagePath);
        orderStore.setProductProperty(props.imageProperty, imagePath);
      },
      [orderStore, props.imageProperty],
    ),
    onUploadFailure: useCallback(error => alert(error.toString()), []),
  });

  const handleCustomImageRedo = useCallback(() => {
    orderStore.setProductProperty(props.imageProperty, customImagePath);
  }, [customImagePath, orderStore, props.imageProperty]);

  const handleApplySuggestionImage = useCallback(() => {
    orderStore.setProductProperty(props.imageProperty, undefined);
  }, [orderStore, props.imageProperty]);

  const handleColorChanged = useCallback(
    (value: string) => {
      orderStore.setProductProperty(props.colorProperty, value);
    },
    [orderStore, props.colorProperty],
  );

  const handleColorPickerChanged = useCallback(
    (value: ColorResult) => {
      orderStore.setProductProperty(props.colorProperty, value.hex);
    },
    [orderStore, props.colorProperty],
  );

  const handleBrandNameChanged = useCallback(
    event => {
      orderStore.setProductProperty(props.refBrandProperty, event.currentTarget.value);
    },
    [orderStore, props.refBrandProperty],
  );

  const handleProductNameChanged = useCallback(
    event => {
      orderStore.setProductProperty(props.refProductProperty, event.currentTarget.value);
    },
    [orderStore, props.refProductProperty],
  );

  return (
    <>
      <div className={cx('container')} key={props.templateItem.type}>
        <div className={cx('col', 'col-1')}>
          <div className={cx('row', 'row-320')}>
            <div className={cx('col')}>
              <div className={cx('header')}>{formatKeyword('Image')}</div>
              <div className={cx('card-container')}>
                <label htmlFor={inputUid} className={cx('label')}>
                  <div
                    className={cx('card', { selected: orderStore.product[props.imageProperty] })}
                  >
                    {isLoadingImage ? (
                      <div className={cx('image')}>
                        <Loader size="large" active inline="centered" />
                      </div>
                    ) : previewImageSrc ? (
                      <img className={cx('image')} src={previewImageSrc} />
                    ) : (
                      <div className={cx('image')}>
                        <IconUpload className={cx('upload-icon')} />
                      </div>
                    )}
                    <div className={cx('title')}>{formatKeyword('Custom')}</div>
                  </div>
                </label>
                <input
                  id={inputUid}
                  type="file"
                  onClick={handleCustomImageRedo}
                  onChange={handleInputChanged}
                  accept={'.png, .jpg, .jpeg, .gif, .bmp'}
                  className={cx('image-input')}
                />
                {suggestImage && (
                  <div
                    className={cx('card', { selected: !orderStore.product[props.imageProperty] })}
                    onClick={handleApplySuggestionImage}
                  >
                    <img className={cx('image')} src={suggestImage} />
                    <div className={cx('title')}>{formatKeyword('Suggestion')}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={cx('row')}>
            <div className={cx('col')}>
              <div className={cx('header')}>{formatKeyword('ReferenceBrandName')}</div>
              <InputText
                placeholder={formatPrefix('Texts.', 'EnterBrandName')}
                value={orderStore.product[props.refBrandProperty] ?? ''}
                onChange={handleBrandNameChanged}
              />
            </div>
          </div>
        </div>
        <div className={cx('divider')} />
        <div className={cx('col', 'col-2')}>
          <div className={cx('row', 'row-320')}>
            <div className={cx('col')}>
              <div className={cx('header')}>{formatKeyword('Color')}</div>
              <div className={cx('color-container')}>
                <ColorPickerComponent color={color} onChange={handleColorPickerChanged} />
                <ColorPallet onChange={handleColorChanged} />
              </div>
            </div>
          </div>
          <div className={cx('row')}>
            <div className={cx('col')}>
              <div className={cx('header')}>{formatKeyword('ReferenceProductName')}</div>
              <InputText
                placeholder={formatPrefix('Texts.', 'EnterProductName')}
                value={orderStore.product[props.refProductProperty] ?? ''}
                onChange={handleProductNameChanged}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

TargetImage.defaultProps = {
  imageProperty: 'boxImage',
  colorProperty: 'boxColor',
  refBrandProperty: 'boxRefBrand',
  refProductProperty: 'boxRefProduct',
  getSuggestionImagePath: (...param: any) => null,
};

export default observer(TargetImage);
