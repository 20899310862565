import React from 'react';

import { Product, Producer } from '@bringk/shared';

export type OrderSource = {
  product: Product;
  producer: Producer;
};

export const OrderSourceContext = React.createContext<OrderSource>({
  product: new Product(),
  producer: new Producer(),
});
