import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames/bind';
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Loader } from 'semantic-ui-react';
import { v4 as uuid } from 'uuid';

import useFirebaseStorage from 'src/hooks/useFirebaseStorage';
import useImageUploader, { OnUploadFailure, OnUploadSuccess } from 'src/hooks/useImageUploader';
import FileUtils from 'src/utils/FileUtils';
import FirebaseApplication from 'src/utils/FirebaseApplication';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface Props {
  className?: string;
  alt?: string;
  accept?: string;
  storagePath?: string | null;
  storagePathSuffix: string;
  placeholderImageSrc?: string;
  onUploadSuccess?: OnUploadSuccess;
  onUploadFailure?: OnUploadFailure;
}

const ImageUploader = ({
  className,
  accept = '.png, .jpg, .jpeg, .gif, .bmp',
  alt = 'image uploader',
  storagePath,
  storagePathSuffix,
  placeholderImageSrc = '/assets/not-found.png',
  onUploadSuccess,
  onUploadFailure,
}: Props) => {
  const inputUid = useMemo(() => uuid(), []);

  const { isLoading, imageSrc, handleInputChanged } = useImageUploader({
    defaultStoragePath: storagePath,
    storagePathSuffix,
    placeholderImageSrc,
    onUploadSuccess,
    onUploadFailure,
  });

  return (
    <div className={cx('container', className)}>
      <div className={cx('image_filter')}>
        <img className={cx('image')} src={imageSrc} alt={alt} />
      </div>
      <label htmlFor={inputUid} className={cx('label')}>
        <div className={cx('icon_container')}>
          {isLoading ? (
            <Loader size="large" active inline="centered" />
          ) : (
            <FontAwesomeIcon className={cx('edit')} icon={faEdit} size="2x" />
          )}
        </div>
      </label>
      <input
        id={inputUid}
        type="file"
        onChange={handleInputChanged}
        accept={accept}
        className={cx('input')}
      />
    </div>
  );
};

export default ImageUploader;
