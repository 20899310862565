"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var TemplateItemType;
(function (TemplateItemType) {
    TemplateItemType["PRODUCER_INFORMATION"] = "PRODUCER_INFORMATION";
    TemplateItemType["TARGET_COUNTRIES_TO_SELL"] = "TARGET_COUNTRIES_TO_SELL";
    TemplateItemType["TARGET_DISTRIBUTION_CHANNEL"] = "TARGET_DISTRIBUTION_CHANNEL";
    TemplateItemType["TARGET_CUSTOMER_GENDER"] = "TARGET_CUSTOMER_GENDER";
    TemplateItemType["TARGET_CUSTOMER_AGE"] = "TARGET_CUSTOMER_AGE";
    TemplateItemType["TARGET_LAUNCH_DATE"] = "TARGET_LAUNCH_DATE";
    TemplateItemType["ORDER_QUANTITY"] = "ORDER_QUANTITY";
    TemplateItemType["TARGET_CONTAINER"] = "TARGET_CONTAINER";
    TemplateItemType["CONTAINER_PUMPING"] = "CONTAINER_PUMPING";
    TemplateItemType["SHEET_SHAPE"] = "SHEET_SHAPE";
    TemplateItemType["SHEET_FABRIC"] = "SHEET_FABRIC";
    TemplateItemType["FABRIC_COLOR"] = "FABRIC_COLOR";
    TemplateItemType["TARGET_BOX_SET"] = "TARGET_BOX_SET";
    TemplateItemType["TARGET_BOX"] = "TARGET_BOX";
    TemplateItemType["CAPACITY"] = "CAPACITY";
    TemplateItemType["CAPACITY_PER_SHEET"] = "CAPACITY_PER_SHEET";
    TemplateItemType["COMPANY_NAME"] = "COMPANY_NAME";
    TemplateItemType["NAME"] = "NAME";
    TemplateItemType["FEATURES"] = "FEATURES";
    TemplateItemType["INGREDIENTS"] = "INGREDIENTS";
    TemplateItemType["FUNCTIONALITY"] = "FUNCTIONALITY";
    TemplateItemType["EWG"] = "EWG";
    TemplateItemType["NON_ADDITIVES"] = "NON_ADDITIVES";
    TemplateItemType["FORMULATION"] = "FORMULATION";
    TemplateItemType["MOISTURE_LEVEL"] = "MOISTURE_LEVEL";
    TemplateItemType["FINISH_LEVEL"] = "FINISH_LEVEL";
    TemplateItemType["NOSE_STRIP"] = "NOSE_STRIP";
    TemplateItemType["CLEANSING_POWER"] = "CLEANSING_POWER";
    TemplateItemType["BUBBLE"] = "BUBBLE";
    TemplateItemType["PH_BALANCED"] = "PH_BALANCED";
    TemplateItemType["SCENT"] = "SCENT";
    TemplateItemType["COLOR"] = "COLOR";
    TemplateItemType["SUNSCREEN_TYPE"] = "SUNSCREEN_TYPE";
    TemplateItemType["OTHER_REQUESTS"] = "OTHER_REQUESTS";
})(TemplateItemType = exports.TemplateItemType || (exports.TemplateItemType = {}));
