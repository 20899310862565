import classNames from 'classnames/bind';
import React from 'react';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface Props {
  bold?: boolean;
  sequence?: boolean;
  contents: {
    key: string;
    value?: string | number | any[] | null;
  }[];
}

const KeyValueInfo = (props: Props) => {
  return (
    <>
      {props.contents.map(content => {
        return (
          <div
            key={content.key + content.value}
            className={cx('container', { multiple: Array.isArray(content.value) })}
          >
            <div className={cx('key', { bold: props.bold })}>{content.key}</div>
            {content.value &&
              (!Array.isArray(content.value) ? (
                <div className={cx('value', { bold: props.bold })}>{content.value}</div>
              ) : (
                content.value.map((value, index) => (
                  <div key={cx('value') + value} className={cx('value', { bold: props.bold })}>
                    {props.sequence && `${index + 1}. `}
                    {value}
                  </div>
                ))
              ))}
          </div>
        );
      })}
    </>
  );
};

export default KeyValueInfo;
