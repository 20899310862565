import { createContext } from 'react';

import OrderHistoryStore from './OrderHistoryStore';
import OrderStore from './OrderStore';

class RootStore {
  orderStore: OrderStore;
  orderHistoryStore: OrderHistoryStore;

  constructor() {
    this.orderStore = new OrderStore();
    this.orderHistoryStore = new OrderHistoryStore();
  }
}

const rootStoreContext = createContext(new RootStore());

export default rootStoreContext;
