import React from 'react';

interface Props {}

const NotFoundPage = (props: Props) => {
  return (
    <div>
      <h3>404 Not found</h3>
    </div>
  );
};

export default NotFoundPage;
