import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames/bind';
import React, { useCallback, useState } from 'react';

import useFormat from 'src/hooks/useFormat';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface Props {
  open: boolean;
  handleAccept: () => void;
  handleToggle: () => void;
}

const PolicyModal = (props: Props) => {
  const { formatPrefix } = useFormat();
  const handleAccept = useCallback(() => {
    props.handleToggle();
    props.handleAccept();
  }, [props.handleAccept, props.handleToggle]);

  return (
    <>
      {props.open && (
        <div className={cx('container')}>
          <div className={cx('box')}>
            <div className={cx('header')}>
              <div className={cx('title')}>{formatPrefix('Acceptance.', 'Title')}</div>
              <div className={cx('close')}>
                <FontAwesomeIcon
                  className={cx('icon')}
                  icon={faTimes}
                  size="2x"
                  color="black"
                  onClick={props.handleToggle}
                />
              </div>
            </div>
            <div className={cx('body')}>
              <div className={cx('acceptance-scroll')}>
                {formatPrefix('Acceptance.', 'Policy')
                  .split('\n')
                  .map((line: string, offset: number) => {
                    return (
                      <span key={offset}>
                        {line}
                        <br />
                      </span>
                    );
                  })}
              </div>
            </div>
            <div className={cx('footer')}>
              <button className={cx('accept')} onClick={handleAccept}>
                {formatPrefix('Acceptance.', 'Accept')}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

PolicyModal.defaultProps = {};

export default PolicyModal;
