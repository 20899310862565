"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const TemplateItemType_1 = require("../TemplateItemType");
const AbstractTemplateItemWorker_1 = require("./AbstractTemplateItemWorker");
const ValidationChainer_1 = require("./ValidationChainer");
class ContainerPumpingWorker extends AbstractTemplateItemWorker_1.AbstractTemplateItemWorker {
    support(templateItem) {
        return templateItem.type === TemplateItemType_1.TemplateItemType.CONTAINER_PUMPING;
    }
    initialize(templateItem, producer, product) {
        product.containerPumping = null;
    }
    validate(templateItem, producer, product) {
        ValidationChainer_1.ValidationChainer.property(product.containerPumping)
            .propertyName('container pumping')
            .stringOptional()
            .stringLimit();
    }
}
exports.ContainerPumpingWorker = ContainerPumpingWorker;
