import classNames from 'classnames/bind';
import { observer } from 'mobx-react';
import React, { useCallback, useMemo, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';

import { SelectableItem, TemplateItem, ArrayUtils } from '@bringk/shared';

import CheckBoxes from 'src/components/CheckBoxes/index';
import SearchBox from 'src/components/SearchBox/index';
import useStore from 'src/hooks/useStore';
import CountryUtils from 'src/utils/CountryUtils';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface Props {
  templateItem: TemplateItem;
}

const TargetCountriesToSell = ({ templateItem }: Props) => {
  const { orderStore } = useStore();
  const targetProperty = templateItem.property;
  const keys: string[] = orderStore.product[targetProperty];
  const [searchInput, setSearchInput] = useState<string>('');
  const selectableItems: SelectableItem[] = [
    {
      key: 'KR',
      label: 'South Korea',
    },
    ...ArrayUtils.filterNotNull(
      keys
        .filter(key => key !== 'KR')
        .map(key => {
          const label = CountryUtils.findByISOCode2(key)?.countryName;
          return label ? { key, label } : null;
        }),
    ),
  ];

  const handleItemChanged = useCallback(
    (itemKey: string[]) => {
      orderStore.setProductProperty(targetProperty, itemKey);
    },
    [targetProperty, orderStore],
  );

  const handleItemAdded = useCallback(
    (addedKey: string) => {
      if (!keys.includes(addedKey)) {
        orderStore.setProductProperty(targetProperty, [...keys, addedKey]);
      }
      setSearchInput('');
    },
    [keys, orderStore, targetProperty],
  );

  const countryISOCode2s = useMemo(
    () =>
      Boolean(searchInput)
        ? CountryUtils.findAll()
            .filter(country =>
              country.countryName.toLowerCase().includes(searchInput.toLowerCase()),
            )
            .map(country => country.isoCode2)
        : CountryUtils.findOverseasToSell(),
    [searchInput],
  );

  const items = ArrayUtils.filterNotNull(
    countryISOCode2s.map(key => ({
      key,
      label: CountryUtils.findByISOCode2(key)?.countryName || '',
    })),
  );

  return (
    <div className={cx('container')}>
      <div className={cx('radio')}>
        <CheckBoxes
          selectedItemKeys={keys}
          selectableItems={selectableItems}
          handleItemAdded={key => handleItemChanged([...keys, key])}
          handleItemRemoved={key => handleItemChanged(keys.filter(oldKey => oldKey !== key))}
          customItemCreator={(key, label) => (
            <>
              {label}{' '}
              <ReactCountryFlag
                countryCode={key}
                style={{
                  fontSize: '1.5em',
                  lineHeight: '1.5em',
                }}
              />
            </>
          )}
        />
        <SearchBox
          items={items}
          useFlag={true}
          placeholder={'Add Overseas'}
          onSearchKeywordChanged={setSearchInput}
          onClick={handleItemAdded}
        />
      </div>
    </div>
  );
};

export default observer(TargetCountriesToSell);
