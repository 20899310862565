import React, { useContext } from 'react';

import { TemplateItem, TemplateItemType } from '@bringk/shared';

import ReceiptRecord from 'src/components/ReceiptRecord/index';
import useFormat from 'src/hooks/useFormat';
import TextInfo from 'src/pages/order/summary/TextInfo';
import Name from 'src/pages/order/templates/Name';
import AbstractTemplateItemSupporter from 'src/utils/template/AbstractTemplateItemSupporter';
import TemplateItemUtils from 'src/utils/TemplateItemUtils';

import { OrderSourceContext } from './OrderSource';

interface Props {
  templateItem: TemplateItem;
}

const CommonSummaryComponent = ({ templateItem }: Props) => {
  const { formatKeyword } = useFormat();
  const { product } = useContext(OrderSourceContext);
  const value = product[templateItem.property];

  if (Boolean(value)) {
    return <TextInfo value={formatKeyword(value)} />;
  }
  return null;
};

const CommonNameReceiptComponent = ({ templateItem }: Props) => {
  const { format, formatKeyword } = useFormat();
  const { product } = useContext(OrderSourceContext);
  const value = product[templateItem.property];
  const description = product.description[templateItem.property];
  const shortTitle = TemplateItemUtils.getShortTitle(templateItem.type);

  return (
    <ReceiptRecord
      label={format(shortTitle)}
      value={formatKeyword(value)}
      description={description}
    />
  );
};

export default class CommonNameSupporter extends AbstractTemplateItemSupporter {
  support(templateItemType: TemplateItemType): boolean {
    switch (templateItemType) {
      case TemplateItemType.NAME:
      case TemplateItemType.COMPANY_NAME:
        return true;
      default:
        return false;
    }
  }

  render(templateItem: TemplateItem): React.ReactNode {
    return <Name templateItem={templateItem} />;
  }

  renderSummary(templateItem: TemplateItem): React.ReactNode | null {
    return <CommonSummaryComponent templateItem={templateItem} />;
  }

  renderReceipt(templateItem: TemplateItem): React.ReactNode | null {
    return <CommonNameReceiptComponent templateItem={templateItem} />;
  }
}
