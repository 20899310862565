import classNames from 'classnames/bind';
import React, { useMemo } from 'react';

import { TemplateItem } from '@bringk/shared';

import TooltipButton from 'src/components/TooltipButton/index';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface Props {
  title: string;
  templateItem?: TemplateItem;
}

const boldTextParser = (text: string) => {
  const result: string[] = [];
  let start = text.indexOf('**');
  while (start !== -1) {
    const subText = text.substring(start + 2);
    const end = subText.indexOf('**');
    if (end !== -1) {
      result.push(subText.substring(0, end));
    }
    start = subText.indexOf('**');
    text = subText;
  }
  return result;
};

const OrderStepTitle = (props: Props) => {
  const texts = useMemo(() => {
    return props.title.split(/\n/);
  }, [props.title]);

  return (
    <div className={cx('container')}>
      {texts.map((text, index) => {
        const boldText = boldTextParser(text);
        return (
          <div key={text} className={cx('line')}>
            {text.split(/\*\*(.*)\*\*/).map(token => {
              const isBold = boldText && token === boldText[0];
              return (
                <div className={cx('token', { bold: isBold })} key={`${text}-${token}`}>
                  {isBold ? boldText?.pop() : token}
                </div>
              );
            })}
            {texts.length === index + 1 && props.templateItem && (
              <div className={cx('tooltips')}>
                <TooltipButton tooltips={props.templateItem.getTooltips()} />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default OrderStepTitle;
