import React, { useCallback, useMemo } from 'react';

import { TemplateItem, Database } from '@bringk/shared';

import CheckBoxes from 'src/components/CheckBoxes';
import useFormat from 'src/hooks/useFormat';
import useStore from 'src/hooks/useStore';
import { CategoryUtils } from 'src/utils/CategoryUtils';
// import styles from './index.module.scss';

// const cx = classNames.bind(styles);

interface Props {
  templateItem: TemplateItem;
}

const Features = ({ templateItem }: Props) => {
  const targetProperty = useMemo(() => templateItem.property, [templateItem]);
  const { orderStore } = useStore();
  const { formatPrefix, formatKeyword } = useFormat();

  const category = orderStore.product.category;
  const selectedItemKeys = orderStore.product.features ?? [];
  const topCategory = category && CategoryUtils.getTopParent(category);
  const groupFilters = topCategory ? Database.ingredients.getGroups(topCategory) : [];
  const selectableItems = useMemo(
    () =>
      groupFilters.map(filter => ({
        key: filter.id,
        label: formatPrefix('IngredientGroupFilters.', filter.id),
      })),
    [groupFilters, formatPrefix],
  );

  const handleItemAdded = useCallback(
    (itemKey?: string) =>
      itemKey && orderStore.setProductProperty(targetProperty, selectedItemKeys.concat(itemKey)),
    [selectedItemKeys, orderStore, targetProperty],
  );

  const handleItemRemoved = useCallback(
    (itemKey?: string) =>
      itemKey &&
      orderStore.setProductProperty(
        targetProperty,
        selectedItemKeys.filter(prevValue => prevValue !== itemKey),
      ),
    [selectedItemKeys, orderStore, targetProperty],
  );

  return (
    <>
      <CheckBoxes
        selectedItemKeys={selectedItemKeys}
        handleItemAdded={handleItemAdded}
        handleItemRemoved={handleItemRemoved}
        selectableItems={selectableItems}
      />
    </>
  );
};

export default Features;
