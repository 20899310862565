import classNames from 'classnames/bind';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import styles from './index.module.scss';
const cx = classNames.bind(styles);

interface Props {
  pageKey?: string;
  containerClassName?: string;
  className?: string;
  threshold?: number;
  children?: React.ReactNode;
  gradientColor?: string;
}

const ScrollableContainer = ({
  pageKey,
  containerClassName,
  className,
  threshold = 1.0,
  children,
  gradientColor = '#ffffff',
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [canScrollUp, setScrollUp] = useState(false);
  const [canScrollDown, setScrollDown] = useState(false);

  const lastScrollYRef = useRef<number>(0);
  const scrollYMapRef = useRef<{ [key: string]: number }>({});

  useLayoutEffect(() => {
    const container = containerRef.current;
    if (container && pageKey) {
      const scrollY = scrollYMapRef.current?.[pageKey] || 0;
      container.scrollTop = scrollY;
      return () => {
        const scrollY = lastScrollYRef.current || 0;
        scrollYMapRef.current = { ...scrollYMapRef.current, [pageKey]: scrollY };
      };
    }
  }, [pageKey, containerRef, lastScrollYRef, scrollYMapRef]);

  const handleLayout = useCallback(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }

    const scrollY = container.scrollTop;
    const canScroll = container.scrollHeight > container.clientHeight;
    const isTop = scrollY < threshold;
    const isBottom = scrollY + container.clientHeight > container.scrollHeight - threshold;

    const nextCanScrollUp = canScroll && !isTop;
    const nextCanScrollDown = canScroll && !isBottom;

    nextCanScrollUp !== canScrollUp && setScrollUp(nextCanScrollUp);
    nextCanScrollDown !== canScrollDown && setScrollDown(nextCanScrollDown);

    lastScrollYRef.current = scrollY;
  }, [
    pageKey,
    containerRef,
    lastScrollYRef,
    canScrollUp,
    canScrollDown,
    setScrollUp,
    setScrollDown,
    threshold,
  ]);

  useEffect(() => {
    const intervalId = setInterval(handleLayout, 1000);
    return () => clearInterval(intervalId);
  }, [handleLayout]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }
    container.addEventListener('scroll', handleLayout);
    return () => container.removeEventListener('scroll', handleLayout);
  }, [handleLayout, containerRef]);

  const contentClassName = cx('content', className);

  const indicators = useMemo(
    () => (
      <>
        <div
          className={cx('indicator', 'up', { 'has-more': canScrollUp })}
          style={{ background: `linear-gradient(180deg, ${gradientColor} 0%, #ffffff00 100%)` }}
        />
        <div
          className={cx('indicator', 'down', { 'has-more': canScrollDown })}
          style={{
            background: `linear-gradient(0, ${gradientColor} 0%, #ffffff00 100%)`,
          }}
        />
      </>
    ),
    [gradientColor, canScrollUp, canScrollDown],
  );

  return (
    <div className={cx('container', containerClassName)}>
      {indicators}
      <div className={contentClassName} ref={containerRef}>
        {children}
      </div>
    </div>
  );
};

export default ScrollableContainer;
