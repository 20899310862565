import Konva from 'konva';
import React, { useEffect, useRef } from 'react';
import { Image, Rect } from 'react-konva';
import useImage from 'use-image';

interface Props {
  imagePath?: string;
  imageElement?: HTMLImageElement;
  x?: number;
  y?: number;
  scale?: number;
  tintColor?: string | null;
  isDebug?: boolean;
  onClick?: () => void;
}

// copied from : https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
const getRGBFromHexColor = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        red: parseInt(result[1], 16),
        green: parseInt(result[2], 16),
        blue: parseInt(result[3], 16),
      }
    : null;
};

const CommonKonvaImage = ({
  imagePath,
  imageElement: outterImageElement,
  x,
  y,
  scale = 0,
  tintColor,
  isDebug,
  onClick,
}: Props) => {
  const [innerImageElement] = useImage(imagePath || '');

  const imageElement = innerImageElement || outterImageElement;

  const imageRef = useRef<any>();

  useEffect(() => {
    if (imageElement) {
      imageRef.current?.cache();
      imageRef.current?.getLayer().batchDraw();
    }
  }, [imageElement, imageRef]);

  const rgb = (tintColor && getRGBFromHexColor(tintColor)) || {};

  const width = (imageElement?.width ?? 0) * scale;
  const height = (imageElement?.height ?? 0) * scale;

  return (
    <>
      {isDebug && (
        <Rect {...{ x, y, width, height, strokeWidth: 1, stroke: 'black', fill: 'red' }} />
      )}
      <Image
        ref={imageRef}
        filters={tintColor ? [Konva.Filters.RGB] : []}
        image={imageElement}
        onMouseEnter={e => {
          const container = e.target.getStage()?.container();
          if (!!onClick && container) {
            container.style.cursor = 'pointer';
          }
        }}
        onMouseLeave={e => {
          const container = e.target.getStage()?.container();
          if (container) {
            container.style.cursor = 'default';
          }
        }}
        onClick={() => onClick?.()}
        {...{ x, y, scaleX: scale, scaleY: scale }}
        {...rgb}
      />
    </>
  );
};

export default CommonKonvaImage;
