import React, { useContext } from 'react';

import { TemplateItem, ArrayUtils, TemplateItemType } from '@bringk/shared';

import ReceiptRecord from 'src/components/ReceiptRecord/index';
import useFormat from 'src/hooks/useFormat';
import TextInfo from 'src/pages/order/summary/TextInfo';
import TargetCustomerAge from 'src/pages/order/templates/TargetCustomerAge';
import AbstractTemplateItemSupporter from 'src/utils/template/AbstractTemplateItemSupporter';
import TemplateItemUtils from 'src/utils/TemplateItemUtils';

import { OrderSourceContext } from './OrderSource';

interface Props {
  templateItem: TemplateItem;
}

const TargetCustomerAgeSummaryComponent = () => {
  const { format } = useFormat();
  const {
    product: { targetCustomerAge: values },
  } = useContext(OrderSourceContext);
  if (ArrayUtils.isNotEmpty(values)) {
    return <TextInfo value={values.map(value => `${value}${format('Unit.Generation')}`)} />;
  }
  return null;
};

const TargetCustomerAgeReceiptComponent = ({ templateItem }: Props) => {
  const { format } = useFormat();
  const {
    product: {
      targetCustomerAge: values,
      description: { targetCustomerAge: description },
    },
  } = useContext(OrderSourceContext);
  const shortTitleId = TemplateItemUtils.getShortTitle(templateItem.type);
  return (
    <ReceiptRecord
      label={format(shortTitleId)}
      value={values.map(element => `${element}${format('Unit.Generation')}`)}
      description={description}
    />
  );
};

export default class TargetCustomerAgeSupporter extends AbstractTemplateItemSupporter {
  support(templateItemType: TemplateItemType): boolean {
    return templateItemType === TemplateItemType.TARGET_CUSTOMER_AGE;
  }
  render(templateItem: TemplateItem): React.ReactNode {
    return <TargetCustomerAge templateItem={templateItem} />;
  }
  renderSummary(): React.ReactNode | null {
    return <TargetCustomerAgeSummaryComponent />;
  }
  renderReceipt(templateItem: TemplateItem): React.ReactNode | null {
    return <TargetCustomerAgeReceiptComponent templateItem={templateItem} />;
  }
  getTitleId(): string {
    return 'Order.Template.TargetCustomerAge.Title';
  }
  getShortTitleId(): string {
    return 'Order.Template.TargetCustomerAge.ShortTitle';
  }
}
