import classNames from 'classnames/bind';
import React from 'react';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface Props {
  bold?: boolean;
  sequence?: boolean;
  value?: string | number | any[] | JSX.Element | null;
}

const TextInfo = (props: Props) => {
  return (
    <div className={cx('container')}>
      {props.value &&
        (!Array.isArray(props.value) ? (
          <div className={cx('value', { bold: props.bold })}>{props.value}</div>
        ) : (
          props.value.map((value, index) => (
            <div
              key={`${cx('value')}:${value}:${index}`}
              className={cx('value', { bold: props.bold })}
            >
              {props.sequence && `${index + 1}. `}
              {value}
            </div>
          ))
        ))}
    </div>
  );
};

export default TextInfo;
