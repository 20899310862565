import { useEffect, useState } from 'react';

import FirebaseApplication from 'src/utils/FirebaseApplication';

export enum State {
  NONE = 'NONE',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  FAILED = 'FAILED',
}

const useFirebaseStorage = (
  storageRef: string | undefined | null,
): { url: string | null; state: State } => {
  const [url, setUrl] = useState<string | null>(null);
  const [state, setState] = useState<State>(State.NONE);
  useEffect(() => {
    setUrl(null);
    if (storageRef && Boolean(storageRef)) {
      setState(State.LOADING);
      FirebaseApplication.storage()
        .ref(storageRef)
        .getDownloadURL()
        .then(url => {
          setState(State.LOADED);
          setUrl(url);
        })
        .catch(() => {
          setState(State.FAILED);
          setUrl(null);
        });
    } else {
      setState(State.NONE);
    }
  }, [storageRef, setUrl, setState]);
  return { url, state };
};

export default useFirebaseStorage;
