import classNames from 'classnames/bind';
import React, { useCallback, useState, useEffect } from 'react';
import { Container, Draggable, DropResult, smoothDnD } from 'react-smooth-dnd';

import { Ingredient, ArrayUtils } from '@bringk/shared';

import useFormat from 'src/hooks/useFormat';

import Item from './item';
import styles from './list.module.scss';

const cx = classNames.bind(styles);

export type OnIngredientsChange = (
  listId: string,
  selectedIds: string[],
  id: string,
  action: 'add' | 'move' | 'remove',
) => void;

interface Props {
  id: string;
  maxLength?: number;
  isSelectionList: boolean;
  isViewOnly?: boolean;
  getIngredients: (ids: string[]) => Promise<Ingredient[]>;
  visibleIds: string[];
  selectedIds: string[];
  onIngredientsChange?: OnIngredientsChange;
}

const IngredientPickerListComponent = ({
  id: listId,
  maxLength,
  isSelectionList,
  isViewOnly = false,
  getIngredients,
  visibleIds,
  selectedIds,
  onIngredientsChange,
}: Props) => {
  const { formatKeyword } = useFormat();

  const handleDragEndToSelect = (dropResult: DropResult) => {
    const { removedIndex, addedIndex, payload } = dropResult;

    const targetId: string = payload;
    if (addedIndex !== null) {
      const newIds = [...selectedIds];
      removedIndex !== null && newIds.splice(removedIndex, 1);
      newIds.splice(addedIndex, 0, targetId);
      onIngredientsChange?.(listId, newIds, targetId, 'move');
    }
  };

  const handleToggle = useCallback(
    (ingredient: Ingredient) => {
      if (smoothDnD?.isDragging() === true) {
        return;
      }

      const targetId = ingredient.id;
      if (selectedIds.includes(targetId)) {
        const newIds = selectedIds.filter(id => id !== targetId);
        onIngredientsChange?.(listId, newIds, targetId, 'remove');
      } else {
        const newIds = [...selectedIds, targetId];
        onIngredientsChange?.(listId, newIds, targetId, 'add');
      }
    },
    [selectedIds, listId, onIngredientsChange],
  );

  const handleRemove = useCallback(
    (ingredient: Ingredient) => {
      if (smoothDnD?.isDragging() === true) {
        return;
      }

      const targetId = ingredient.id;
      const newIds = selectedIds.filter(id => id !== targetId);
      onIngredientsChange?.(listId, newIds, targetId, 'remove');
    },
    [selectedIds, listId, onIngredientsChange],
  );

  const [ingredients, setIngredients] = useState<Ingredient[]>([]);

  useEffect(() => {
    getIngredients?.(visibleIds).then(setIngredients);
  }, [getIngredients, visibleIds]);

  const handleShouldAcceptDrop = useCallback(
    source => {
      if (!isSelectionList) {
        return false; // 죄측 목록
      } else if (source.groupName === listId) {
        return true; // 우측 같은 종류의 목록
      } else if (maxLength !== undefined) {
        return ingredients.length < maxLength; //우측 다른 종류의 목록에서부터 온 경우
      } else {
        return true; // 길이 제한이 없는 경우
      }
    },
    [ingredients, isSelectionList, listId, maxLength],
  );

  return (
    <div className={cx('container', { 'selection-list': isSelectionList })}>
      {maxLength && (
        <div className={cx('list')}>
          {ArrayUtils.numberStream(isViewOnly ? 1 : maxLength).map(index => (
            <div key={index} className={cx('placeholder-item')}>
              {!isViewOnly && formatKeyword('IngredientPickerDraggable')}
            </div>
          ))}
        </div>
      )}
      <div className={cx('list')}>
        <Container
          groupName={listId}
          onDrop={handleDragEndToSelect}
          behaviour={isSelectionList ? 'move' : 'copy'}
          getChildPayload={i => ingredients[i].id}
          shouldAcceptDrop={handleShouldAcceptDrop}
        >
          {ingredients.map((ingredient, index) => {
            const component = (
              <Item
                key={ingredient.id}
                {...{
                  ingredient,
                  priority: index + 1,
                  isSelected: selectedIds.includes(ingredient.id),
                  isSelectionList,
                  isViewOnly,
                  isLast: index === ingredients.length - 1,
                  onToggle: handleToggle,
                  onRemove: (isSelectionList && handleRemove) || undefined,
                }}
              />
            );

            const isDraggable =
              !isViewOnly && (isSelectionList || !selectedIds.includes(ingredient.id));

            return isDraggable ? <Draggable key={ingredient.id}>{component}</Draggable> : component;
          })}
        </Container>
      </div>
    </div>
  );
};

export default IngredientPickerListComponent;
