import { Category } from '@bringk/shared';

import MackPackNoseSheetImg from 'src/images/cosmetic/content/maskpack(nosepack-sheet).png';
import MackPackSheetImg from 'src/images/cosmetic/content/maskpack(sheet).png';
import SunCusionPuffImg from 'src/images/cosmetic/content/suncusion_puff.png';
import TonerPadImg from 'src/images/cosmetic/content/tonerpad(pad).png';

const getImagePath = (category: Category): string | null => {
  switch (category) {
    case Category.SKIN_CARE_MASK_NOSE:
      return MackPackNoseSheetImg;
    case Category.SKIN_CARE_MASK_SHEET:
      return MackPackSheetImg;
    case Category.SKIN_CARE_SUN_CUSHION:
      return SunCusionPuffImg;
    case Category.SKIN_CARE_TONER_PAD:
      return TonerPadImg;
    default:
      return null;
  }
};

export const getContentInfo = (category: Category): { imagePath: string } | null => {
  const imagePath = getImagePath(category);

  if (imagePath) {
    return {
      imagePath,
    };
  } else {
    return null;
  }
};
