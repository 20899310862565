import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames/bind';
import React, { useState, useCallback } from 'react';

import { Tooltip, ArrayUtils } from '@bringk/shared';

import useFormat from 'src/hooks/useFormat';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface Props {
  tooltips: Tooltip[];
}

const TooltipButton = (props: Props) => {
  const { formatPrefix } = useFormat();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onIconClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  if (ArrayUtils.isEmpty(props.tooltips)) {
    return null;
  }

  return (
    <div className={cx('container')}>
      <FontAwesomeIcon
        className={cx('tooltip-icon')}
        icon={faInfoCircle}
        size="xs"
        color="gray"
        onClick={onIconClick}
      />
      {isOpen && (
        <div className={cx('tooltip-popup')}>
          <div className={cx('tooltip-container')}>
            {props.tooltips &&
              props.tooltips.map(tooltip => {
                return (
                  <div key={tooltip.value} className={cx('tooltip-item')}>
                    {formatPrefix('Tooltips.', tooltip.value)}
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
};

export default TooltipButton;
