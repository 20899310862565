import React, { useContext } from 'react';

import { TemplateItem, ArrayUtils, TemplateItemType } from '@bringk/shared';

import ReceiptRecord from 'src/components/ReceiptRecord/index';
import useFormat from 'src/hooks/useFormat';
import TextInfo from 'src/pages/order/summary/TextInfo';
import TargetDistributionChannel from 'src/pages/order/templates/TargetDistributionChannel';
import AbstractTemplateItemSupporter from 'src/utils/template/AbstractTemplateItemSupporter';
import TemplateItemUtils from 'src/utils/TemplateItemUtils';

import { OrderSourceContext } from './OrderSource';

interface Props {
  templateItem: TemplateItem;
}

const TargetDistributionChannelSummaryComponent = () => {
  const { formatPrefix } = useFormat();
  const {
    product: { targetDistributionChannel: values },
  } = useContext(OrderSourceContext);
  if (ArrayUtils.isNotEmpty(values)) {
    return <TextInfo value={values.map(value => formatPrefix('Channel.', value))} />;
  }
  return null;
};

const TargetDistributionChannelReceiptComponent = ({ templateItem }: Props) => {
  const { format } = useFormat();
  const { formatPrefix } = useFormat();
  const {
    product: {
      targetDistributionChannel: values,
      description: { targetDistributionChannel: description },
    },
  } = useContext(OrderSourceContext);
  const shortTitleId = TemplateItemUtils.getShortTitle(templateItem.type);
  return (
    <ReceiptRecord
      label={format(shortTitleId)}
      value={values.map(value => formatPrefix('Channel.', value))}
      description={description}
    />
  );
};

export default class TargetDistributionChannelSupporter extends AbstractTemplateItemSupporter {
  support(templateItemType: TemplateItemType): boolean {
    return templateItemType === TemplateItemType.TARGET_DISTRIBUTION_CHANNEL;
  }
  render(templateItem: TemplateItem): React.ReactNode {
    return <TargetDistributionChannel templateItem={templateItem} />;
  }
  renderSummary(): React.ReactNode | null {
    return <TargetDistributionChannelSummaryComponent />;
  }
  renderReceipt(templateItem: TemplateItem): React.ReactNode | null {
    return <TargetDistributionChannelReceiptComponent templateItem={templateItem} />;
  }
  getTitleId(): string {
    return 'Order.Template.TargetDistributionChannel.Title';
  }
  getShortTitleId(): string {
    return 'Order.Template.TargetDistributionChannel.ShortTitle';
  }
}
