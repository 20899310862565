import { isString } from 'lodash';
import { observer } from 'mobx-react';
// import classNames from 'classnames/bind';
import React, { useCallback } from 'react';

import { TemplateItem } from '@bringk/shared';

import CheckBoxes from 'src/components/CheckBoxes';
import TextArea from 'src/components/TextArea';
import useFormat from 'src/hooks/useFormat';
import useStore from 'src/hooks/useStore';

// import styles from './index.module.scss';

// const cx = classNames.bind(styles);

interface Props {
  templateItem: TemplateItem;
}

const TargetCustomerAge = ({ templateItem }: Props) => {
  const { format, formatPrefix } = useFormat();
  const { orderStore } = useStore();
  const targetProperty = templateItem.property;
  const selectableItems = templateItem.choiceValues!.filter(isString).map(choiceValue => {
    const tooltip = templateItem.getTooltip(choiceValue);
    return {
      key: choiceValue,
      label: `${choiceValue}${format('Unit.Generation')}`,
      tooltip: tooltip ? formatPrefix('Tooltips.', tooltip.value) : undefined,
    };
  });

  const values: any[] = orderStore.product[targetProperty] ?? [];

  const handleItemAdded = useCallback(
    (itemKey?: string) => orderStore.setProductProperty(targetProperty, values.concat(itemKey)),
    [values, orderStore, targetProperty],
  );

  const handleItemRemoved = useCallback(
    (itemKey?: string) =>
      orderStore.setProductProperty(
        targetProperty,
        values.filter(prevValue => prevValue !== itemKey),
      ),
    [values, orderStore, targetProperty],
  );

  const handleTextAreaChanged = useCallback(
    event => {
      orderStore.setProductDescriptionProperty(targetProperty, event.currentTarget.value);
    },
    [targetProperty, orderStore],
  );

  return (
    <>
      <CheckBoxes
        selectedItemKeys={values}
        handleItemAdded={handleItemAdded}
        handleItemRemoved={handleItemRemoved}
        selectableItems={selectableItems}
      />
      <TextArea
        value={orderStore.product.description[targetProperty]}
        onChange={handleTextAreaChanged}
        placeholder={format('Tooltips.OtherRequest')}
      />
    </>
  );
};

export default observer(TargetCustomerAge);
