import classNames from 'classnames/bind';
import React, { useCallback, useMemo } from 'react';
import { smoothDnD } from 'react-smooth-dnd';

import { Ingredient } from '@bringk/shared';

import useFormat from 'src/hooks/useFormat';
import { ReactComponent as CheckSvg } from 'src/images/svg/icon_check_big.svg';
import { ReactComponent as RemoveSvg } from 'src/images/svg/icon_delete.svg';

import EwgIcon from './ewgIcon';
import styles from './item.module.scss';
import OriginIcon from './originIcon';

const cx = classNames.bind(styles);

interface Props {
  priority: number;
  ingredient: Ingredient;
  isSelected: boolean;
  isSelectionList: boolean;
  isViewOnly: boolean;
  onToggle?: (ingredient: Ingredient) => void;
  onRemove?: (ingredient: Ingredient) => void;
}

export const ingredientPickerItemType = 'IngredientPickerItemComponent';

const IngredientPickerItemComponent = ({
  priority,
  ingredient,
  isSelected,
  isSelectionList,
  isViewOnly,
  onToggle,
  onRemove,
}: Props) => {
  const { formatPrefix } = useFormat();

  const handleRemove = useCallback(() => {
    smoothDnD?.cancelDrag();
    onRemove?.(ingredient);
  }, [onRemove, ingredient]);

  const prioritySuffix = useMemo(() => {
    switch (priority % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }, [priority]);

  return (
    <div
      className={cx('container', { 'selection-list': isSelectionList })}
      onClick={() => !isSelectionList && onToggle?.(ingredient)}
    >
      {isSelectionList ? (
        <div className={cx('priority')}>
          {priority}
          {prioritySuffix}
        </div>
      ) : (
        <div className={cx('mark', { selected: isSelected })}>
          <CheckSvg />
        </div>
      )}
      <div className={cx('title')}>{formatPrefix('Ingredients.', ingredient.id)}</div>
      <div className={cx('ewg')}>
        <EwgIcon ewg={ingredient.ewg} />
      </div>
      <div className={cx('origin')}>
        <OriginIcon origin={ingredient.origin} />
      </div>
      {!isViewOnly && onRemove && (
        <div className={cx('remove')} onClick={handleRemove}>
          <RemoveSvg />
        </div>
      )}
    </div>
  );
};

export default IngredientPickerItemComponent;
