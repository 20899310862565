"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
const class_transformer_1 = require("class-transformer");
const TemplateItemType_1 = require("./TemplateItemType");
const Tooltip_1 = require("./Tooltip");
class TemplateItem {
    constructor() {
        this.type = TemplateItemType_1.TemplateItemType.NAME;
        this.typeof = 'string';
        this.property = '';
        this.isMultipleChoice = false;
        this.isRequired = false;
        this.isDescriptable = false;
        this.isExclude = false;
    }
    getTooltip(mapping) {
        if (!this.tooltips)
            return undefined;
        if (mapping === undefined)
            return this.getTooltip('');
        return this.tooltips.find(tooltip => tooltip.mapping === mapping);
    }
    getTooltips(mapping) {
        if (!this.tooltips)
            return [];
        if (mapping === undefined)
            return this.getTooltips('');
        return this.tooltips.filter(tooltip => tooltip.mapping === mapping);
    }
}
__decorate([
    class_transformer_1.Type(() => Tooltip_1.Tooltip)
], TemplateItem.prototype, "tooltips", void 0);
exports.TemplateItem = TemplateItem;
