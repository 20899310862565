import classNames from 'classnames/bind';
import debounce from 'lodash/debounce';
import escapeRegExp from 'lodash/escapeRegExp';
import sortBy from 'lodash/sortBy';
import React, { useMemo, useState, useCallback, useRef } from 'react';
import { smoothDnD } from 'react-smooth-dnd';

import {
  Ingredient,
  IngredientFilter,
  IngredientEwg,
  IngredientOrigin,
  Database,
  Category,
} from '@bringk/shared';

import ScrollableContainer from 'src/components/ScrollableContainer/index';
import useFormat from 'src/hooks/useFormat';
import { CategoryUtils } from 'src/utils/CategoryUtils';

import { MAIN_CONCEPT_INGREDIENT_MAX_COUNT, SUB_CONCEPT_INGREDIENT_MAX_COUNT } from './config';
import EwgIcon from './ewgIcon';
import styles from './index.module.scss';
import List, { OnIngredientsChange } from './list';
import OriginIcon from './originIcon';
import Picker from './picker';
import SearchBar from './searchBar';
import SelectedLists from './selectedLists';

const cx = classNames.bind(styles);

interface Props {
  category?: Category;
  selectedMainIds: string[];
  selectedSubIds: string[];
  onClose?: (mainIngredientIds: string[], subIngredientIds: string[]) => void;
}

enum SortType {
  RECOMMENDATION = 'RECOMMENDATION',
  ALPHABET = 'ALPHABET',
}

const defaultOriginFilters: IngredientOrigin[] = [
  'ANIMAL',
  'FERMENTATION',
  'GRAIN',
  'MARINE',
  'VEGETABLE',
  'NONE',
];

const defaultEwgFilters: IngredientEwg[] = ['GREEN', 'YELLOW', 'NONE'];

interface Name {
  name: string;
}

const IngredientPickerComponent = ({
  category,
  selectedMainIds: initialSelectedMainIds,
  selectedSubIds: initialSelectedSubIds,
  onClose,
}: Props) => {
  const { formatPrefix, formatKeyword } = useFormat();
  const db = Database.ingredients;

  const [selectedMainIds, setSelectedMainIds] = useState<string[]>(initialSelectedMainIds || []);
  const [selectedSubIds, setSelectedSubIds] = useState<string[]>(initialSelectedSubIds || []);
  const [groupFilter, setGroupFilter] = useState<IngredientFilter | null>(null);
  const [originFilters, setOriginFilters] = useState<IngredientOrigin[]>(defaultOriginFilters);
  const [ewgFilters, setEwgFilters] = useState<IngredientEwg[]>(defaultEwgFilters);
  const [searchKeyword, setSearchKeyword] = useState('');
  const sortType = groupFilter ? SortType.RECOMMENDATION : SortType.ALPHABET;

  const handleToggleOriginFilter = useCallback(
    origin =>
      setOriginFilters(oldFilters =>
        oldFilters.includes(origin)
          ? oldFilters.filter(oldFilter => oldFilter !== (origin as IngredientOrigin))
          : [...oldFilters, origin as IngredientOrigin],
      ),
    [setOriginFilters],
  );

  const handleToggleEwgFilter = useCallback(
    ewg =>
      setEwgFilters(oldFilters =>
        oldFilters.includes(ewg)
          ? oldFilters.filter(oldFilter => oldFilter !== (ewg as IngredientEwg))
          : [...oldFilters, ewg as IngredientEwg],
      ),
    [setEwgFilters],
  );

  const debounced = useRef(debounce((keyword?: string) => setSearchKeyword(keyword || ''), 200));
  const handleSearchChange = useCallback(keyword => debounced.current(keyword), [debounced]);

  const handleSave = useCallback(() => {
    smoothDnD?.cancelDrag();
    onClose?.(selectedMainIds, selectedSubIds);
  }, [onClose, selectedMainIds, selectedSubIds]);

  const filteredIds = useMemo(() => {
    let ingredients: (Ingredient & Name)[] = db.getGroupedAll(groupFilter).map(ingredient => {
      return {
        ...ingredient,
        name: formatPrefix('Ingredients.', ingredient.id),
      };
    });
    ingredients = ingredients.filter(ingredient => ewgFilters.includes(ingredient.ewg));
    ingredients = ingredients.filter(ingredient => originFilters.includes(ingredient.origin));
    if (searchKeyword.length > 0) {
      const re = new RegExp(escapeRegExp(searchKeyword), 'i');
      ingredients = ingredients.filter((ingredient: Ingredient & Name) => re.test(ingredient.name));
    }
    if (sortType === SortType.ALPHABET) {
      ingredients = sortBy(ingredients, 'name');
    }
    return ingredients.map(ingredient => ingredient.id);
  }, [db, formatPrefix, groupFilter, ewgFilters, originFilters, searchKeyword, sortType]);

  const handleIngredientsChange: OnIngredientsChange = useCallback(
    (listId, selectedIds, targetId, action) => {
      if (listId === 'main') {
        setSelectedMainIds(selectedIds);
        setSelectedSubIds(ids => [...ids].filter(id => id !== targetId));
      } else if (listId === 'sub') {
        setSelectedMainIds(ids => [...ids].filter(id => id !== targetId));
        setSelectedSubIds(selectedIds);
      } else if (listId === 'copy') {
        if (action === 'add') {
          setSelectedMainIds(ids => {
            if (ids.length < MAIN_CONCEPT_INGREDIENT_MAX_COUNT) {
              return [...ids, targetId];
            } else {
              setSelectedSubIds(ids => {
                if (ids.length < SUB_CONCEPT_INGREDIENT_MAX_COUNT) {
                  return [...ids, targetId];
                } else {
                  return ids;
                }
              });
              return ids;
            }
          });
        } else {
          setSelectedMainIds(ids => [...ids].filter(id => selectedIds.includes(id)));
          setSelectedSubIds(ids => [...ids].filter(id => selectedIds.includes(id)));
        }
      }
    },
    [setSelectedMainIds, setSelectedSubIds],
  );

  const getIngredients = useCallback(
    async (ids: string[]) =>
      ids.map(id => db.get(id)).filter((id): id is Ingredient => id !== null),
    [db],
  );

  const topCategory = category && CategoryUtils.getTopParent(category);
  const groupFilters = topCategory ? db.getGroups(topCategory) : [];

  return (
    <div className={cx('container')}>
      <div className={cx('box')}>
        <div className={cx('top-menu')}>
          <div className={cx('left')}>
            <SearchBar placeholder={formatKeyword('Search')} onChange={handleSearchChange} />
            <Picker
              title={
                groupFilter && [groupFilter.id]
                  ? formatPrefix('IngredientGroupFilters.', groupFilter.id)
                  : formatPrefix('IngredientGroupFilters.', 'NAME')
              }
              minWidth={180}
              isMultipleChoice={false}
              selectedKeys={(groupFilter && [groupFilter.id]) || []}
              onClick={filterId => {
                const newFilter = groupFilters.find(filter => filter.id === filterId) || null;
                setGroupFilter(oldFilter => (oldFilter === newFilter ? null : newFilter));
              }}
              items={groupFilters.map(filter => ({
                key: filter.id,
                element: <div>{formatPrefix('IngredientGroupFilters.', filter.id)}</div>,
              }))}
            />
            <Picker
              title={formatPrefix('IngredientOriginFilters.', 'NAME')}
              minWidth={162}
              isMultipleChoice={true}
              selectedKeys={originFilters}
              onClick={filter => handleToggleOriginFilter(filter)}
              items={defaultOriginFilters.map(filter => ({
                key: filter,
                element: (
                  <div className={cx('picker-item')}>
                    <OriginIcon origin={filter} />
                    {formatPrefix('IngredientOriginFilters.', filter)}
                  </div>
                ),
              }))}
            />
            <Picker
              title={formatPrefix('IngredientEwgFilters.', 'NAME')}
              minWidth={135}
              isMultipleChoice={true}
              selectedKeys={ewgFilters}
              onClick={filter => handleToggleEwgFilter(filter)}
              items={defaultEwgFilters.map(filter => ({
                key: filter,
                element: (
                  <div className={cx('picker-item')}>
                    <EwgIcon ewg={filter} />
                    {formatPrefix('IngredientEwgFilters.', filter)}
                  </div>
                ),
              }))}
            />
          </div>
          <div className={cx('right')}>
            <div className={cx('save')} onClick={handleSave}>
              {formatKeyword('Confirm')}
            </div>
          </div>
        </div>
        <div className={cx('content')}>
          <div className={cx('left')}>
            <ScrollableContainer>
              <List
                {...{
                  id: 'copy',
                  isSelectionList: false,
                  getIngredients,
                  visibleIds: filteredIds,
                  selectedIds: [...selectedMainIds, ...selectedSubIds],
                  onIngredientsChange: handleIngredientsChange,
                }}
              />
            </ScrollableContainer>
          </div>
          <div className={cx('right')}>
            <ScrollableContainer className={cx('right-container')}>
              <SelectedLists
                {...{
                  getIngredients,
                  selectedMainIds,
                  selectedSubIds,
                  onIngredientsChange: handleIngredientsChange,
                }}
              />
            </ScrollableContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IngredientPickerComponent;
