"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const TemplateItemType_1 = require("../TemplateItemType");
const AbstractTemplateItemWorker_1 = require("./AbstractTemplateItemWorker");
const ValidationChainer_1 = require("./ValidationChainer");
class TargetBoxWorker extends AbstractTemplateItemWorker_1.AbstractTemplateItemWorker {
    support(templateItem) {
        return templateItem.type === TemplateItemType_1.TemplateItemType.TARGET_BOX;
    }
    initialize(templateItem, producer, product) {
        product.boxColor = null;
        product.boxImage = null;
        product.boxRefBrand = null;
        product.boxRefProduct = null;
    }
    validate(templateItem, producer, product) {
        ValidationChainer_1.ValidationChainer.property(product.boxColor)
            .propertyName('box color')
            .stringOptional()
            .stringLimit();
        ValidationChainer_1.ValidationChainer.property(product.boxImage)
            .propertyName('box image')
            .stringOptional()
            .stringLimit();
        ValidationChainer_1.ValidationChainer.property(product.boxRefBrand)
            .propertyName('box reference brand')
            .stringOptional()
            .stringLimit();
        ValidationChainer_1.ValidationChainer.property(product.boxRefProduct)
            .propertyName('box reference product')
            .stringOptional()
            .stringLimit();
    }
}
exports.TargetBoxWorker = TargetBoxWorker;
