import { render } from '@testing-library/react';
import classNames from 'classnames/bind';
import React from 'react';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface Props {
  label: string;
  contents?: { key: string; value?: string | null }[];
  sequence?: boolean;
  description?: string;
}

const ReceiptRecordKeyValue = (props: Props) => {
  return (
    <div className={cx('receipt-record')}>
      <div className={cx('record-key')}>{props.label}</div>
      <div className={cx('record-value')}>
        {props.contents &&
          props.contents.map((value, index: number) => {
            return (
              <div className={cx('record-value-element')} key={value.key + value.value}>
                <div className={cx('key')}>
                  {props.sequence ? `${index + 1}. ${value.key}` : `${value.key}`}
                </div>
                <div className={cx('value')}>{value.value}</div>
              </div>
            );
          })}
        {props.description && <div className={cx('description')}>{`* ${props.description}`}</div>}
      </div>
    </div>
  );
};

export default ReceiptRecordKeyValue;
