// data from
// https://github.com/country-regions/country-region-data/blob/master/data.json
// https://countrycode.org/

interface CountryEntity {
  countryName: string;
  countryCodes: string[];
  isoCode2: string;
  isoCode3: string;
  isVirtual?: boolean;
}

const CountryEntities = [
  { countryName: '', countryCodes: [], isoCode2: '', isoCode3: '' },
  { countryName: 'Afghanistan', countryCodes: ['93'], isoCode2: 'AF', isoCode3: 'AFG' },
  { countryName: 'Albania', countryCodes: ['355'], isoCode2: 'AL', isoCode3: 'ALB' },
  { countryName: 'Algeria', countryCodes: ['213'], isoCode2: 'DZ', isoCode3: 'DZA' },
  { countryName: 'American Samoa', countryCodes: ['1-684'], isoCode2: 'AS', isoCode3: 'ASM' },
  { countryName: 'Andorra', countryCodes: ['376'], isoCode2: 'AD', isoCode3: 'AND' },
  { countryName: 'Angola', countryCodes: ['244'], isoCode2: 'AO', isoCode3: 'AGO' },
  { countryName: 'Anguilla', countryCodes: ['1-264'], isoCode2: 'AI', isoCode3: 'AIA' },
  { countryName: 'Antarctica', countryCodes: ['672'], isoCode2: 'AQ', isoCode3: 'ATA' },
  { countryName: 'Antigua and Barbuda', countryCodes: ['1-268'], isoCode2: 'AG', isoCode3: 'ATG' },
  { countryName: 'Argentina', countryCodes: ['54'], isoCode2: 'AR', isoCode3: 'ARG' },
  { countryName: 'Armenia', countryCodes: ['374'], isoCode2: 'AM', isoCode3: 'ARM' },
  { countryName: 'Aruba', countryCodes: ['297'], isoCode2: 'AW', isoCode3: 'ABW' },
  { countryName: 'Australia', countryCodes: ['61'], isoCode2: 'AU', isoCode3: 'AUS' },
  { countryName: 'Austria', countryCodes: ['43'], isoCode2: 'AT', isoCode3: 'AUT' },
  { countryName: 'Azerbaijan', countryCodes: ['994'], isoCode2: 'AZ', isoCode3: 'AZE' },
  { countryName: 'Bahamas', countryCodes: ['1-242'], isoCode2: 'BS', isoCode3: 'BHS' },
  { countryName: 'Bahrain', countryCodes: ['973'], isoCode2: 'BH', isoCode3: 'BHR' },
  { countryName: 'Bangladesh', countryCodes: ['880'], isoCode2: 'BD', isoCode3: 'BGD' },
  { countryName: 'Barbados', countryCodes: ['1-246'], isoCode2: 'BB', isoCode3: 'BRB' },
  { countryName: 'Belarus', countryCodes: ['375'], isoCode2: 'BY', isoCode3: 'BLR' },
  { countryName: 'Belgium', countryCodes: ['32'], isoCode2: 'BE', isoCode3: 'BEL' },
  { countryName: 'Belize', countryCodes: ['501'], isoCode2: 'BZ', isoCode3: 'BLZ' },
  { countryName: 'Benin', countryCodes: ['229'], isoCode2: 'BJ', isoCode3: 'BEN' },
  { countryName: 'Bermuda', countryCodes: ['1-441'], isoCode2: 'BM', isoCode3: 'BMU' },
  { countryName: 'Bhutan', countryCodes: ['975'], isoCode2: 'BT', isoCode3: 'BTN' },
  { countryName: 'Bolivia', countryCodes: ['591'], isoCode2: 'BO', isoCode3: 'BOL' },
  { countryName: 'Bosnia and Herzegovina', countryCodes: ['387'], isoCode2: 'BA', isoCode3: 'BIH' },
  { countryName: 'Botswana', countryCodes: ['267'], isoCode2: 'BW', isoCode3: 'BWA' },
  { countryName: 'Brazil', countryCodes: ['55'], isoCode2: 'BR', isoCode3: 'BRA' },
  {
    countryName: 'British Indian Ocean Territory',
    countryCodes: ['246'],
    isoCode2: 'IO',
    isoCode3: 'IOT',
  },
  { countryName: 'Brunei', countryCodes: ['673'], isoCode2: 'BN', isoCode3: 'BRN' },
  { countryName: 'Bulgaria', countryCodes: ['359'], isoCode2: 'BG', isoCode3: 'BGR' },
  { countryName: 'Burkina Faso', countryCodes: ['226'], isoCode2: 'BF', isoCode3: 'BFA' },
  { countryName: 'Burundi', countryCodes: ['257'], isoCode2: 'BI', isoCode3: 'BDI' },
  { countryName: 'Cambodia', countryCodes: ['855'], isoCode2: 'KH', isoCode3: 'KHM' },
  { countryName: 'Cameroon', countryCodes: ['237'], isoCode2: 'CM', isoCode3: 'CMR' },
  { countryName: 'Canada', countryCodes: ['1'], isoCode2: 'CA', isoCode3: 'CAN' },
  { countryName: 'Cape Verde', countryCodes: ['238'], isoCode2: 'CV', isoCode3: 'CPV' },
  { countryName: 'Cayman Islands', countryCodes: ['1-345'], isoCode2: 'KY', isoCode3: 'CYM' },
  {
    countryName: 'Central African Republic',
    countryCodes: ['236'],
    isoCode2: 'CF',
    isoCode3: 'CAF',
  },
  { countryName: 'Chad', countryCodes: ['235'], isoCode2: 'TD', isoCode3: 'TCD' },
  { countryName: 'Chile', countryCodes: ['56'], isoCode2: 'CL', isoCode3: 'CHL' },
  { countryName: 'China', countryCodes: ['86'], isoCode2: 'CN', isoCode3: 'CHN' },
  { countryName: 'Christmas Island', countryCodes: ['61'], isoCode2: 'CX', isoCode3: 'CXR' },
  { countryName: 'Cocos Islands', countryCodes: ['61'], isoCode2: 'CC', isoCode3: 'CCK' },
  { countryName: 'Colombia', countryCodes: ['57'], isoCode2: 'CO', isoCode3: 'COL' },
  { countryName: 'Comoros', countryCodes: ['269'], isoCode2: 'KM', isoCode3: 'COM' },
  { countryName: 'Republic of the Congo', countryCodes: ['242'], isoCode2: 'CG', isoCode3: 'COG' },
  {
    countryName: 'Democratic Republic of the Congo',
    countryCodes: ['243'],
    isoCode2: 'CD',
    isoCode3: 'COD',
  },
  { countryName: 'Cook Islands', countryCodes: ['682'], isoCode2: 'CK', isoCode3: 'COK' },
  { countryName: 'Costa Rica', countryCodes: ['506'], isoCode2: 'CR', isoCode3: 'CRI' },
  { countryName: 'Ivory Coast', countryCodes: ['225'], isoCode2: 'CI', isoCode3: 'CIV' },
  { countryName: 'Croatia', countryCodes: ['385'], isoCode2: 'HR', isoCode3: 'HRV' },
  { countryName: 'Cuba', countryCodes: ['53'], isoCode2: 'CU', isoCode3: 'CUB' },
  { countryName: 'Curacao', countryCodes: ['599'], isoCode2: 'CW', isoCode3: 'CUW' },
  { countryName: 'Cyprus', countryCodes: ['357'], isoCode2: 'CY', isoCode3: 'CYP' },
  { countryName: 'Czech Republic', countryCodes: ['420'], isoCode2: 'CZ', isoCode3: 'CZE' },
  { countryName: 'Denmark', countryCodes: ['45'], isoCode2: 'DK', isoCode3: 'DNK' },
  { countryName: 'Djibouti', countryCodes: ['253'], isoCode2: 'DJ', isoCode3: 'DJI' },
  { countryName: 'Dominica', countryCodes: ['1-767'], isoCode2: 'DM', isoCode3: 'DMA' },
  {
    countryName: 'Dominican Republic',
    countryCodes: ['1-809', '1-829', '1-849'],
    isoCode2: 'DO',
    isoCode3: 'DOM',
  },
  { countryName: 'Ecuador', countryCodes: ['593'], isoCode2: 'EC', isoCode3: 'ECU' },
  { countryName: 'Egypt', countryCodes: ['20'], isoCode2: 'EG', isoCode3: 'EGY' },
  { countryName: 'El Salvador', countryCodes: ['503'], isoCode2: 'SV', isoCode3: 'SLV' },
  { countryName: 'Equatorial Guinea', countryCodes: ['240'], isoCode2: 'GQ', isoCode3: 'GNQ' },
  { countryName: 'Eritrea', countryCodes: ['291'], isoCode2: 'ER', isoCode3: 'ERI' },
  { countryName: 'Estonia', countryCodes: ['372'], isoCode2: 'EE', isoCode3: 'EST' },
  { countryName: 'Ethiopia', countryCodes: ['251'], isoCode2: 'ET', isoCode3: 'ETH' },
  { countryName: 'Falkland Islands', countryCodes: ['500'], isoCode2: 'FK', isoCode3: 'FLK' },
  { countryName: 'Faroe Islands', countryCodes: ['298'], isoCode2: 'FO', isoCode3: 'FRO' },
  { countryName: 'Fiji', countryCodes: ['679'], isoCode2: 'FJ', isoCode3: 'FJI' },
  { countryName: 'Finland', countryCodes: ['358'], isoCode2: 'FI', isoCode3: 'FIN' },
  { countryName: 'France', countryCodes: ['33'], isoCode2: 'FR', isoCode3: 'FRA' },
  { countryName: 'French Polynesia', countryCodes: ['689'], isoCode2: 'PF', isoCode3: 'PYF' },
  { countryName: 'Gabon', countryCodes: ['241'], isoCode2: 'GA', isoCode3: 'GAB' },
  { countryName: 'Gambia', countryCodes: ['220'], isoCode2: 'GM', isoCode3: 'GMB' },
  { countryName: 'Georgia', countryCodes: ['995'], isoCode2: 'GE', isoCode3: 'GEO' },
  { countryName: 'Germany', countryCodes: ['49'], isoCode2: 'DE', isoCode3: 'DEU' },
  { countryName: 'Ghana', countryCodes: ['233'], isoCode2: 'GH', isoCode3: 'GHA' },
  { countryName: 'Gibraltar', countryCodes: ['350'], isoCode2: 'GI', isoCode3: 'GIB' },
  { countryName: 'Greece', countryCodes: ['30'], isoCode2: 'GR', isoCode3: 'GRC' },
  { countryName: 'Greenland', countryCodes: ['299'], isoCode2: 'GL', isoCode3: 'GRL' },
  { countryName: 'Grenada', countryCodes: ['1-473'], isoCode2: 'GD', isoCode3: 'GRD' },
  { countryName: 'Guam', countryCodes: ['1-671'], isoCode2: 'GU', isoCode3: 'GUM' },
  { countryName: 'Guatemala', countryCodes: ['502'], isoCode2: 'GT', isoCode3: 'GTM' },
  { countryName: 'Guernsey', countryCodes: ['44-1481'], isoCode2: 'GG', isoCode3: 'GGY' },
  { countryName: 'Guinea', countryCodes: ['224'], isoCode2: 'GN', isoCode3: 'GIN' },
  { countryName: 'Guinea-Bissau', countryCodes: ['245'], isoCode2: 'GW', isoCode3: 'GNB' },
  { countryName: 'Guyana', countryCodes: ['592'], isoCode2: 'GY', isoCode3: 'GUY' },
  { countryName: 'Haiti', countryCodes: ['509'], isoCode2: 'HT', isoCode3: 'HTI' },
  { countryName: 'Vatican', countryCodes: ['379'], isoCode2: 'VA', isoCode3: 'VAT' },
  { countryName: 'Honduras', countryCodes: ['504'], isoCode2: 'HN', isoCode3: 'HND' },
  { countryName: 'Hong Kong', countryCodes: ['852'], isoCode2: 'HK', isoCode3: 'HKG' },
  { countryName: 'Hungary', countryCodes: ['36'], isoCode2: 'HU', isoCode3: 'HUN' },
  { countryName: 'Iceland', countryCodes: ['354'], isoCode2: 'IS', isoCode3: 'ISL' },
  { countryName: 'India', countryCodes: ['91'], isoCode2: 'IN', isoCode3: 'IND' },
  { countryName: 'Indonesia', countryCodes: ['62'], isoCode2: 'ID', isoCode3: 'IDN' },
  { countryName: 'Iran', countryCodes: ['98'], isoCode2: 'IR', isoCode3: 'IRN' },
  { countryName: 'Iraq', countryCodes: ['964'], isoCode2: 'IQ', isoCode3: 'IRQ' },
  { countryName: 'Ireland', countryCodes: ['353'], isoCode2: 'IE', isoCode3: 'IRL' },
  { countryName: 'Isle of Man', countryCodes: ['44-1624'], isoCode2: 'IM', isoCode3: 'IMN' },
  { countryName: 'Israel', countryCodes: ['972'], isoCode2: 'IL', isoCode3: 'ISR' },
  { countryName: 'Italy', countryCodes: ['39'], isoCode2: 'IT', isoCode3: 'ITA' },
  { countryName: 'Jamaica', countryCodes: ['1-876'], isoCode2: 'JM', isoCode3: 'JAM' },
  { countryName: 'Japan', countryCodes: ['81'], isoCode2: 'JP', isoCode3: 'JPN' },
  { countryName: 'Jersey', countryCodes: ['44-1534'], isoCode2: 'JE', isoCode3: 'JEY' },
  { countryName: 'Jordan', countryCodes: ['962'], isoCode2: 'JO', isoCode3: 'JOR' },
  { countryName: 'Kazakhstan', countryCodes: ['7'], isoCode2: 'KZ', isoCode3: 'KAZ' },
  { countryName: 'Kenya', countryCodes: ['254'], isoCode2: 'KE', isoCode3: 'KEN' },
  { countryName: 'Kiribati', countryCodes: ['686'], isoCode2: 'KI', isoCode3: 'KIR' },
  { countryName: 'North Korea', countryCodes: ['850'], isoCode2: 'KP', isoCode3: 'PRK' },
  { countryName: 'South Korea', countryCodes: ['82'], isoCode2: 'KR', isoCode3: 'KOR' },
  { countryName: 'Kuwait', countryCodes: ['965'], isoCode2: 'KW', isoCode3: 'KWT' },
  { countryName: 'Kyrgyzstan', countryCodes: ['996'], isoCode2: 'KG', isoCode3: 'KGZ' },
  { countryName: 'Laos', countryCodes: ['856'], isoCode2: 'LA', isoCode3: 'LAO' },
  { countryName: 'Latvia', countryCodes: ['371'], isoCode2: 'LV', isoCode3: 'LVA' },
  { countryName: 'Lebanon', countryCodes: ['961'], isoCode2: 'LB', isoCode3: 'LBN' },
  { countryName: 'Lesotho', countryCodes: ['266'], isoCode2: 'LS', isoCode3: 'LSO' },
  { countryName: 'Liberia', countryCodes: ['231'], isoCode2: 'LR', isoCode3: 'LBR' },
  { countryName: 'Libya', countryCodes: ['218'], isoCode2: 'LY', isoCode3: 'LBY' },
  { countryName: 'Liechtenstein', countryCodes: ['423'], isoCode2: 'LI', isoCode3: 'LIE' },
  { countryName: 'Lithuania', countryCodes: ['370'], isoCode2: 'LT', isoCode3: 'LTU' },
  { countryName: 'Luxembourg', countryCodes: ['352'], isoCode2: 'LU', isoCode3: 'LUX' },
  { countryName: 'Macau', countryCodes: ['853'], isoCode2: 'MO', isoCode3: 'MAC' },
  { countryName: 'Macedonia', countryCodes: ['389'], isoCode2: 'MK', isoCode3: 'MKD' },
  { countryName: 'Madagascar', countryCodes: ['261'], isoCode2: 'MG', isoCode3: 'MDG' },
  { countryName: 'Malawi', countryCodes: ['265'], isoCode2: 'MW', isoCode3: 'MWI' },
  { countryName: 'Malaysia', countryCodes: ['60'], isoCode2: 'MY', isoCode3: 'MYS' },
  { countryName: 'Maldives', countryCodes: ['960'], isoCode2: 'MV', isoCode3: 'MDV' },
  { countryName: 'Mali', countryCodes: ['223'], isoCode2: 'ML', isoCode3: 'MLI' },
  { countryName: 'Malta', countryCodes: ['356'], isoCode2: 'MT', isoCode3: 'MLT' },
  { countryName: 'Marshall Islands', countryCodes: ['692'], isoCode2: 'MH', isoCode3: 'MHL' },
  { countryName: 'Mauritania', countryCodes: ['222'], isoCode2: 'MR', isoCode3: 'MRT' },
  { countryName: 'Mauritius', countryCodes: ['230'], isoCode2: 'MU', isoCode3: 'MUS' },
  { countryName: 'Mayotte', countryCodes: ['262'], isoCode2: 'YT', isoCode3: 'MYT' },
  { countryName: 'Mexico', countryCodes: ['52'], isoCode2: 'MX', isoCode3: 'MEX' },
  { countryName: 'Micronesia', countryCodes: ['691'], isoCode2: 'FM', isoCode3: 'FSM' },
  { countryName: 'Moldova', countryCodes: ['373'], isoCode2: 'MD', isoCode3: 'MDA' },
  { countryName: 'Monaco', countryCodes: ['377'], isoCode2: 'MC', isoCode3: 'MCO' },
  { countryName: 'Mongolia', countryCodes: ['976'], isoCode2: 'MN', isoCode3: 'MNG' },
  { countryName: 'Montenegro', countryCodes: ['382'], isoCode2: 'ME', isoCode3: 'MNE' },
  { countryName: 'Montserrat', countryCodes: ['1-664'], isoCode2: 'MS', isoCode3: 'MSR' },
  { countryName: 'Morocco', countryCodes: ['212'], isoCode2: 'MA', isoCode3: 'MAR' },
  { countryName: 'Mozambique', countryCodes: ['258'], isoCode2: 'MZ', isoCode3: 'MOZ' },
  { countryName: 'Myanmar', countryCodes: ['95'], isoCode2: 'MM', isoCode3: 'MMR' },
  { countryName: 'Namibia', countryCodes: ['264'], isoCode2: 'NA', isoCode3: 'NAM' },
  { countryName: 'Nauru', countryCodes: ['674'], isoCode2: 'NR', isoCode3: 'NRU' },
  { countryName: 'Nepal', countryCodes: ['977'], isoCode2: 'NP', isoCode3: 'NPL' },
  { countryName: 'Netherlands', countryCodes: ['31'], isoCode2: 'NL', isoCode3: 'NLD' },
  { countryName: 'New Caledonia', countryCodes: ['687'], isoCode2: 'NC', isoCode3: 'NCL' },
  { countryName: 'New Zealand', countryCodes: ['64'], isoCode2: 'NZ', isoCode3: 'NZL' },
  { countryName: 'Nicaragua', countryCodes: ['505'], isoCode2: 'NI', isoCode3: 'NIC' },
  { countryName: 'Niger', countryCodes: ['227'], isoCode2: 'NE', isoCode3: 'NER' },
  { countryName: 'Nigeria', countryCodes: ['234'], isoCode2: 'NG', isoCode3: 'NGA' },
  { countryName: 'Niue', countryCodes: ['683'], isoCode2: 'NU', isoCode3: 'NIU' },
  {
    countryName: 'Northern Mariana Islands',
    countryCodes: ['1-670'],
    isoCode2: 'MP',
    isoCode3: 'MNP',
  },
  { countryName: 'Norway', countryCodes: ['47'], isoCode2: 'NO', isoCode3: 'NOR' },
  { countryName: 'Oman', countryCodes: ['968'], isoCode2: 'OM', isoCode3: 'OMN' },
  { countryName: 'Pakistan', countryCodes: ['92'], isoCode2: 'PK', isoCode3: 'PAK' },
  { countryName: 'Palau', countryCodes: ['680'], isoCode2: 'PW', isoCode3: 'PLW' },
  { countryName: 'Palestine', countryCodes: ['970'], isoCode2: 'PS', isoCode3: 'PSE' },
  { countryName: 'Panama', countryCodes: ['507'], isoCode2: 'PA', isoCode3: 'PAN' },
  { countryName: 'Papua New Guinea', countryCodes: ['675'], isoCode2: 'PG', isoCode3: 'PNG' },
  { countryName: 'Paraguay', countryCodes: ['595'], isoCode2: 'PY', isoCode3: 'PRY' },
  { countryName: 'Peru', countryCodes: ['51'], isoCode2: 'PE', isoCode3: 'PER' },
  { countryName: 'Philippines', countryCodes: ['63'], isoCode2: 'PH', isoCode3: 'PHL' },
  { countryName: 'Pitcairn', countryCodes: ['64'], isoCode2: 'PN', isoCode3: 'PCN' },
  { countryName: 'Poland', countryCodes: ['48'], isoCode2: 'PL', isoCode3: 'POL' },
  { countryName: 'Portugal', countryCodes: ['351'], isoCode2: 'PT', isoCode3: 'PRT' },
  { countryName: 'Puerto Rico', countryCodes: ['1-787', '1-939'], isoCode2: 'PR', isoCode3: 'PRI' },
  { countryName: 'Qatar', countryCodes: ['974'], isoCode2: 'QA', isoCode3: 'QAT' },
  { countryName: 'Reunion', countryCodes: ['262'], isoCode2: 'RE', isoCode3: 'REU' },
  { countryName: 'Romania', countryCodes: ['40'], isoCode2: 'RO', isoCode3: 'ROU' },
  { countryName: 'Russia', countryCodes: ['7'], isoCode2: 'RU', isoCode3: 'RUS' },
  { countryName: 'Rwanda', countryCodes: ['250'], isoCode2: 'RW', isoCode3: 'RWA' },
  { countryName: 'Saint Barthelemy', countryCodes: ['590'], isoCode2: 'BL', isoCode3: 'BLM' },
  { countryName: 'Saint Helena', countryCodes: ['290'], isoCode2: 'SH', isoCode3: 'SHN' },
  {
    countryName: 'Saint Kitts and Nevis',
    countryCodes: ['1-869'],
    isoCode2: 'KN',
    isoCode3: 'KNA',
  },
  { countryName: 'Saint Lucia', countryCodes: ['1-758'], isoCode2: 'LC', isoCode3: 'LCA' },
  { countryName: 'Saint Martin', countryCodes: ['590'], isoCode2: 'MF', isoCode3: 'MAF' },
  {
    countryName: 'Saint Pierre and Miquelon',
    countryCodes: ['508'],
    isoCode2: 'PM',
    isoCode3: 'SPM',
  },
  {
    countryName: 'Saint Vincent and the Grenadines',
    countryCodes: ['1-784'],
    isoCode2: 'VC',
    isoCode3: 'VCT',
  },
  { countryName: 'Samoa', countryCodes: ['685'], isoCode2: 'WS', isoCode3: 'WSM' },
  { countryName: 'San Marino', countryCodes: ['378'], isoCode2: 'SM', isoCode3: 'SMR' },
  { countryName: 'Sao Tome and Principe', countryCodes: ['239'], isoCode2: 'ST', isoCode3: 'STP' },
  { countryName: 'Saudi Arabia', countryCodes: ['966'], isoCode2: 'SA', isoCode3: 'SAU' },
  { countryName: 'Senegal', countryCodes: ['221'], isoCode2: 'SN', isoCode3: 'SEN' },
  { countryName: 'Serbia', countryCodes: ['381'], isoCode2: 'RS', isoCode3: 'SRB' },
  { countryName: 'Seychelles', countryCodes: ['248'], isoCode2: 'SC', isoCode3: 'SYC' },
  { countryName: 'Sierra Leone', countryCodes: ['232'], isoCode2: 'SL', isoCode3: 'SLE' },
  { countryName: 'Singapore', countryCodes: ['65'], isoCode2: 'SG', isoCode3: 'SGP' },
  { countryName: 'Sint Maarten', countryCodes: ['1-721'], isoCode2: 'SX', isoCode3: 'SXM' },
  { countryName: 'Slovakia', countryCodes: ['421'], isoCode2: 'SK', isoCode3: 'SVK' },
  { countryName: 'Slovenia', countryCodes: ['386'], isoCode2: 'SI', isoCode3: 'SVN' },
  { countryName: 'Solomon Islands', countryCodes: ['677'], isoCode2: 'SB', isoCode3: 'SLB' },
  { countryName: 'Somalia', countryCodes: ['252'], isoCode2: 'SO', isoCode3: 'SOM' },
  { countryName: 'South Africa', countryCodes: ['27'], isoCode2: 'ZA', isoCode3: 'ZAF' },
  { countryName: 'South Sudan', countryCodes: ['211'], isoCode2: 'SS', isoCode3: 'SSD' },
  { countryName: 'Spain', countryCodes: ['34'], isoCode2: 'ES', isoCode3: 'ESP' },
  { countryName: 'Sri Lanka', countryCodes: ['94'], isoCode2: 'LK', isoCode3: 'LKA' },
  { countryName: 'Sudan', countryCodes: ['249'], isoCode2: 'SD', isoCode3: 'SDN' },
  { countryName: 'Suriname', countryCodes: ['597'], isoCode2: 'SR', isoCode3: 'SUR' },
  { countryName: 'Swaziland', countryCodes: ['268'], isoCode2: 'SZ', isoCode3: 'SWZ' },
  { countryName: 'Sweden', countryCodes: ['46'], isoCode2: 'SE', isoCode3: 'SWE' },
  { countryName: 'Switzerland', countryCodes: ['41'], isoCode2: 'CH', isoCode3: 'CHE' },
  { countryName: 'Syria', countryCodes: ['963'], isoCode2: 'SY', isoCode3: 'SYR' },
  { countryName: 'Taiwan', countryCodes: ['886'], isoCode2: 'TW', isoCode3: 'TWN' },
  { countryName: 'Tajikistan', countryCodes: ['992'], isoCode2: 'TJ', isoCode3: 'TJK' },
  { countryName: 'Tanzania', countryCodes: ['255'], isoCode2: 'TZ', isoCode3: 'TZA' },
  { countryName: 'Thailand', countryCodes: ['66'], isoCode2: 'TH', isoCode3: 'THA' },
  { countryName: 'East Timor', countryCodes: ['670'], isoCode2: 'TL', isoCode3: 'TLS' },
  { countryName: 'Togo', countryCodes: ['228'], isoCode2: 'TG', isoCode3: 'TGO' },
  { countryName: 'Tokelau', countryCodes: ['690'], isoCode2: 'TK', isoCode3: 'TKL' },
  { countryName: 'Tonga', countryCodes: ['676'], isoCode2: 'TO', isoCode3: 'TON' },
  { countryName: 'Trinidad and Tobago', countryCodes: ['1-868'], isoCode2: 'TT', isoCode3: 'TTO' },
  { countryName: 'Tunisia', countryCodes: ['216'], isoCode2: 'TN', isoCode3: 'TUN' },
  { countryName: 'Turkey', countryCodes: ['90'], isoCode2: 'TR', isoCode3: 'TUR' },
  { countryName: 'Turkmenistan', countryCodes: ['993'], isoCode2: 'TM', isoCode3: 'TKM' },
  {
    countryName: 'Turks and Caicos Islands',
    countryCodes: ['1-649'],
    isoCode2: 'TC',
    isoCode3: 'TCA',
  },
  { countryName: 'Tuvalu', countryCodes: ['688'], isoCode2: 'TV', isoCode3: 'TUV' },
  { countryName: 'Uganda', countryCodes: ['256'], isoCode2: 'UG', isoCode3: 'UGA' },
  { countryName: 'Ukraine', countryCodes: ['380'], isoCode2: 'UA', isoCode3: 'UKR' },
  { countryName: 'United Arab Emirates', countryCodes: ['971'], isoCode2: 'AE', isoCode3: 'ARE' },
  { countryName: 'United Kingdom', countryCodes: ['44'], isoCode2: 'GB', isoCode3: 'GBR' },
  { countryName: 'United States', countryCodes: ['1'], isoCode2: 'US', isoCode3: 'USA' },
  { countryName: 'Uruguay', countryCodes: ['598'], isoCode2: 'UY', isoCode3: 'URY' },
  { countryName: 'Uzbekistan', countryCodes: ['998'], isoCode2: 'UZ', isoCode3: 'UZB' },
  { countryName: 'Vanuatu', countryCodes: ['678'], isoCode2: 'VU', isoCode3: 'VUT' },
  { countryName: 'Venezuela', countryCodes: ['58'], isoCode2: 'VE', isoCode3: 'VEN' },
  { countryName: 'Vietnam', countryCodes: ['84'], isoCode2: 'VN', isoCode3: 'VNM' },
  {
    countryName: 'British Virgin Islands',
    countryCodes: ['1-284'],
    isoCode2: 'VG',
    isoCode3: 'VGB',
  },
  { countryName: 'U.S. Virgin Islands', countryCodes: ['1-340'], isoCode2: 'VI', isoCode3: 'VIR' },
  { countryName: 'Wallis and Futuna', countryCodes: ['681'], isoCode2: 'WF', isoCode3: 'WLF' },
  { countryName: 'Western Sahara', countryCodes: ['212'], isoCode2: 'EH', isoCode3: 'ESH' },
  { countryName: 'Yemen', countryCodes: ['967'], isoCode2: 'YE', isoCode3: 'YEM' },
  { countryName: 'Zambia', countryCodes: ['260'], isoCode2: 'ZM', isoCode3: 'ZMB' },
  { countryName: 'Zimbabwe', countryCodes: ['263'], isoCode2: 'ZW', isoCode3: 'ZWE' },
];

export default class CountryUtils {
  static findByName = (countryName?: string) => {
    return CountryUtils.findAll().find(countryEntity => countryEntity.countryName === countryName);
  };

  static findByISOCode2 = (isoCode2?: string) => {
    return CountryUtils.findAll().find(countryEntity => countryEntity.isoCode2 === isoCode2);
  };

  static findAll = () => {
    return [
      ...CountryEntities,
      {
        countryName: 'Europe (Entire Europe Countries)',
        countryCodes: [],
        isoCode2: 'EU',
        isoCode3: 'EUR',
        isVirtual: true,
      },
    ];
  };

  static findOverseasToSell = () => {
    return [
      'CN',
      'JP',
      'HK',
      'SG',
      'TW',
      'PH',
      'VN',
      'TH',
      'ID',
      'MY',
      'IN',
      'US',
      'EU',
      'CA',
      'AU',
    ];
  };
}
