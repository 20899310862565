import classNames from 'classnames/bind';
import React, { useMemo } from 'react';
import { Text, Stage, Layer, Rect } from 'react-konva';
import useImage from 'use-image';

import { ArrayUtils, TemplateItemType } from '@bringk/shared';

import useFirebaseStorage from 'src/hooks/useFirebaseStorage';
import useStore from 'src/hooks/useStore';
import { getBoxInfo } from 'src/utils/BoxImagesUtils';
import { getContainerInfo } from 'src/utils/ContainerImagesUtils';
import { getContentInfo } from 'src/utils/ContentImagesUtils';

import CommonKonvaImage from './commonKonvaImage';
import { getDstImageFromSrcImage, SrcImage } from './imageLogic';
import styles from './index.module.scss';

const cx = classNames.bind(styles);
interface Props {
  onEditClicked?: (templateItemType?: TemplateItemType) => void;
}

const CANVAS_MAX_WIDTH = 400;
const LIMIT_IMAGE_SIZE = { width: 180, height: 400 };

const ContainerInfo = ({ onEditClicked }: Props) => {
  const isDebug = false;

  const { orderStore } = useStore();
  const { category, boxColor, containerColor, boxImage, containerImage } = orderStore.product;
  const { companyName = '', name: productName = '' } = orderStore.product;
  const boxImageUrl = useFirebaseStorage(boxImage).url;
  const containerImageUrl = useFirebaseStorage(containerImage).url;

  const title = useMemo(() => [companyName, productName].filter(name => Boolean(name)).join('\n'), [
    companyName,
    productName,
  ]);

  const betweenImgSpacing = 13;

  const boxInfo = useMemo(() => category && getBoxInfo(category), [category]);
  const containerInfo = useMemo(() => category && getContainerInfo(category), [category]);
  const contentInfo = useMemo(() => category && getContentInfo(category), [category]);
  const [boxDefaultImg] = useImage(boxInfo?.imagePath || '');
  const [boxCustomImg] = useImage(boxImageUrl || '');
  const [containerDefaultImg] = useImage(containerInfo?.imagePath || '');
  const [containerCustomImg] = useImage(containerImageUrl || '');
  const [contentDefaultImg] = useImage(contentInfo?.imagePath || '');

  const defaultTopImgs = [boxDefaultImg, containerDefaultImg];
  const customTopImgs = [boxCustomImg, containerCustomImg];
  const topImgs = [boxCustomImg || boxDefaultImg, containerCustomImg || containerDefaultImg];
  const defaultLoadedTopImgs = ArrayUtils.filterNotUndefined(defaultTopImgs);
  const rawTotalWidth =
    defaultLoadedTopImgs.reduce((width, img) => width + img.width, 0) +
    Math.max(0, defaultLoadedTopImgs.length - 1) * betweenImgSpacing;
  const defaultImageScale = Math.min(CANVAS_MAX_WIDTH / rawTotalWidth, 1.0 / 2.1);
  const customImageScales = [boxCustomImg, containerCustomImg, contentDefaultImg].map(image => {
    if (!image) {
      return 0;
    }
    const hScale = LIMIT_IMAGE_SIZE.width / image.width;
    const vScale = LIMIT_IMAGE_SIZE.height / image.height;
    return Math.min(hScale, vScale);
  });

  const topImgScales = ArrayUtils.numberStream(2).map(offset => {
    if (customTopImgs[offset]) {
      return customImageScales[offset];
    } else {
      return defaultImageScale;
    }
  });

  const topImgHeights = topImgs.map((img, offset) => {
    const height = img?.height || 0;
    return height * topImgScales[offset];
  });

  let canvasSize = {
    width: CANVAS_MAX_WIDTH,
    height: Math.max(...topImgHeights),
  };

  const srcImages: SrcImage[] = [];

  if (boxInfo && topImgs[0]) {
    srcImages.push({
      text: title,
      imageElement: topImgs[0],
      imageScale: topImgScales[0],
      textInImageRect: boxInfo.imageRect,
      tintColor: boxColor,
    });
  }

  if (containerInfo && topImgs[1]) {
    srcImages.push({
      text: title,
      imageElement: topImgs[1],
      imageScale: topImgScales[1],
      textInImageRect: containerInfo.imageRect,
      tintColor: containerColor,
    });
  }

  const dstImages = useMemo(
    () => getDstImageFromSrcImage(srcImages, canvasSize, betweenImgSpacing),
    [srcImages, canvasSize, betweenImgSpacing],
  );

  let contentImgProps = null;
  if (contentDefaultImg) {
    const width = CANVAS_MAX_WIDTH * 0.5;
    const scale = width / contentDefaultImg.width;
    const height = contentDefaultImg.height * scale;
    const x = (CANVAS_MAX_WIDTH - width) / 2;
    const y = canvasSize.height + betweenImgSpacing;
    contentImgProps = { x, y, scale };
    canvasSize = {
      width: canvasSize.width,
      height: canvasSize.height + height + betweenImgSpacing,
    };
  }

  return (
    <Stage {...canvasSize} className={cx('container')}>
      <Layer>
        {dstImages.map((dstImage, offset) => (
          <React.Fragment key={offset}>
            <CommonKonvaImage
              imageElement={dstImage.imageElement}
              tintColor={dstImage.tintColor}
              onClick={() =>
                onEditClicked?.(
                  offset ? TemplateItemType.TARGET_CONTAINER : TemplateItemType.TARGET_BOX,
                )
              }
              {...dstImage.imageRect}
              scale={srcImages[offset].imageScale}
              isDebug={isDebug}
            />
            {isDebug && (
              <Rect
                {...dstImage.textRect}
                {...{ strokeWidth: 1, stroke: 'black', fill: '#0000FF66' }}
              />
            )}
            <Text
              {...dstImage.textRect}
              text={dstImage.text}
              fontSize={9}
              align="center"
              verticalAlign="middle"
              ellipsis={true}
              onClick={() => onEditClicked?.(TemplateItemType.NAME)}
            />
          </React.Fragment>
        ))}
        {contentImgProps && (
          <CommonKonvaImage
            imageElement={contentDefaultImg}
            {...contentImgProps}
            isDebug={isDebug}
          />
        )}
      </Layer>
    </Stage>
  );
};

export default ContainerInfo;
