import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames/bind';
import i18next from 'i18next';
import React, { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown, Flag } from 'semantic-ui-react';

import useClickOutside from 'src/hooks/useClickOutside';
import useFormat from 'src/hooks/useFormat';

import styles from './index.module.scss';
const cx = classNames.bind(styles);

interface Props {
  text?: string;
  pointing?:
    | boolean
    | 'left'
    | 'right'
    | 'top'
    | 'top left'
    | 'top right'
    | 'bottom'
    | 'bottom left'
    | 'bottom right';
}

const SettingDropdown = (props: Props) => {
  const history = useHistory();
  const { formatPrefix, formatKeyword } = useFormat();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleToggle = useCallback(() => {
    setIsOpen(isOpen => !isOpen);
  }, [setIsOpen]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  useClickOutside(containerRef, handleClose);

  const setLanguage = useCallback((lang: string) => {
    sessionStorage.setItem('language', lang);
    i18next.changeLanguage(lang);
  }, []);

  const handleEnglishClick = useCallback(() => {
    setLanguage('en');
  }, [setLanguage]);

  const handleKoreanClick = useCallback(() => {
    setLanguage('ko');
  }, [setLanguage]);

  const handleOrderHistoryClick = useCallback(
    e => {
      history.push('/history');
      handleClose();
      e.stopPropagation();
    },
    [history, handleClose],
  );

  return (
    <div className={cx('container')} ref={containerRef}>
      {props.text ? (
        <div className={cx('text')} onClick={handleToggle}>
          {props.text}
        </div>
      ) : (
        <FontAwesomeIcon
          className={cx('icon')}
          icon={faCog}
          size="1x"
          onClick={() => setIsOpen(value => !value)}
        />
      )}
      <Dropdown pointing={props.pointing} icon>
        <Dropdown.Menu open={isOpen} className={cx('menu')}>
          <div className={cx('menu-header')}>{formatPrefix('Language.', 'Language')}</div>
          <div className={cx('menu-item')} onClick={handleEnglishClick}>
            <Flag name="us" />
            {formatPrefix('Language.', 'English')}
          </div>
          <div className={cx('menu-item')} onClick={handleKoreanClick}>
            <Flag name="kr" />
            {formatPrefix('Language.', 'Korean')}
          </div>
          <Dropdown.Divider />
          <div className={cx('menu-header')}>{formatKeyword('More')}</div>
          <div className={cx('menu-item')} onClick={handleOrderHistoryClick}>
            {formatKeyword('OrderHistory')}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default SettingDropdown;
