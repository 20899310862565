import React, { useContext } from 'react';

import { TemplateItem, TemplateItemType } from '@bringk/shared';

import ReceiptRecordKeyValue from 'src/components/ReceiptRecordKeyValue/index';
import useFormat from 'src/hooks/useFormat';
import KeyValueInfo from 'src/pages/order/summary/KeyValueInfo';
import TargetImage from 'src/pages/order/templates/TargetImage';
import AbstractTemplateItemSupporter from 'src/utils/template/AbstractTemplateItemSupporter';
import TemplateItemUtils from 'src/utils/TemplateItemUtils';

import { OrderSourceContext } from './OrderSource';

interface Props {
  templateItem: TemplateItem;
}

const ColorSummaryComponent = () => {
  const { formatKeyword } = useFormat();
  const {
    product: { contentImage, contentColor, contentRefBrand, contentRefProduct },
  } = useContext(OrderSourceContext);
  const contents = [
    { key: formatKeyword('Image'), value: contentImage },
    { key: formatKeyword('Color'), value: contentColor },
    { key: formatKeyword('ReferenceBrandName'), value: contentRefBrand },
    { key: formatKeyword('ReferenceProductName'), value: contentRefProduct },
  ].filter(content => Boolean(content.value));
  return <KeyValueInfo contents={contents} />;
};

const ColorReceiptComponent = ({ templateItem }: Props) => {
  const { format, formatKeyword } = useFormat();
  const {
    product: { contentImage, contentColor, contentRefBrand, contentRefProduct },
  } = useContext(OrderSourceContext);
  const contents = [
    { key: formatKeyword('Image'), value: contentImage },
    { key: formatKeyword('Color'), value: contentColor },
    { key: formatKeyword('ReferenceBrandName'), value: contentRefBrand },
    { key: formatKeyword('ReferenceProductName'), value: contentRefProduct },
  ].filter(content => Boolean(content.value));
  const shortTitleId = TemplateItemUtils.getShortTitle(templateItem.type);
  return <ReceiptRecordKeyValue label={format(shortTitleId)} contents={contents} />;
};
export default class ColorSupporter extends AbstractTemplateItemSupporter {
  support(templateItemType: TemplateItemType): boolean {
    return templateItemType === TemplateItemType.COLOR;
  }
  render(templateItem: TemplateItem): React.ReactNode {
    return (
      <TargetImage
        templateItem={templateItem}
        imageProperty={'contentImage'}
        colorProperty={'contentColor'}
        refBrandProperty={'contentRefBrand'}
        refProductProperty={'contentRefProduct'}
      />
    );
  }
  renderSummary(): React.ReactNode | null {
    return <ColorSummaryComponent />;
  }
  renderReceipt(templateItem: TemplateItem): React.ReactNode | null {
    return <ColorReceiptComponent templateItem={templateItem} />;
  }
  getTitleId(): string {
    return 'Order.Template.Color.Title';
  }
  getShortTitleId(): string {
    return 'Order.Template.Color.ShortTitle';
  }
}
