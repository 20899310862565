import React, { useContext } from 'react';

import { TemplateItem, TemplateItemType } from '@bringk/shared';

import ReceiptRecordKeyValue from 'src/components/ReceiptRecordKeyValue/index';
import useFormat from 'src/hooks/useFormat';
import KeyValueInfo from 'src/pages/order/summary/KeyValueInfo';
import TargetImage from 'src/pages/order/templates/TargetImage';
import { getBoxInfo } from 'src/utils/BoxImagesUtils';
import AbstractTemplateItemSupporter from 'src/utils/template/AbstractTemplateItemSupporter';
import TemplateItemUtils from 'src/utils/TemplateItemUtils';

import { OrderSourceContext } from './OrderSource';

interface Props {
  templateItem: TemplateItem;
}

const TargetBoxSummaryComponent = () => {
  const { formatKeyword } = useFormat();
  const { product } = useContext(OrderSourceContext);
  const contents = [
    { key: formatKeyword('Image'), value: product.boxImage },
    { key: formatKeyword('Color'), value: product.boxColor },
    { key: formatKeyword('ReferenceBrandName'), value: product.boxRefBrand },
    { key: formatKeyword('ReferenceProductName'), value: product.boxRefProduct },
  ].filter(content => Boolean(content.value));
  return <KeyValueInfo contents={contents} />;
};

const TargetBoxReceiptComponent = ({ templateItem }: Props) => {
  const { format, formatKeyword } = useFormat();
  const { product } = useContext(OrderSourceContext);
  const contents = [
    { key: formatKeyword('Image'), value: product.boxImage },
    { key: formatKeyword('Color'), value: product.boxColor },
    { key: formatKeyword('ReferenceBrandName'), value: product.boxRefBrand },
    { key: formatKeyword('ReferenceProductName'), value: product.boxRefProduct },
  ].filter(content => Boolean(content.value));
  const shortTitleId = TemplateItemUtils.getShortTitle(templateItem.type);
  return <ReceiptRecordKeyValue label={format(shortTitleId)} contents={contents} />;
};

export default class TargetBoxSupporter extends AbstractTemplateItemSupporter {
  support(templateItemType: TemplateItemType): boolean {
    return templateItemType === TemplateItemType.TARGET_BOX;
  }
  render(templateItem: TemplateItem): React.ReactNode {
    return (
      <TargetImage
        templateItem={templateItem}
        imageProperty={'boxImage'}
        colorProperty={'boxColor'}
        refBrandProperty={'boxRefBrand'}
        refProductProperty={'boxRefProduct'}
        getSuggestionImagePath={getBoxInfo}
      />
    );
  }
  renderSummary(): React.ReactNode | null {
    return <TargetBoxSummaryComponent />;
  }
  renderReceipt(templateItem: TemplateItem): React.ReactNode | null {
    return <TargetBoxReceiptComponent templateItem={templateItem} />;
  }
}
