"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const IngredientDatabase_1 = require("./IngredientDatabase");
const SampleDatabase_1 = require("./SampleDatabase");
const TemplateDatabase_1 = require("./TemplateDatabase");
class Database {
}
exports.Database = Database;
Database.templates = TemplateDatabase_1.TemplateDatabase.getInstance();
Database.ingredients = IngredientDatabase_1.IngredientDatabase.getInstance();
Database.sample = SampleDatabase_1.SampleDatabase.getInstance();
