import React, { useCallback, useMemo } from 'react';

import { TemplateItem } from '@bringk/shared';

import TextArea from 'src/components/TextArea';
import useFormat from 'src/hooks/useFormat';
import useStore from 'src/hooks/useStore';

interface Props {
  templateItem: TemplateItem;
}

const OtherRequest = ({ templateItem }: Props) => {
  const { format } = useFormat();
  const { orderStore } = useStore();
  const { product } = orderStore;
  const targetProperty = useMemo(() => templateItem.property, [templateItem]);

  const value: any = useMemo(() => product[targetProperty], [product, targetProperty]);

  const handleTextAreaChanged = useCallback(
    event => {
      orderStore.setProductProperty(targetProperty, event.currentTarget.value);
    },
    [targetProperty, orderStore],
  );

  return (
    <>
      <TextArea
        value={value}
        onChange={handleTextAreaChanged}
        placeholder={format('Tooltips.OtherRequest')}
      />
    </>
  );
};

export default OtherRequest;
