import classNames from 'classnames/bind';
import { observer } from 'mobx-react';
import React, { useCallback } from 'react';

import { TemplateItemType, Database } from '@bringk/shared';

import EwgIcon from 'src/components/IngredientPicker/ewgIcon';
import useFormat from 'src/hooks/useFormat';
import useStore from 'src/hooks/useStore';
import { CategoryUtils } from 'src/utils/CategoryUtils';
import TemplateItemUtils from 'src/utils/TemplateItemUtils';

import Colors from './colors';
import styles from './index.module.scss';
const cx = classNames.bind(styles);

interface Props {
  onEditClicked?: (templateItemType?: TemplateItemType) => void;
}

const TopInfo = ({ onEditClicked }: Props) => {
  const { orderStore } = useStore();
  const { format, formatPrefix, formatKeyword } = useFormat();
  const {
    category,
    companyName,
    name,
    capacity,
    ewg,
    functionality,
    features,
    mainConceptIngredients,
    subConceptIngredients,
    nonAdditives,
  } = orderStore.product;

  const topCategory = category && CategoryUtils.getTopParent(category);

  const renderIngredientGroupIds = useCallback(
    (ingredientGroupIds: string[]) =>
      topCategory &&
      ingredientGroupIds.map(ingredientGroupId => {
        return (
          <div key={ingredientGroupId}>
            {formatPrefix('IngredientGroupFilters.', ingredientGroupId)}
          </div>
        );
      }),
    [formatPrefix, topCategory],
  );

  const renderIngredientIds = useCallback(
    (ingredientIds: string[]) =>
      topCategory &&
      ingredientIds.map(ingredientId => {
        const name = formatPrefix('Ingredients.', ingredientId);
        const groupIds = Database.ingredients
          .getGroups(topCategory)
          .filter(group => group.ingredientIds?.includes(ingredientId) === true)
          .map(group => group.id);
        const functionalities = groupIds.map((groupId, index) => {
          const isMatchedFeature = features?.includes(groupId);
          return (
            <span key={groupId} className={cx({ matched: isMatchedFeature })}>
              {formatPrefix('IngredientGroupFilters.', groupId)}
              {groupIds.length - 1 !== index && ', '}
            </span>
          );
        });
        return (
          <span key={ingredientId}>
            {name}{' '}
            {functionalities.length && <span className={cx('hint')}>({functionalities}) </span>}
          </span>
        );
      }),
    [topCategory, formatPrefix, features],
  );

  return (
    <div className={cx('container')}>
      <div
        className={cx('section', 'selectable')}
        onClick={() => onEditClicked?.(TemplateItemType.COMPANY_NAME)}
      >
        <div className={cx('title')}>
          {format(TemplateItemUtils.getShortTitle(TemplateItemType.COMPANY_NAME))}
        </div>
        <div className={cx('bigValue')}>{companyName}</div>
      </div>
      <div className={cx('section')}>
        <div className={cx('title')}>
          <span className={cx('selectable')} onClick={() => onEditClicked?.(TemplateItemType.NAME)}>
            {format(TemplateItemUtils.getShortTitle(TemplateItemType.NAME))}
          </span>
          <span
            className={cx('selectable')}
            onClick={() => onEditClicked?.(TemplateItemType.CAPACITY)}
          >
            {' ('}
            {format(TemplateItemUtils.getShortTitle(TemplateItemType.CAPACITY))}
            {')'}
          </span>
        </div>
        <div className={cx('bigValue', 'selectable')}>
          <span className={cx('selectable')} onClick={() => onEditClicked?.(TemplateItemType.NAME)}>
            {name}
          </span>
          {Boolean(capacity) && (
            <span
              className={cx('selectable')}
              onClick={() => onEditClicked?.(TemplateItemType.CAPACITY)}
            >
              {' ('}
              {formatKeyword(capacity)}
              {')'}
            </span>
          )}
        </div>
      </div>
      <div className={cx('section')}>
        <div className={cx('title', 'ewg_and_color')}>
          <span className={cx('selectable')} onClick={() => onEditClicked?.(TemplateItemType.EWG)}>
            {format(TemplateItemUtils.getShortTitle(TemplateItemType.EWG))}
          </span>
          {` ${'and'} `}
          <span>{format(TemplateItemUtils.getShortTitle(TemplateItemType.COLOR))}</span>
        </div>
        <div className={cx('value')}>
          <span className={cx('selectable')} onClick={() => onEditClicked?.(TemplateItemType.EWG)}>
            <EwgIcon ewg={ewg === 'EwgGradeGreen' ? 'GREEN' : 'NONE'} />
          </span>
          <span className={cx('color')}>
            <Colors {...{ onEditClicked }} />
          </span>
        </div>
      </div>
      <div
        className={cx('section', 'selectable')}
        onClick={() => onEditClicked?.(TemplateItemType.FEATURES)}
      >
        <div className={cx('title')}>{formatKeyword('Features')}</div>
        <div className={cx('value')}>{features && renderIngredientGroupIds(features)}</div>
      </div>
      <div
        className={cx('section', 'selectable')}
        onClick={() => onEditClicked?.(TemplateItemType.INGREDIENTS)}
      >
        <div className={cx('title')}>{formatKeyword('MainConceptIngredients')}</div>
        <div className={cx('value')}>
          {mainConceptIngredients && renderIngredientIds(mainConceptIngredients)}
        </div>
      </div>
      <div
        className={cx('section', 'selectable')}
        onClick={() => onEditClicked?.(TemplateItemType.INGREDIENTS)}
      >
        <div className={cx('title')}>{formatKeyword('SubConceptIngredients')}</div>
        <div className={cx('value')}>
          {subConceptIngredients && renderIngredientIds(subConceptIngredients)}
        </div>
      </div>
      <div
        className={cx('section', 'selectable')}
        onClick={() => onEditClicked?.(TemplateItemType.NON_ADDITIVES)}
      >
        <div className={cx('title')}>
          {format(TemplateItemUtils.getShortTitle(TemplateItemType.NON_ADDITIVES))}
        </div>
        <div className={cx('value')}>
          {nonAdditives?.map(value => formatKeyword(value)).join(', ')}
        </div>
      </div>
      <div
        className={cx('section', 'selectable')}
        onClick={() => onEditClicked?.(TemplateItemType.FUNCTIONALITY)}
      >
        <div className={cx('title')}>
          {format(TemplateItemUtils.getShortTitle(TemplateItemType.FUNCTIONALITY))}
        </div>
        <div className={cx('value')}>
          {functionality?.map(value => formatKeyword(value)).join(', ')}
        </div>
      </div>
    </div>
  );
};

export default observer(TopInfo);
