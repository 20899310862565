class TimeUtils {
  static since(timestamp: number) {
    const currentTimestamp = Date.now();
    const seconds = Math.floor((currentTimestamp - timestamp) / 1000);
    let interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + '년 전';
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + '월 전';
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + '일 전';
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + '시간 전';
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + '분 전';
    }
    return Math.floor(seconds) + '초 전';
  }

  static yyyymmdd(timestamp: number) {
    const date = new Date(timestamp);
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    const year = '' + date.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return `${year}-${month}-${day}`;
  }
}
export default TimeUtils;
