import classNames from 'classnames/bind';
import React, { useMemo } from 'react';

import { TemplateItemType, Product, Producer } from '@bringk/shared';

import ImageFailover from 'src/components/ImageFailover';
import ReceiptRecord from 'src/components/ReceiptRecord';
import ReceiptTable from 'src/components/ReceiptTable';
import useFirebaseStorage from 'src/hooks/useFirebaseStorage';
import useFormat from 'src/hooks/useFormat';
import useOrderTemplate from 'src/hooks/useOrderTemplate';
import { getBoxInfo } from 'src/utils/BoxImagesUtils';
import { getContainerInfo } from 'src/utils/ContainerImagesUtils';
import TemplateItemUtils from 'src/utils/TemplateItemUtils';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface Props {
  producer: Producer;
  product: Product;
  requiredTemplateItemTypes: TemplateItemType[];
  customTemplateItemTypes: TemplateItemType[];
  unrequiredTemplateItemTypes: TemplateItemType[];
}

const Receipt = ({
  product,
  requiredTemplateItemTypes,
  customTemplateItemTypes,
  unrequiredTemplateItemTypes,
}: Props) => {
  const { format, formatPrefix, formatKeyword } = useFormat();
  const boxImageUrl = useFirebaseStorage(product.boxImage).url;
  const containerImageUrl = useFirebaseStorage(product.containerImage).url;

  const suggestBoxImage = useMemo(() => {
    if (!product.category) return;
    return getBoxInfo(product.category)?.imagePath;
  }, [product.category]);

  const suggestContainerImage = useMemo(() => {
    if (!product.category) return;
    return getContainerInfo(product.category)?.imagePath;
  }, [product.category]);

  const { getTemplateItemByType } = useOrderTemplate(product.category);

  return (
    <div className={cx('receipt')}>
      <div className={cx('receipt-table-title')}>{formatKeyword('ProducerInformation')}</div>
      <ReceiptTable>
        {TemplateItemUtils.renderReceipt(
          getTemplateItemByType(TemplateItemType.PRODUCER_INFORMATION),
        )}
      </ReceiptTable>
      <div className={cx('empty')} />
      <div className={cx('receipt-table-title')}>{formatKeyword('RequiredInformation')}</div>
      <ReceiptTable>
        <ReceiptRecord
          label={formatKeyword('Category')}
          value={formatPrefix('Category.', product.category)}
        />
        {requiredTemplateItemTypes
          .filter(templateItemType => templateItemType !== TemplateItemType.PRODUCER_INFORMATION)
          .map(templateItemType =>
            TemplateItemUtils.renderReceipt(getTemplateItemByType(templateItemType)),
          )}
      </ReceiptTable>
      <div className={cx('empty')} />
      <div className={cx('receipt-table-title')}>{formatKeyword('DetailInformation')}</div>
      <ReceiptTable>
        <div className={cx('image-records')}>
          <div className={cx('row', 'headers')}>
            <div className={cx('section')}>
              {format(TemplateItemUtils.getShortTitle(TemplateItemType.TARGET_BOX))}
            </div>
            <div className={cx('section')}>
              {format(TemplateItemUtils.getShortTitle(TemplateItemType.TARGET_CONTAINER))}
            </div>
          </div>
          <div className={cx('row')}>
            <div className={cx('section', 'box-section')}>
              <ImageFailover src={boxImageUrl || undefined} failover={suggestBoxImage} />
            </div>
            <div className={cx('section', 'container-section')}>
              <ImageFailover
                src={containerImageUrl || undefined}
                failover={suggestContainerImage}
              />
            </div>
          </div>
        </div>
        {customTemplateItemTypes.map(templateItemType =>
          TemplateItemUtils.renderReceipt(getTemplateItemByType(templateItemType)),
        )}
      </ReceiptTable>
      <div className={cx('empty')} />
      <ReceiptTable>
        {unrequiredTemplateItemTypes
          .filter(
            templateItemType =>
              !customTemplateItemTypes.some(
                customTemplateItemType => customTemplateItemType === templateItemType,
              ),
          )
          .map(templateItemType =>
            TemplateItemUtils.renderReceipt(getTemplateItemByType(templateItemType)),
          )}
      </ReceiptTable>
    </div>
  );
};

Receipt.defaultProps = {
  requiredTemplateItemTypes: [
    TemplateItemType.PRODUCER_INFORMATION,
    TemplateItemType.TARGET_COUNTRIES_TO_SELL,
    TemplateItemType.TARGET_DISTRIBUTION_CHANNEL,
    TemplateItemType.TARGET_CUSTOMER_GENDER,
    TemplateItemType.TARGET_CUSTOMER_AGE,
    TemplateItemType.TARGET_LAUNCH_DATE,
    TemplateItemType.ORDER_QUANTITY,
  ],
  customTemplateItemTypes: [
    TemplateItemType.TARGET_BOX,
    TemplateItemType.TARGET_CONTAINER,
    TemplateItemType.COMPANY_NAME,
    TemplateItemType.NAME,
    TemplateItemType.FEATURES,
    TemplateItemType.INGREDIENTS,
  ],
  unrequiredTemplateItemTypes: [
    TemplateItemType.TARGET_CONTAINER,
    TemplateItemType.CONTAINER_PUMPING,
    TemplateItemType.FABRIC_COLOR,
    TemplateItemType.TARGET_BOX,
    TemplateItemType.TARGET_BOX_SET,
    TemplateItemType.SHEET_FABRIC,
    TemplateItemType.SHEET_SHAPE,
    TemplateItemType.COMPANY_NAME,
    TemplateItemType.NAME,
    TemplateItemType.FEATURES,
    TemplateItemType.INGREDIENTS,
    TemplateItemType.CAPACITY,
    TemplateItemType.CAPACITY_PER_SHEET,
    TemplateItemType.FUNCTIONALITY,
    TemplateItemType.EWG,
    TemplateItemType.NON_ADDITIVES,
    TemplateItemType.FORMULATION,
    TemplateItemType.MOISTURE_LEVEL,
    TemplateItemType.FINISH_LEVEL,
    TemplateItemType.NOSE_STRIP,
    TemplateItemType.CLEANSING_POWER,
    TemplateItemType.BUBBLE,
    TemplateItemType.PH_BALANCED,
    TemplateItemType.SCENT,
    TemplateItemType.COLOR,
    TemplateItemType.SUNSCREEN_TYPE,
    TemplateItemType.OTHER_REQUESTS,
  ],
};

export default Receipt;
