import React from 'react';

import { IngredientEwg } from '@bringk/shared';

import { ReactComponent as EwgGreenSvg } from 'src/images/svg/icon_ingredient_ewg_green.svg';
import { ReactComponent as EwgNoneSvg } from 'src/images/svg/icon_ingredient_ewg_none.svg';
import { ReactComponent as EwgYellowSvg } from 'src/images/svg/icon_ingredient_ewg_yellow.svg';

interface Props {
  ewg: IngredientEwg;
}

const EwgIcon = ({ ewg }: Props) => {
  return (
    <>
      {ewg === 'GREEN' && <EwgGreenSvg />}
      {ewg === 'YELLOW' && <EwgYellowSvg />}
      {ewg === 'NONE' && <EwgNoneSvg />}
    </>
  );
};

export default EwgIcon;
