"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Constants_1 = require("../../Constants");
const ValidationError_1 = require("./ValidationError");
class ValidationChainer {
    constructor(property) {
        this._property = property;
        this._propertyType = typeof property;
    }
    static property(property) {
        return new ValidationChainer(property);
    }
    customError(customError) {
        this._customError = customError;
        return this;
    }
    propertyName(_propertyName) {
        this._propertyName = _propertyName;
        return this;
    }
    throwError(error) {
        const payload = {
            property: this._property,
            propertyType: this._propertyType,
            propertyName: this._propertyName,
        };
        if (this._customError)
            throw new ValidationError_1.ValidationError(this._customError, payload);
        throw new ValidationError_1.ValidationError(error, payload);
    }
    required() {
        if (!Boolean(this._property))
            this.throwError(ValidationChainer.REQUIRED);
        if (this._propertyType === 'string' && !Boolean(this._property))
            this.throwError(ValidationChainer.REQUIRED);
        if (Array.isArray(this._property) && this._property.length < 0)
            this.throwError(ValidationChainer.REQUIRED);
        return this;
    }
    number() {
        if (this._property === undefined || this._property === null)
            this.throwError(ValidationChainer.EMPTY_NUMBER);
        if (this._propertyType !== 'number')
            this.throwError(ValidationChainer.TYPE_NOT_MATCH_SPEC);
        return this;
    }
    string() {
        if (!Boolean(this._property))
            this.throwError(ValidationChainer.EMPTY_STRING);
        if (this._propertyType !== 'string')
            this.throwError(ValidationChainer.TYPE_NOT_MATCH_SPEC);
        if (!Boolean(this._property))
            this.throwError(ValidationChainer.EMPTY_STRING);
        return this;
    }
    stringArray(maxLength) {
        if (this._property === undefined || this._property === null)
            this.throwError(ValidationChainer.TYPE_NOT_MATCH_SPEC);
        if (!Array.isArray(this._property))
            this.throwError(ValidationChainer.TYPE_NOT_MATCH_SPEC);
        if (maxLength !== undefined && this._property.length > maxLength)
            this.throwError(ValidationChainer.ARRAY_LIMIT_EXCEED);
        for (const element of this._property) {
            const elementType = typeof element;
            if (!Boolean(element))
                this.throwError(ValidationChainer.EMPTY_STRING);
            if (elementType !== 'string')
                this.throwError(ValidationChainer.TYPE_NOT_MATCH_SPEC);
            if (!Boolean(element))
                this.throwError(ValidationChainer.EMPTY_STRING);
        }
        return this;
    }
    numberOptional() {
        if (this._property === undefined || this._property === null)
            return this;
        if (this._propertyType !== 'number')
            this.throwError(ValidationChainer.TYPE_NOT_MATCH_SPEC);
        return this;
    }
    stringOptional() {
        if (this._property === undefined || this._property === null)
            return this;
        if (this._propertyType !== 'string')
            this.throwError(ValidationChainer.TYPE_NOT_MATCH_SPEC);
        return this;
    }
    stringArrayOptional(maxLength) {
        if (this._property === undefined || this._property === null)
            return this;
        if (!Array.isArray(this._property))
            this.throwError(ValidationChainer.TYPE_NOT_MATCH_SPEC);
        if (maxLength !== undefined && this._property.length > maxLength)
            this.throwError(ValidationChainer.ARRAY_LIMIT_EXCEED);
        for (const element of this._property) {
            const elementType = typeof element;
            if (!Boolean(element))
                this.throwError(ValidationChainer.EMPTY_STRING);
            if (elementType !== 'string')
                this.throwError(ValidationChainer.TYPE_NOT_MATCH_SPEC);
            if (!Boolean(element))
                this.throwError(ValidationChainer.EMPTY_STRING);
        }
        return this;
    }
    stringLimit(isText) {
        const maxLength = isText ? Constants_1.CONSTANTS.MAX_TEXT_LENGTH : Constants_1.CONSTANTS.MAX_STRING_LENGTH;
        if (this._propertyType === 'string') {
            if (this._property.length > maxLength)
                this.throwError(ValidationChainer.STRING_LIMIT_EXCEED);
        }
        else if (Array.isArray(this._property)) {
            this._property.map(element => {
                if (element.length > maxLength)
                    this.throwError(ValidationChainer.STRING_LIMIT_EXCEED);
            });
        }
        return this;
    }
}
exports.ValidationChainer = ValidationChainer;
ValidationChainer.EMPTY_STRING = 'EmptyString';
ValidationChainer.EMPTY_NUMBER = 'EmptyNumber';
ValidationChainer.EMPTY_ARRAY = 'EmptyArray';
ValidationChainer.REQUIRED = 'RequiredProperty';
ValidationChainer.TYPE_NOT_MATCH_SPEC = 'TypeNotMatchSpec';
ValidationChainer.STRING_LIMIT_EXCEED = 'StringLimitExceed';
ValidationChainer.ARRAY_LIMIT_EXCEED = 'ArrayLimitExceed';
