"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Category;
(function (Category) {
    Category["SKIN_CARE"] = "SKIN_CARE";
    Category["SKIN_CARE_TONER"] = "SKIN_CARE/TONER";
    Category["SKIN_CARE_TONER_WATER"] = "SKIN_CARE/TONER/WATER";
    Category["SKIN_CARE_TONER_PAD"] = "SKIN_CARE/TONER/PAD";
    Category["SKIN_CARE_ESSENCE"] = "SKIN_CARE/ESSENCE";
    Category["SKIN_CARE_OIL"] = "SKIN_CARE/OIL";
    Category["SKIN_CARE_LOTION"] = "SKIN_CARE/LOTION";
    Category["SKIN_CARE_CREAM"] = "SKIN_CARE/CREAM";
    Category["SKIN_CARE_CREAM_FACE"] = "SKIN_CARE/CREAM/FACE";
    Category["SKIN_CARE_CREAM_EYE"] = "SKIN_CARE/CREAM/EYE";
    Category["SKIN_CARE_CREAM_NECK"] = "SKIN_CARE/CREAM/NECK";
    Category["SKIN_CARE_MASK"] = "SKIN_CARE/MASK";
    Category["SKIN_CARE_MASK_SHEET"] = "SKIN_CARE/MASK/SHEET";
    Category["SKIN_CARE_MASK_WASH_OFF"] = "SKIN_CARE/MASK/WASH_OFF";
    Category["SKIN_CARE_MASK_SLEEPING"] = "SKIN_CARE/MASK/SLEEPING";
    Category["SKIN_CARE_MASK_PEEL_OFF"] = "SKIN_CARE/MASK/PEEL_OFF";
    Category["SKIN_CARE_MASK_NOSE"] = "SKIN_CARE/MASK/NOSE";
    Category["SKIN_CARE_MIST"] = "SKIN_CARE/MIST";
    Category["SKIN_CARE_MIST_MOISTURIZER"] = "SKIN_CARE/MIST/MOISTURIZER";
    Category["SKIN_CARE_CLEANSING"] = "SKIN_CARE/CLEANSING";
    Category["SKIN_CARE_CLEANSING_LIP_AND_EYE"] = "SKIN_CARE/CLEANSING/LIP_AND_EYE";
    Category["SKIN_CARE_CLEANSING_EYE"] = "SKIN_CARE/CLEANSING/EYE";
    // SKIN_CARE_CLEANSING_MASCARA = 'SKIN_CARE/CLEANSING/MASCARA',
    Category["SKIN_CARE_CLEANSING_OIL"] = "SKIN_CARE/CLEANSING/OIL";
    Category["SKIN_CARE_CLEANSING_MILK"] = "SKIN_CARE/CLEANSING/MILK";
    Category["SKIN_CARE_CLEANSING_WATER"] = "SKIN_CARE/CLEANSING/WATER";
    Category["SKIN_CARE_CLEANSING_FOAM"] = "SKIN_CARE/CLEANSING/FOAM";
    Category["SKIN_CARE_PEELING"] = "SKIN_CARE/PEELING";
    Category["SKIN_CARE_LIP"] = "SKIN_CARE/LIP";
    Category["SKIN_CARE_LIP_SCRUB"] = "SKIN_CARE/LIP/SCRUB";
    Category["SKIN_CARE_LIP_MOISTURIZER"] = "SKIN_CARE/LIP/MOISTURIZER";
    Category["SKIN_CARE_LIP_MASK"] = "SKIN_CARE/LIP/MASK";
    Category["SKIN_CARE_SUN"] = "SKIN_CARE/SUN";
    Category["SKIN_CARE_SUN_BLOCK"] = "SKIN_CARE/SUN/BLOCK";
    Category["SKIN_CARE_SUN_CUSHION"] = "SKIN_CARE/SUN/CUSHION";
    Category["SKIN_CARE_SUN_STICK"] = "SKIN_CARE/SUN/STICK";
    Category["SKIN_CARE_SUN_SPRAY"] = "SKIN_CARE/SUN/SPRAY";
    Category["MAKE_UP"] = "MAKE_UP";
    Category["ETC"] = "ETC";
})(Category = exports.Category || (exports.Category = {}));
