import classNames from 'classnames/bind';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo } from 'react';

import { TemplateItem } from '@bringk/shared';

import CalendarComponent from 'src/components/Calendar';
import useStore from 'src/hooks/useStore';

import styles from './index.module.scss';
import TimeZonePicker from './timeZonePicker';

const cx = classNames.bind(styles);

interface Props {
  templateItem: TemplateItem;
}

const TargetLaunchDate = (props: Props) => {
  const { orderStore } = useStore();
  const targetProperty = props.templateItem.property;

  const handleTimeZone = useCallback(
    (key: string) => orderStore.setProductDescriptionProperty('targetLaunchDate', key),
    [orderStore],
  );

  const defaultTimeZoneKey = useMemo(() => Intl.DateTimeFormat().resolvedOptions().timeZone, []);

  const timeZoneKey = orderStore.product.description['targetLaunchDate'];

  useEffect(() => {
    if (!timeZoneKey && timeZoneKey !== defaultTimeZoneKey) {
      handleTimeZone(defaultTimeZoneKey);
    }
  }, [timeZoneKey, defaultTimeZoneKey, handleTimeZone]);

  return (
    <div className={cx('container')}>
      <CalendarComponent
        value={new Date(orderStore.product[targetProperty])}
        minDate={new Date(Date.now())}
        onClickDay={date => {
          orderStore.setProductProperty(targetProperty, date.getTime());
        }}
      />
      <TimeZonePicker timeZoneKey={timeZoneKey} onTimeZoneChanged={handleTimeZone} />
    </div>
  );
};

export default observer(TargetLaunchDate);
