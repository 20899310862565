import classNames from 'classnames/bind';
import React, { useState, useCallback } from 'react';

import { ReactComponent as HideSvg } from 'src/images/svg/icon_folding_hide.svg';
import { ReactComponent as ShowSvg } from 'src/images/svg/icon_folding_show.svg';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface Props extends React.HTMLProps<HTMLDivElement> {
  title?: string;
  visible?: boolean;
  expanded?: boolean;
  handleEdit?: () => any;
}

const TitledPane = (props: Props) => {
  const [folded, setFolded] = useState<boolean>(!(props.expanded === true));

  const handleFold = useCallback(() => {
    setFolded(value => !value);
  }, []);

  return (
    <>
      {props.visible && (
        <div className={cx('container')}>
          <div className={cx('title')}>
            <div className={cx('label')} onClick={handleFold}>
              {props.title}
            </div>
            <div className={cx('icon')}>{folded ? <ShowSvg /> : <HideSvg />}</div>
          </div>
          <div className={cx('content', { folded })}>{props.children}</div>
        </div>
      )}
    </>
  );
};

export default TitledPane;
