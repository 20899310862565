"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const TemplateItemType_1 = require("../TemplateItemType");
const AbstractTemplateItemWorker_1 = require("./AbstractTemplateItemWorker");
const ValidationChainer_1 = require("./ValidationChainer");
class TargetContainerWorker extends AbstractTemplateItemWorker_1.AbstractTemplateItemWorker {
    support(templateItem) {
        return templateItem.type === TemplateItemType_1.TemplateItemType.TARGET_CONTAINER;
    }
    initialize(templateItem, producer, product) {
        product.containerColor = null;
        product.containerImage = null;
        product.containerRefBrand = null;
        product.containerRefProduct = null;
    }
    validate(templateItem, producer, product) {
        ValidationChainer_1.ValidationChainer.property(product.containerColor)
            .propertyName('container color')
            .stringOptional()
            .stringLimit();
        ValidationChainer_1.ValidationChainer.property(product.containerImage)
            .propertyName('container image')
            .stringOptional()
            .stringLimit();
        ValidationChainer_1.ValidationChainer.property(product.containerRefBrand)
            .propertyName('container reference brand')
            .stringOptional()
            .stringLimit();
        ValidationChainer_1.ValidationChainer.property(product.containerRefProduct)
            .propertyName('container reference product')
            .stringOptional()
            .stringLimit();
    }
}
exports.TargetContainerWorker = TargetContainerWorker;
