import React from 'react';

import { IngredientOrigin } from '@bringk/shared';

import { ReactComponent as OriginAnimalSvg } from 'src/images/svg/icon_ingredient_origin_animal.svg';
import { ReactComponent as OriginFermentationSvg } from 'src/images/svg/icon_ingredient_origin_fermentation.svg';
import { ReactComponent as OriginGrainSvg } from 'src/images/svg/icon_ingredient_origin_grain.svg';
import { ReactComponent as OriginOtherSvg } from 'src/images/svg/icon_ingredient_origin_other.svg';
import { ReactComponent as OriginPlantSvg } from 'src/images/svg/icon_ingredient_origin_plant.svg';
import { ReactComponent as OriginSeaSvg } from 'src/images/svg/icon_ingredient_origin_sea.svg';

interface Props {
  origin: IngredientOrigin;
}

const OriginIcon = ({ origin }: Props) => {
  return (
    <>
      {origin === 'ANIMAL' && <OriginAnimalSvg />}
      {origin === 'FERMENTATION' && <OriginFermentationSvg />}
      {origin === 'GRAIN' && <OriginGrainSvg />}
      {origin === 'NONE' && <OriginOtherSvg />}
      {origin === 'VEGETABLE' && <OriginPlantSvg />}
      {origin === 'MARINE' && <OriginSeaSvg />}
    </>
  );
};

export default OriginIcon;
