import { observer } from 'mobx-react';
import React, { useCallback, useMemo } from 'react';

import { TemplateItem } from '@bringk/shared';

import TextArea from 'src/components/TextArea';
import useStore from 'src/hooks/useStore';

interface Props {
  templateItem: TemplateItem;
  placeholder?: string;
}

const TextAreaWrapper = ({ templateItem, placeholder }: Props) => {
  const { orderStore } = useStore();
  const targetProperty = useMemo(() => templateItem.property, [templateItem]);

  const value: any = useMemo(() => orderStore.product.description[targetProperty], [
    orderStore.product.description,
    targetProperty,
  ]);

  const handleTextAreaChanged = useCallback(
    event => {
      orderStore.setProductDescriptionProperty(targetProperty, event.currentTarget.value);
    },
    [targetProperty, orderStore],
  );

  return (
    <TextArea
      key={templateItem.type || 'default'}
      value={value}
      onChange={handleTextAreaChanged}
      placeholder={placeholder}
    />
  );
};

export default observer(TextAreaWrapper);
