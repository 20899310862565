import React, { useContext } from 'react';

import { TemplateItem, ArrayUtils, TemplateItemType } from '@bringk/shared';

import ReceiptRecord from 'src/components/ReceiptRecord/index';
import useFormat from 'src/hooks/useFormat';
import TextInfo from 'src/pages/order/summary/TextInfo';
import ItemFormatter from 'src/pages/order/templates/ItemFormatter';
import AbstractTemplateItemSupporter from 'src/utils/template/AbstractTemplateItemSupporter';
import TemplateItemUtils from 'src/utils/TemplateItemUtils';

import { OrderSourceContext } from './OrderSource';

interface Props {
  templateItem: TemplateItem;
}

const CommonSummaryComponent = ({ templateItem }: Props) => {
  const { formatKeyword } = useFormat();
  const { product } = useContext(OrderSourceContext);
  const value = product[templateItem.property];
  if (templateItem.isMultipleChoice) {
    if (ArrayUtils.isNotEmpty(value)) {
      return <TextInfo value={value?.map((item: string) => formatKeyword(item))} />;
    }
  } else {
    if (Boolean(value)) {
      return <TextInfo value={formatKeyword(value)} />;
    }
  }
  return null;
};

const CommonReceiptComponent = ({ templateItem }: Props) => {
  const { format, formatKeyword } = useFormat();
  const { product } = useContext(OrderSourceContext);
  const value = product[templateItem.property];
  const description = product.description[templateItem.property];
  const shortTitleId = TemplateItemUtils.getShortTitle(templateItem.type);
  if (templateItem.isMultipleChoice) {
    return (
      <ReceiptRecord
        label={format(shortTitleId)}
        value={value?.map((item: string) => formatKeyword(item))}
        description={description}
      />
    );
  } else {
    return (
      <ReceiptRecord
        label={format(shortTitleId)}
        value={formatKeyword(value)}
        description={description}
      />
    );
  }
};

export default class CommonSupporter extends AbstractTemplateItemSupporter {
  support(templateItemType: TemplateItemType): boolean {
    switch (templateItemType) {
      case TemplateItemType.BUBBLE:
      case TemplateItemType.CAPACITY:
      case TemplateItemType.CAPACITY_PER_SHEET:
      case TemplateItemType.CONTAINER_PUMPING:
      case TemplateItemType.EWG:
      case TemplateItemType.FINISH_LEVEL:
      case TemplateItemType.FORMULATION:
      case TemplateItemType.FUNCTIONALITY:
      case TemplateItemType.MOISTURE_LEVEL:
      case TemplateItemType.NON_ADDITIVES:
      case TemplateItemType.NOSE_STRIP:
      case TemplateItemType.PH_BALANCED:
      case TemplateItemType.SCENT:
      case TemplateItemType.SHEET_FABRIC:
      case TemplateItemType.SHEET_SHAPE:
      case TemplateItemType.SUNSCREEN_TYPE:
      case TemplateItemType.TARGET_BOX_SET:
        return true;
      default:
        return false;
    }
  }

  render(templateItem: TemplateItem): React.ReactNode {
    return <ItemFormatter templateItem={templateItem} />;
  }

  renderSummary(templateItem: TemplateItem): React.ReactNode | null {
    return <CommonSummaryComponent templateItem={templateItem} />;
  }

  renderReceipt(templateItem: TemplateItem): React.ReactNode | null {
    return <CommonReceiptComponent templateItem={templateItem} />;
  }
}
