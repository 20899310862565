"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Producer {
    constructor() {
        this.name = null;
        this.email = null;
        this.telephone = null;
        this.country = null;
        this.businessRegistrationNumber = null;
        this.businessRegistrationNumberAttachment = null;
        this.businessCardAttachment = null;
        this.businessHomepage = null;
        this.acceptance = false;
    }
}
exports.Producer = Producer;
