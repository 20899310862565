import React, { useContext } from 'react';

import { TemplateItem, TemplateItemType } from '@bringk/shared';

import ReceiptRecord from 'src/components/ReceiptRecord/index';
import useFormat from 'src/hooks/useFormat';
import TextInfo from 'src/pages/order/summary/TextInfo';
import Color from 'src/pages/order/templates/Color';
import AbstractTemplateItemSupporter from 'src/utils/template/AbstractTemplateItemSupporter';
import TemplateItemUtils from 'src/utils/TemplateItemUtils';

import { OrderSourceContext } from './OrderSource';

interface Props {
  templateItem: TemplateItem;
}

const FabricColorSummaryComponent = () => {
  const { formatKeyword } = useFormat();
  const {
    product: { fabricColor: value },
  } = useContext(OrderSourceContext);
  if (Boolean(value)) {
    return <TextInfo value={formatKeyword(value)} />;
  }
  return null;
};

const FabricColorReceiptComponent = ({ templateItem }: Props) => {
  const { format, formatKeyword } = useFormat();
  const {
    product: {
      fabricColor: value,
      description: { fabricColor: description },
    },
  } = useContext(OrderSourceContext);
  const shortTitle = TemplateItemUtils.getShortTitle(templateItem.type);
  return (
    <ReceiptRecord
      label={format(shortTitle)}
      value={formatKeyword(value)}
      description={description}
    />
  );
};

export default class FabricColorSupporter extends AbstractTemplateItemSupporter {
  support(templateItemType: TemplateItemType): boolean {
    return templateItemType === TemplateItemType.FABRIC_COLOR;
  }
  render(templateItem: TemplateItem): React.ReactNode {
    return <Color templateItem={templateItem} />;
  }
  renderSummary(): React.ReactNode | null {
    return <FabricColorSummaryComponent />;
  }
  renderReceipt(templateItem: TemplateItem): React.ReactNode | null {
    return <FabricColorReceiptComponent templateItem={templateItem} />;
  }
}
