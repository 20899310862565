import classNames from 'classnames/bind';
import React from 'react';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface Props {
  current: number;
  total?: number;
}

const OrderStepPageNumber = (props: Props) => {
  return (
    <div className={cx('container')}>
      {props.total ? `${props.current + 1} / ${props.total + 1}` : `${props.current + 1}`}
    </div>
  );
};

export default OrderStepPageNumber;
