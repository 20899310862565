import classNames from 'classnames/bind';
import React from 'react';

import { Category } from '@bringk/shared';

import { ReactComponent as CleansingSvg } from 'src/images/svg/icon_category_cleansing.svg';
import { ReactComponent as Cream1Svg } from 'src/images/svg/icon_category_cream1.svg';
import { ReactComponent as Cream2Svg } from 'src/images/svg/icon_category_cream2.svg';
import { ReactComponent as CusionSvg } from 'src/images/svg/icon_category_cusion.svg';
import { ReactComponent as EssenceSvg } from 'src/images/svg/icon_category_essence.svg';
import { ReactComponent as LotionSvg } from 'src/images/svg/icon_category_lotion.svg';
import { ReactComponent as MaskSvg } from 'src/images/svg/icon_category_mask.svg';
import { ReactComponent as MistSvg } from 'src/images/svg/icon_category_mist.svg';
import { ReactComponent as None1Svg } from 'src/images/svg/icon_category_none1.svg';
import { ReactComponent as None2Svg } from 'src/images/svg/icon_category_none2.svg';
import { ReactComponent as NosepackSvg } from 'src/images/svg/icon_category_nosepack.svg';
import { ReactComponent as OilSvg } from 'src/images/svg/icon_category_oil.svg';
import { ReactComponent as Pad1Svg } from 'src/images/svg/icon_category_pad1.svg';
import { ReactComponent as Pad2Svg } from 'src/images/svg/icon_category_pad2.svg';
import { ReactComponent as SerumSvg } from 'src/images/svg/icon_category_serum.svg';
import { ReactComponent as SpraySvg } from 'src/images/svg/icon_category_spray.svg';
import { ReactComponent as StickSvg } from 'src/images/svg/icon_category_stick.svg';
import { ReactComponent as TonerSvg } from 'src/images/svg/icon_category_toner.svg';
import { ReactComponent as TubeSvg } from 'src/images/svg/icon_category_tube.svg';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface Props {
  category: Category;
}

const CategorySvg = (props: Props) => {
  const svgProps = { width: '100px', height: '100px' };
  const smallSvgProps = { width: '70px', height: '70px' };
  switch (props.category) {
    case Category.SKIN_CARE:
      return <TonerSvg {...svgProps} />;
    case Category.SKIN_CARE_TONER:
      return <TonerSvg {...svgProps} />;
    case Category.SKIN_CARE_TONER_WATER:
      return <TonerSvg {...svgProps} />;
    case Category.SKIN_CARE_TONER_PAD:
      return <Pad1Svg {...svgProps} />;
    case Category.SKIN_CARE_ESSENCE:
      return <SerumSvg {...svgProps} />;
    case Category.SKIN_CARE_OIL:
      return <None1Svg {...svgProps} />;
    case Category.SKIN_CARE_LOTION:
      return <LotionSvg {...svgProps} />;
    case Category.SKIN_CARE_CREAM:
      return <Cream1Svg {...svgProps} />;
    case Category.SKIN_CARE_CREAM_FACE:
      return <Cream1Svg {...svgProps} />;
    case Category.SKIN_CARE_CREAM_NECK:
      return <Cream1Svg {...svgProps} />;
    case Category.SKIN_CARE_CREAM_EYE:
      return <Cream2Svg {...svgProps} />;
    case Category.SKIN_CARE_MASK:
      return <MaskSvg {...svgProps} />;
    case Category.SKIN_CARE_MASK_SHEET:
      return <MaskSvg {...svgProps} />;
    case Category.SKIN_CARE_MASK_WASH_OFF:
      return <TubeSvg {...svgProps} />;
    case Category.SKIN_CARE_MASK_PEEL_OFF:
      return <TubeSvg {...svgProps} />;
    case Category.SKIN_CARE_MASK_SLEEPING:
      return <Pad2Svg {...svgProps} />;
    case Category.SKIN_CARE_MASK_NOSE:
      return <NosepackSvg {...svgProps} />;
    case Category.SKIN_CARE_MIST:
      return <MistSvg {...svgProps} />;
    case Category.SKIN_CARE_MIST_MOISTURIZER:
      return <MistSvg {...svgProps} />;
    case Category.SKIN_CARE_CLEANSING:
      return <CleansingSvg {...svgProps} />;
    case Category.SKIN_CARE_CLEANSING_LIP_AND_EYE:
      return <OilSvg {...svgProps} />;
    case Category.SKIN_CARE_CLEANSING_EYE:
      //   return <OilSvg {...smallSvgProps} />;
      // case Category.SKIN_CARE_CLEANSING_MASCARA:
      return <OilSvg {...smallSvgProps} />;
    case Category.SKIN_CARE_CLEANSING_OIL:
      return <EssenceSvg {...svgProps} />;
    case Category.SKIN_CARE_CLEANSING_MILK:
      return <None2Svg {...svgProps} />;
    case Category.SKIN_CARE_CLEANSING_WATER:
      return <None1Svg {...svgProps} />;
    case Category.SKIN_CARE_CLEANSING_FOAM:
      return <TubeSvg {...svgProps} />;
    case Category.SKIN_CARE_PEELING:
      return <TubeSvg {...svgProps} />;
    case Category.SKIN_CARE_LIP:
      return <Pad2Svg {...smallSvgProps} />;
    case Category.SKIN_CARE_LIP_SCRUB:
      return <Pad2Svg {...smallSvgProps} />;
    case Category.SKIN_CARE_LIP_MOISTURIZER:
      return <Pad2Svg {...smallSvgProps} />;
    case Category.SKIN_CARE_LIP_MASK:
      return <Pad2Svg {...smallSvgProps} />;
    case Category.SKIN_CARE_SUN:
      return <TubeSvg {...svgProps} />;
    case Category.SKIN_CARE_SUN_BLOCK:
      return <TubeSvg {...svgProps} />;
    case Category.SKIN_CARE_SUN_CUSHION:
      return <CusionSvg {...svgProps} />;
    case Category.SKIN_CARE_SUN_STICK:
      return <StickSvg {...svgProps} />;
    case Category.SKIN_CARE_SUN_SPRAY:
      return <SpraySvg {...svgProps} />;
    case Category.MAKE_UP:
      return <CusionSvg {...svgProps} />;
    case Category.ETC:
      return <TubeSvg {...svgProps} />;
    default:
      return <h1>{props.category}</h1>;
  }
};

export default CategorySvg;
