"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Ingredient {
    constructor() {
        this.id = '';
        this.origin = 'NONE';
        this.ewg = 'NONE';
        this.etc = 'NONE';
        this.image = null;
    }
}
exports.Ingredient = Ingredient;
