import { json as jsonMobx } from 'json-mobx';
import { useCallback } from 'react';

import useOrderStep from 'src/hooks/useOrderStep';
import useStore from 'src/hooks/useStore';

const LAST_SAVED_DATA_VERSION = 1;
const LAST_SAVED_DATA_KEY = 'LAST_SAVED_DATA';

interface TemporaryData {
  data: any;
  stepId: number;
  subStepId: number;
  version: number;
  timestamp: number;
}

const useOrderTemporarySave = () => {
  const { orderStore } = useStore();
  const { setStep } = useOrderStep();

  const hasData = useCallback(() => {
    const jsonStr = localStorage.getItem(LAST_SAVED_DATA_KEY);
    const temporaryData: TemporaryData = jsonStr && JSON.parse(jsonStr);
    if (temporaryData && temporaryData.version === LAST_SAVED_DATA_VERSION) {
      return true;
    } else {
      return false;
    }
  }, []);

  const saveData = useCallback(
    (stepId: number, subStepId: number) => {
      if (!orderStore.product.category) {
        return;
      }

      const data = jsonMobx.save(orderStore);
      const temporaryData: TemporaryData = {
        data: data,
        stepId,
        subStepId,
        version: LAST_SAVED_DATA_VERSION,
        timestamp: new Date().getTime(),
      };
      const jsonStr = JSON.stringify(temporaryData);
      localStorage.setItem(LAST_SAVED_DATA_KEY, jsonStr);
    },
    [orderStore],
  );

  const goSavedPath = useCallback(() => {
    const jsonStr = localStorage.getItem(LAST_SAVED_DATA_KEY);
    const temporaryData: TemporaryData = jsonStr && JSON.parse(jsonStr);
    if (temporaryData && temporaryData.version === LAST_SAVED_DATA_VERSION) {
      try {
        const { stepId, subStepId } = temporaryData;
        setStep(stepId, subStepId);
      } catch (error) {
        console.error(error);
      }
    }
  }, [orderStore, setStep]);

  const goFirstPath = useCallback(() => {
    setStep(1, 0);
  }, [setStep]);

  const loadData = useCallback(() => {
    const jsonStr = localStorage.getItem(LAST_SAVED_DATA_KEY);
    const temporaryData: TemporaryData = jsonStr && JSON.parse(jsonStr);
    if (temporaryData && temporaryData.version === LAST_SAVED_DATA_VERSION) {
      try {
        const { data, stepId, subStepId } = temporaryData;
        jsonMobx.load(orderStore, data);
        setStep(stepId, subStepId);
      } catch (error) {
        console.error(error);
      }
    }
  }, [orderStore, setStep]);

  const clearData = useCallback(() => {
    localStorage.removeItem(LAST_SAVED_DATA_KEY);
  }, []);

  return { hasData, saveData, loadData, clearData, goSavedPath, goFirstPath };
};

export default useOrderTemporarySave;
