import React from 'react';
import { hot } from 'react-hot-loader/root';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import NavigationHeader from './components/NavigationHeader/index';
import AboutPage from './pages/about';
import DebugPage from './pages/debug';
import HistoryPage from './pages/history';
import OrderHistoryPage from './pages/history/order';
import HomePage from './pages/home';
import NotFoundPage from './pages/notFound';
import OrderPage from './pages/order';
import { Phase } from './utils/PhaseUtil';

const App = () => {
  return (
    <BrowserRouter>
      <NavigationHeader />
      <Route
        render={({ location }) => {
          const locationKey = location.pathname.split('/').slice(0, 2).join('/'); // 첫번째 path 가 변경시에만 페이지 트렌지션이 발생하도록 수정
          return (
            <TransitionGroup className="root-transition-group">
              <CSSTransition key={locationKey} timeout={300} classNames="fade">
                <div className="root-route">
                  <Switch>
                    <Route path="/debug" component={DebugPage} />
                    <Route path="/order" component={OrderPage} />
                    <Route path="/history/orders/:orderId" component={OrderHistoryPage} />
                    <Route path="/history" component={HistoryPage} />
                    <Route path="/about" component={AboutPage} />
                    <Route path="/" component={HomePage} />
                    <Route component={NotFoundPage} />
                  </Switch>
                </div>
              </CSSTransition>
            </TransitionGroup>
          );
        }}
      />
    </BrowserRouter>
  );
};

export default Phase.isDev ? hot(App) : App;
