import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames/bind';
import React, { useCallback } from 'react';

import { Tooltip } from '@bringk/shared';

import TooltipButton from 'src/components/TooltipButton';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface OwnProps {
  isChecked?: boolean;
  label?: string | JSX.Element;
  tooltip?: Tooltip;
  onChanged?: (checked: boolean) => void;
}

type Props = OwnProps;

const CheckBox = ({ isChecked, label, tooltip, onChanged }: Props) => {
  const handleChange = useCallback(() => onChanged?.(!isChecked), [onChanged, isChecked]);

  return (
    <div className={cx('container', { checked: isChecked })} onClick={handleChange}>
      <div className={cx('mark')}>
        <FontAwesomeIcon className={cx('icon')} icon={faCheck} size="1x" color="white" />
      </div>
      <div className={cx('label')}>{label}</div>
      {tooltip && <TooltipButton tooltips={[tooltip]} />}
    </div>
  );
};

export default CheckBox;
