import { json } from 'json-mobx';
import { observable, action } from 'mobx';
import { actionAsync, task } from 'mobx-utils';

import { TemplateItemWorkers, Product, Producer, Order, Database, Category } from '@bringk/shared';

import AxiosUtils from 'src/utils/AxiosUtils';

class OrderStore {
  @json @observable id?: string;
  @json @observable product: Product;
  @json @observable producer: Producer;

  constructor() {
    this.id = undefined;
    this.product = new Product();
    this.producer = new Producer();
  }

  @action
  clear() {
    this.id = undefined;
    this.product = new Product();
    this.producer = new Producer();
  }

  @action
  initialize = (category: Category) => {
    this.id = undefined;
    this.product = new Product(category);
    this.producer = new Producer();

    const template = Database.templates.get(category);
    if (!template) throw new Error('CategoryInvalid');
    TemplateItemWorkers.initialize(template, this.producer, this.product);
  };

  @action
  setProductProperty = (property: string, value: any) => {
    const newProduct = { ...this.product, [property]: value };
    this.product = newProduct;
  };

  @action
  setProductDescriptionProperty = (property: string, value: any) => {
    const newDescription = { ...this.product.description, [property]: value };
    const newProduct = { ...this.product, description: newDescription };
    this.product = newProduct;
  };

  @action
  setProducerProperty = (property: string, value: any) => {
    const newProducer = { ...this.producer, [property]: value };
    this.producer = newProducer;
  };

  @actionAsync
  post = async () => {
    const order = new Order(this.producer, this.product);
    const response = await task(AxiosUtils.post(`/orders`, order));
    const orderResponse = response.data.contents;
    this.id = orderResponse.id;
  };
}

export default OrderStore;
