import i18next from 'i18next';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const useFormat = () => {
  const { t } = useTranslation();
  const format = useCallback((id: string, obj?: any | null) => (id ? t(id, obj) : ''), [t]);
  const formatPrefix = useCallback(
    (prefix: string, id?: string | null, obj?: any) => {
      if (!id) return;
      const key = prefix + id;
      if (i18next.exists(key)) {
        return t(key, obj);
      }
      return id;
    },
    [t],
  );
  const formatKeyword = useCallback((id?: string | null) => formatPrefix('Keywords.', id), [
    formatPrefix,
  ]);

  return { format, formatPrefix, formatKeyword };
};

export default useFormat;
