"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const __1 = require("..");
class Order {
    constructor(producer, product) {
        this.product = product !== null && product !== void 0 ? product : new __1.Product();
        this.producer = producer !== null && producer !== void 0 ? producer : new __1.Producer();
    }
    validate() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.product.category)
                throw new Error('CategoryUndefined');
            const template = __1.Database.templates.get(this.product.category);
            if (!template)
                throw new Error('CategoryInvalid');
            __1.TemplateItemWorkers.validate(template, this.producer, this.product);
        });
    }
}
exports.Order = Order;
