import classNames from 'classnames/bind';
import React from 'react';

import { CONSTANTS } from '@bringk/shared';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface Props extends React.HTMLProps<HTMLTextAreaElement> {
  key?: string;
}

const TextArea = (props: Props) => {
  return (
    <textarea
      key={props.key}
      className={cx('container')}
      maxLength={CONSTANTS.MAX_TEXT_LENGTH}
      {...props}
    >
      {props.children}
    </textarea>
  );
};

export default TextArea;
