import classNames from 'classnames/bind';
import React from 'react';

import { Category } from '@bringk/shared';

import CategorySvg from 'src/components/CategorySvg';
import useFormat from 'src/hooks/useFormat';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface Props {
  category: Category;
  selected: boolean;
  onClick?: () => void;
  comingSoon?: boolean;
}

const CategoryCard = (props: Props) => {
  const { formatPrefix } = useFormat();
  return (
    <div
      className={cx('container', { selected: props.selected, comingSoon: props.comingSoon })}
      onClick={props.onClick}
    >
      <div className={cx('icon')}>
        <CategorySvg category={props.category} />
      </div>
      <div className={cx('label')}>{formatPrefix('Category.', props.category)}</div>
      {props.comingSoon && (
        <div className={cx('comingSoon')}>{formatPrefix('Texts.', 'ComingSoon')}</div>
      )}
    </div>
  );
};

export default CategoryCard;
