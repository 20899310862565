import classNames from 'classnames/bind';
import React from 'react';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface Props extends React.HTMLProps<HTMLDivElement> {}

const ReceiptTable = (props: Props) => {
  return <div className={cx('table')}>{props.children}</div>;
};

export default ReceiptTable;
