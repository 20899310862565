import * as firebase from 'firebase';

const getFirebaseApplication = () => {
  if (firebase.apps.length) {
    return firebase.app();
  }
  const firebaseApp = firebase.initializeApp({
    apiKey: 'AIzaSyDNjds8dEXeeizasxf3C2mYlSnzmMapQH8',
    authDomain: 'bringk.firebaseapp.com',
    databaseURL: 'https://bringk.firebaseio.com',
    projectId: 'bringk',
    storageBucket: 'bringk.appspot.com',
    messagingSenderId: '989091466885',
    appId: '1:989091466885:web:c654028fb8454f0defa3ec',
  });
  firebaseApp.firestore().settings({
    cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
  });
  firebaseApp.firestore().enablePersistence();
  return firebaseApp;
};

const FirebaseApplication = getFirebaseApplication();
export default FirebaseApplication;
