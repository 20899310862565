import classNames from 'classnames/bind';
import React from 'react';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface Props {
  src: string;
}

const ItemPreviewImage = (props: Props) => {
  return (
    <div className={cx('container')}>
      <img className={cx('image')} {...props} />
    </div>
  );
};

export default ItemPreviewImage;
