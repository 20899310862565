import { isString } from 'lodash';
import { observer } from 'mobx-react';
import React, { useCallback } from 'react';

import { TemplateItem } from '@bringk/shared';

import RadioButtons from 'src/components/RadioButtons';
import useFormat from 'src/hooks/useFormat';
import useStore from 'src/hooks/useStore';

interface Props {
  templateItem: TemplateItem;
}

const TargetCustomerGender = ({ templateItem }: Props) => {
  const { formatPrefix } = useFormat();
  const { orderStore } = useStore();
  const targetProperty = templateItem.property;
  const selectedItemKey: string | undefined = orderStore.product[targetProperty];

  const selectableItems = templateItem.choiceValues!.filter(isString).map(choiceValue => {
    const tooltip = templateItem.getTooltip(choiceValue);
    return {
      key: choiceValue,
      label: formatPrefix('Gender.', choiceValue),
      tooltip: tooltip ? formatPrefix('Tooltips.', tooltip.value) : undefined,
    };
  });

  const handleItemSelected = useCallback(
    (itemKey?: string) => orderStore.setProductProperty(targetProperty, itemKey),
    [orderStore, targetProperty],
  );

  return (
    <RadioButtons
      selectedItemKey={selectedItemKey}
      handleItemSelected={handleItemSelected}
      selectableItems={selectableItems}
    />
  );
};

export default observer(TargetCustomerGender);
