"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ingredientEwgs = ['GREEN', 'YELLOW', 'ORANGE', 'RED', 'NONE'];
exports.ingredientOrigins = [
    'ANIMAL',
    'FERMENTATION',
    'GRAIN',
    'MARINE',
    'VEGETABLE',
    'NONE',
];
exports.ingredientETCs = ['AHA', 'BHA', 'EGF', 'PHA', 'Q10', 'NONE'];
