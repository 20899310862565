import 'react-calendar/dist/Calendar.css';
import classNames from 'classnames/bind';
import React from 'react';
import Calendar, { CalendarProps } from 'react-calendar';

import styles from './index.module.scss';
import './index.override.scss';

const cx = classNames.bind(styles);

interface Props extends CalendarProps {}

const CalendarComponent = (props: Props) => {
  return (
    <div className={cx('container')}>
      <Calendar {...props} />
    </div>
  );
};

export default CalendarComponent;
