import { useCallback, useMemo } from 'react';

import {
  TemplateItem,
  TemplateItemWorkers,
  TemplateItemType,
  Database,
  ValidationError,
  Category,
} from '@bringk/shared';

import OrderStore from 'src/store/OrderStore';
import TemplateItemUtils from 'src/utils/TemplateItemUtils';

export interface StepValidationError {
  messageId: string;
  messagePayload: string | null;
}

const useOrderTemplate = (category: Category | undefined | null) => {
  const template = useMemo(() => category && Database.templates.get(category), [category]);
  const templateItems = useMemo(() => template?.items || [], [template]);

  const requiredTemplateItems = useMemo(
    () => TemplateItemUtils.sort(templateItems).filter(templateItem => templateItem.isRequired),
    [templateItems],
  );

  const unrequiredTemplateItems = useMemo(
    () => TemplateItemUtils.sort(templateItems).filter(templateItem => !templateItem.isRequired),
    [templateItems],
  );

  const getTemplateItemsByStepId = useCallback(
    stepId => {
      switch (stepId) {
        case 2:
          return requiredTemplateItems;
        case 3:
          return unrequiredTemplateItems;
        default:
          return [];
      }
    },
    [requiredTemplateItems, unrequiredTemplateItems],
  );

  const getTemplateItemByStep = useCallback(
    (stepId: number, subStepId: number): TemplateItem | null =>
      getTemplateItemsByStepId(stepId)[subStepId] || null,
    [getTemplateItemsByStepId],
  );

  const getTemplateItemByType = useCallback(
    (templateItemType: TemplateItemType): TemplateItem | null =>
      templateItems.find(item => item.type === templateItemType) || null,
    [templateItems],
  );

  const getSubStepMaxId = useCallback(
    (stepId: number): number => {
      return (getTemplateItemsByStepId(stepId).length || 0) - 1;
    },
    [getTemplateItemsByStepId],
  );

  const getStepByType = useCallback(
    (
      templateItemType: TemplateItemType,
    ): {
      stepId: number;
      subStepId: number;
    } | null => {
      for (const stepId of [2, 3]) {
        const subStepId = getTemplateItemsByStepId(stepId).findIndex(
          templateItem => templateItem.type === templateItemType,
        );
        if (subStepId !== -1) {
          return {
            stepId,
            subStepId,
          };
        }
      }
      return null;
    },
    [getTemplateItemsByStepId],
  );

  const getSubStepValidationError = useCallback(
    (stepId: number, subStepId: number, orderStore?: OrderStore): StepValidationError | null => {
      switch (stepId) {
        case 2:
        case 3:
          try {
            if (!orderStore) throw Error('Order store is empty');
            const currentTemplateItem = getTemplateItemByStep(stepId, subStepId);
            if (!currentTemplateItem) throw Error('Template item is undefined');
            TemplateItemWorkers.validateItem(
              currentTemplateItem,
              orderStore.producer,
              orderStore.product,
            );

            return null;
          } catch (error) {
            console.error(error);
            if (error instanceof ValidationError) {
              return {
                messageId: error.message,
                messagePayload: error.payload,
              };
            } else {
              return {
                messageId: error.message,
                messagePayload: null,
              };
            }
          }
        default:
          return null;
      }
    },
    [getTemplateItemByStep],
  );

  return {
    template,
    requiredTemplateItems,
    unrequiredTemplateItems,
    getTemplateItemByType,
    getTemplateItemByStep,
    getStepByType,
    getSubStepMaxId,
    getSubStepValidationError,
  };
};

export default useOrderTemplate;
