import classNames from 'classnames/bind';
import React, { useCallback } from 'react';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface Props {
  label: string;
  value?: { name: any; hints?: { hint: any; matched?: boolean }[] }[];
  sequence?: boolean;
  description?: string;
}

const ReceiptIngredientsRecord = (props: Props) => {
  const renderIngredientIds = useCallback(
    (values?: { name: any; hints?: { hint: any; matched?: boolean }[] }[]) => {
      if (!values) return <></>;
      return values.map((value, index) => {
        const functionalities = value.hints?.map((hint, index) => {
          return (
            <span key={hint.hint} className={cx({ matched: hint.matched })}>
              {hint.hint}
              {value.hints!.length - 1 !== index && ', '}
            </span>
          );
        });
        return (
          <div key={value.name} className={cx('record-value-element')}>
            {props.sequence && `${index + 1}. `}
            {value.name}{' '}
            {Boolean(functionalities && functionalities.length) && (
              <span className={cx('hint')}>({functionalities}) </span>
            )}
          </div>
        );
      });
    },
    [],
  );

  return (
    <div className={cx('receipt-ingredients-record')}>
      <div className={cx('record-key')}>{props.label}</div>
      <div className={cx('record-value')}>
        {props.value && renderIngredientIds(props.value)}
        {props.description && <div className={cx('description')}>{`* ${props.description}`}</div>}
      </div>
    </div>
  );
};

export default ReceiptIngredientsRecord;
