"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class SampleDatabase {
    static getInstance() {
        if (!SampleDatabase.instance)
            SampleDatabase.instance = new SampleDatabase();
        return SampleDatabase.instance;
    }
    getInt() {
        return 2;
    }
}
exports.SampleDatabase = SampleDatabase;
