import classNames from 'classnames/bind';
import React from 'react';

import { CONSTANTS } from '@bringk/shared';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface Props extends React.HTMLProps<HTMLInputElement> {}

const InputText = (props: Props) => {
  return (
    <input
      type="text"
      className={cx('container')}
      maxLength={CONSTANTS.MAX_STRING_LENGTH}
      {...props}
    ></input>
  );
};

export default InputText;
