"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class StringUtils {
}
exports.StringUtils = StringUtils;
StringUtils.trimWhiteSpace = (arg) => {
    if (!Boolean(arg))
        return '';
    return arg.trim().replace(/\s/g, '');
};
StringUtils.isExistWhiteSpace = (arg) => {
    if (!Boolean(arg))
        return true;
    const compactStringRegularExpression = new RegExp('[a-zA-Z0-9-_]+');
    return !compactStringRegularExpression.test(arg);
};
StringUtils.remove = (arrays, target) => {
    if (Array.isArray(target))
        return arrays.filter(str => {
            for (const trg of target) {
                if (str !== trg)
                    return false;
            }
            return true;
        });
    else
        return arrays.filter(str => str !== target);
};
StringUtils.count = (str, searchValue) => {
    return str.split(searchValue).length - 1;
};
StringUtils.capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
