import imageCompression from 'browser-image-compression';

class FileUtils {
  private static compileOption = {
    maxSizeMB: 2.0,
    maxWidthOrHeight: 4096,
    useWebWorker: true,
  };

  static convertBase64 = async (
    file?: File | null,
  ): Promise<{ compressedFile: File; base64: string } | null> => {
    if (!file) return Promise.resolve(null);
    const compressedFile = (await imageCompression(file, FileUtils.compileOption)) as File;
    return {
      compressedFile: compressedFile,
      base64: await imageCompression.getDataUrlFromFile(compressedFile),
    };
  };

  static compressMulti = async (
    files?: FileList | null,
  ): Promise<({ compressedFile: File; base64: string } | null)[] | null> => {
    if (!files || !files.length) return Promise.resolve(null);
    const promises: Promise<{ compressedFile: File; base64: string } | null>[] = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      promises.push(FileUtils.convertBase64(file));
    }
    return await Promise.all(promises);
  };
}

export default FileUtils;
