import { ArrayUtils } from '@bringk/shared';

import ImageRect from './imageRect';

export interface SrcImage {
  text: string;
  imageElement?: HTMLImageElement;
  imageScale: number;
  textInImageRect: ImageRect;
  tintColor?: string | null;
}

export interface DstImage {
  text: string;
  imageElement: HTMLImageElement;
  imageRect: ImageRect;
  textRect: ImageRect;
  tintColor?: string | null;
}

export const getDstImageFromSrcImage = (
  srcImages: SrcImage[],
  canvasSize: { width: number; height: number },
  spacing: number,
): DstImage[] => {
  let oldImgX = 0;
  let images: DstImage[] = ArrayUtils.filterNotNull(
    srcImages.map(srcImage => {
      const { imageElement, textInImageRect, tintColor, imageScale } = srcImage;
      if (!imageElement) {
        return null;
      }

      const imageRect = {
        x: oldImgX,
        y: canvasSize.height - imageElement.height * imageScale,
        width: imageElement.width * imageScale,
        height: imageElement.height * imageScale,
      };

      const textRect = {
        x: imageRect.x + textInImageRect.x * imageScale,
        y: imageRect.y + textInImageRect.y * imageScale,
        width: textInImageRect.width * imageScale,
        height: textInImageRect.height * imageScale,
      };

      const image: DstImage = {
        text: srcImage.text,
        imageElement,
        imageRect,
        textRect,
        tintColor,
      };
      oldImgX += image.imageRect.width + spacing;
      return image;
    }),
  );
  const adjustX = (canvasSize.width - (oldImgX - spacing)) / 2;
  images = images.map(image => ({
    ...image,
    imageRect: {
      ...image.imageRect,
      x: image.imageRect.x + adjustX,
    },
    textRect: {
      ...image.textRect,
      x: image.textRect.x + adjustX,
    },
  }));

  return images;
};
