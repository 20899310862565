import { Category } from '@bringk/shared';

import MaskpackNosepackPouchImg from 'src/images/cosmetic/container/maskpack(nosepack-pouch).png';
import MaskpackPouchImg from 'src/images/cosmetic/container/maskpack(pouch).png';
import MistMoisturizingImg from 'src/images/cosmetic/container/mist(moisturizing).png';
import PlasticPumpingImg from 'src/images/cosmetic/container/plastic_pumping.png';
import PlasticJarImg from 'src/images/cosmetic/container/plasticjar.png';
import PlasticJarSmallImg from 'src/images/cosmetic/container/plasticjar_small.png';
import SerumImg from 'src/images/cosmetic/container/serum.png';
import SunBlockImg from 'src/images/cosmetic/container/sunblock.png';
import SunCusionImg from 'src/images/cosmetic/container/suncusion.png';
import SunSprayImg from 'src/images/cosmetic/container/sunspray.png';
import SunStickImg from 'src/images/cosmetic/container/sunstick.png';
import TonerAImg from 'src/images/cosmetic/container/toner_A.png';
import TonerBImg from 'src/images/cosmetic/container/toner_B.png';
import TonerPadImg from 'src/images/cosmetic/container/toner_pad.png';
import TubeImg from 'src/images/cosmetic/container/tube.png';
import ImageRect from 'src/pages/order/summary/ContainerInfo/imageRect';

export const getImagePaths = (): string[] => {
  return [
    TonerAImg,
    TonerBImg,
    TonerPadImg,
    SerumImg,
    PlasticJarImg,
    PlasticJarSmallImg,
    MaskpackPouchImg,
    TubeImg,
    PlasticPumpingImg,
    MaskpackNosepackPouchImg,
    MistMoisturizingImg,
    SunBlockImg,
    SunCusionImg,
    SunStickImg,
    SunSprayImg,
  ];
};

const getImagePath = (category: Category): string | null => {
  switch (category) {
    case Category.SKIN_CARE_TONER_WATER:
    case Category.SKIN_CARE_LOTION:
    case Category.SKIN_CARE_CLEANSING_WATER:
      return TonerAImg;
    case Category.SKIN_CARE_CLEANSING_LIP_AND_EYE:
    case Category.SKIN_CARE_CLEANSING_EYE:
      // case Category.SKIN_CARE_CLEANSING_MASCARA:
      return TonerBImg;
    case Category.SKIN_CARE_TONER_PAD:
      return TonerPadImg;
    case Category.SKIN_CARE_ESSENCE:
    case Category.SKIN_CARE_OIL:
      return SerumImg;
    case Category.SKIN_CARE_CREAM_FACE:
    case Category.SKIN_CARE_CREAM_NECK:
      return PlasticJarImg;
    case Category.SKIN_CARE_CREAM_EYE:
    case Category.SKIN_CARE_LIP_SCRUB:
    case Category.SKIN_CARE_LIP_MOISTURIZER:
    case Category.SKIN_CARE_LIP_MASK:
      return PlasticJarSmallImg;
    case Category.SKIN_CARE_MASK_SHEET:
      return MaskpackPouchImg;
    case Category.SKIN_CARE_MASK_WASH_OFF:
    case Category.SKIN_CARE_MASK_SLEEPING:
    case Category.SKIN_CARE_MASK_PEEL_OFF:
    case Category.SKIN_CARE_CLEANSING_FOAM:
    case Category.SKIN_CARE_PEELING:
      return TubeImg;
    case Category.SKIN_CARE_CLEANSING_OIL:
    case Category.SKIN_CARE_CLEANSING_MILK:
      return PlasticPumpingImg;
    case Category.SKIN_CARE_MASK_NOSE:
      return MaskpackNosepackPouchImg;
    case Category.SKIN_CARE_MIST_MOISTURIZER:
      return MistMoisturizingImg;
    case Category.SKIN_CARE_SUN_BLOCK:
      return SunBlockImg;
    case Category.SKIN_CARE_SUN_CUSHION:
      return SunCusionImg;
    case Category.SKIN_CARE_SUN_STICK:
      return SunStickImg;
    case Category.SKIN_CARE_SUN_SPRAY:
      return SunSprayImg;
    default:
      return null;
    // return DummyImg;
  }
};

const getImageRect = (imagePath: string): ImageRect | null => {
  switch (imagePath) {
    case MaskpackNosepackPouchImg: // 'src/images/cosmetic/container/maskpack(nosepack-pouch).png';
      return { x: 132, y: 62, width: 202, height: 144 };
    case MaskpackPouchImg: // 'src/images/cosmetic/container/maskpack(pouch).png';
      return { x: 73, y: 109, width: 121, height: 86 };
    case MistMoisturizingImg: // 'src/images/cosmetic/container/mist(moisturizing).png';
      return { x: 17, y: 133, width: 90, height: 63 };
    case PlasticPumpingImg: // 'src/images/cosmetic/container/plastic_pumping.png';
      return { x: 27, y: 249, width: 131, height: 92 };
    case PlasticJarImg: // 'src/images/cosmetic/container/plasticjar.png';
      return { x: 50, y: 113, width: 133, height: 93 };
    case PlasticJarSmallImg: // 'src/images/cosmetic/container/plasticjar_small.png';
      return { x: 43, y: 86, width: 96, height: 68 };
    case SerumImg: // 'src/images/cosmetic/container/serum.png';
      return { x: 29, y: 214, width: 130, height: 93 };
    case SunBlockImg: // 'src/images/cosmetic/container/sunblock.png';
      return { x: 45, y: 97, width: 132, height: 93 };
    case SunCusionImg: // 'src/images/cosmetic/container/suncusion.png';
      return { x: 113, y: 82, width: 364, height: 115 };
    case SunSprayImg: // 'src/images/cosmetic/container/sunspray.png';
      return { x: 26, y: 195, width: 155, height: 120 };
    case SunStickImg: // 'src/images/cosmetic/container/sunstick.png';
      return { x: 56, y: 97, width: 134, height: 95 };
    case TonerAImg: // 'src/images/cosmetic/container/toner_A.png';
      return { x: 34, y: 195, width: 130, height: 94 };
    case TonerBImg: // 'src/images/cosmetic/container/toner_B.png';
      return { x: 27, y: 181, width: 110, height: 80 };
    case TonerPadImg: // 'src/images/cosmetic/container/toner_pad.png';
      return { x: 73, y: 116, width: 168, height: 117 };
    case TubeImg: // 'src/images/cosmetic/container/tube.png';
      return { x: 45, y: 70, width: 88, height: 62 };
    default:
      return null;
  }
};

export const getContainerInfo = (
  category: Category,
): { imagePath: string; imageRect: ImageRect } | null => {
  const imagePath = getImagePath(category);
  const imageRect = (imagePath && getImageRect(imagePath)) || null;

  if (imagePath && imageRect) {
    return {
      imagePath,
      imageRect,
    };
  } else {
    return null;
  }
};
