import classNames from 'classnames/bind';
import React from 'react';

import { Ingredient } from '@bringk/shared';

import useFormat from 'src/hooks/useFormat';

import { MAIN_CONCEPT_INGREDIENT_MAX_COUNT, SUB_CONCEPT_INGREDIENT_MAX_COUNT } from './config';
import List, { OnIngredientsChange } from './list';
import styles from './selectedLists.module.scss';

const cx = classNames.bind(styles);

interface Props {
  getIngredients: (ids: string[]) => Promise<Ingredient[]>;
  selectedMainIds: string[];
  selectedSubIds: string[];
  isViewOnly?: boolean;
  onIngredientsChange?: OnIngredientsChange;
}

const IngredientPickerSelectedListsComponent = ({
  getIngredients,
  selectedMainIds,
  selectedSubIds,
  isViewOnly,
  onIngredientsChange,
}: Props) => {
  const { formatKeyword } = useFormat();
  return (
    <div className={cx('container')}>
      <div className={cx('header')}>
        <div className={cx('main')}>{formatKeyword('MainConceptIngredients')}</div>
        <div className={cx('sub')}>
          {`${formatKeyword(
            'MaxSelectableCountSuffix',
          )}${MAIN_CONCEPT_INGREDIENT_MAX_COUNT}${formatKeyword('MaxSelectableCountPostfix')}`}
        </div>
      </div>
      <div className={cx('content')}>
        <List
          {...{
            id: 'main',
            isSelectionList: true,
            isViewOnly,
            getIngredients,
            visibleIds: selectedMainIds,
            selectedIds: selectedMainIds,
            onIngredientsChange,
            maxLength: MAIN_CONCEPT_INGREDIENT_MAX_COUNT,
          }}
        />
      </div>
      <div className={cx('header')}>
        <div className={cx('main')}>{formatKeyword('SubConceptIngredients')}</div>
        <div className={cx('sub')}>
          {`${formatKeyword(
            'MaxSelectableCountSuffix',
          )}${SUB_CONCEPT_INGREDIENT_MAX_COUNT}${formatKeyword('MaxSelectableCountPostfix')}`}
        </div>
      </div>
      <div className={cx('content')}>
        <List
          {...{
            id: 'sub',
            isSelectionList: true,
            isViewOnly,
            getIngredients,
            visibleIds: selectedSubIds,
            selectedIds: selectedSubIds,
            onIngredientsChange,
            maxLength: SUB_CONCEPT_INGREDIENT_MAX_COUNT,
          }}
        />
      </div>
    </div>
  );
};

export default IngredientPickerSelectedListsComponent;
