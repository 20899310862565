import classNames from 'classnames/bind';
import React from 'react';

import { Category } from '@bringk/shared';

import CategorySvg from 'src/components/CategorySvg';
import useFormat from 'src/hooks/useFormat';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface Props {
  categories: Category[];
}

const CategoryInfo = (props: Props) => {
  const { formatPrefix } = useFormat();
  return (
    <div className={cx('container')}>
      {props.categories.map(category => {
        return (
          <div className={cx('item')} key={category}>
            <div className={cx('icon')}>
              <CategorySvg category={category} />
            </div>
            <div className={cx('label')}>{formatPrefix('Category.', category)}</div>
          </div>
        );
      })}
    </div>
  );
};

export default CategoryInfo;
