"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const TemplateItemType_1 = require("../TemplateItemType");
const AbstractTemplateItemWorker_1 = require("./AbstractTemplateItemWorker");
const ValidationChainer_1 = require("./ValidationChainer");
class SheetFabricWorker extends AbstractTemplateItemWorker_1.AbstractTemplateItemWorker {
    support(templateItem) {
        return templateItem.type === TemplateItemType_1.TemplateItemType.SHEET_FABRIC;
    }
    initialize(templateItem, producer, product) {
        product.sheetFabric = null;
    }
    validate(templateItem, producer, product) {
        ValidationChainer_1.ValidationChainer.property(product.sheetFabric)
            .propertyName('sheet fabric')
            .stringOptional()
            .stringLimit();
    }
}
exports.SheetFabricWorker = SheetFabricWorker;
