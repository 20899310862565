"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const BubbleWorker_1 = require("./BubbleWorker");
const CapacityPerSheetWorker_1 = require("./CapacityPerSheetWorker");
const CapacityWorker_1 = require("./CapacityWorker");
const CleansingPowerWorker_1 = require("./CleansingPowerWorker");
const ColorWorker_1 = require("./ColorWorker");
const CompanyNameWorker_1 = require("./CompanyNameWorker");
const ContainerPumpingWorker_1 = require("./ContainerPumpingWorker");
const EwgWorker_1 = require("./EwgWorker");
const FabricColorWorker_1 = require("./FabricColorWorker");
const FeaturesWorker_1 = require("./FeaturesWorker");
const FinishLevelWorker_1 = require("./FinishLevelWorker");
const FormulationWorker_1 = require("./FormulationWorker");
const FunctionalityWorker_1 = require("./FunctionalityWorker");
const IngredientsWorker_1 = require("./IngredientsWorker");
const MoistureLevelWorker_1 = require("./MoistureLevelWorker");
const NameWorker_1 = require("./NameWorker");
const NonAdditivesWorker_1 = require("./NonAdditivesWorker");
const NoseStripWorker_1 = require("./NoseStripWorker");
const OrderQuantityWorker_1 = require("./OrderQuantityWorker");
const OtherRequestWorker_1 = require("./OtherRequestWorker");
const PhBalancedWorker_1 = require("./PhBalancedWorker");
const ProducerInformationWorker_1 = require("./ProducerInformationWorker");
const ScentWorker_1 = require("./ScentWorker");
const SheetFabricWorker_1 = require("./SheetFabricWorker");
const SheetShapeWorker_1 = require("./SheetShapeWorker");
const SunscreenTypeWorker_1 = require("./SunscreenTypeWorker");
const TargetBoxSetWorker_1 = require("./TargetBoxSetWorker");
const TargetBoxWorker_1 = require("./TargetBoxWorker");
const TargetContainerWorker_1 = require("./TargetContainerWorker");
const TargetCountriesToSellWorker_1 = require("./TargetCountriesToSellWorker");
const TargetCustomerAgeWorker_1 = require("./TargetCustomerAgeWorker");
const TargetCustomerGenderWorker_1 = require("./TargetCustomerGenderWorker");
const TargetDistributionChannelWorker_1 = require("./TargetDistributionChannelWorker");
const TargetLaunchDateWorker_1 = require("./TargetLaunchDateWorker");
class TemplateItemWorkers {
    static initialize(template, producer, product) {
        for (const templateItem of template.items) {
            this.initializeItem(templateItem, producer, product);
        }
    }
    static initializeItem(templateItem, producer, product) {
        for (const templateItemValidator of this.workers) {
            if (templateItemValidator.support(templateItem)) {
                templateItemValidator.initialize(templateItem, producer, product);
            }
        }
    }
    static validate(template, producer, product) {
        for (const templateItem of template.items) {
            this.validateItem(templateItem, producer, product);
        }
    }
    static validateItem(templateItem, producer, product) {
        for (const templateItemValidator of this.workers) {
            if (templateItemValidator.support(templateItem)) {
                templateItemValidator.validate(templateItem, producer, product);
            }
        }
    }
    static validateAsync(template, producer, product) {
        return __awaiter(this, void 0, void 0, function* () {
            this.validate(template, producer, product);
        });
    }
}
exports.TemplateItemWorkers = TemplateItemWorkers;
TemplateItemWorkers.workers = [
    new BubbleWorker_1.BubbleWorker(),
    new CapacityPerSheetWorker_1.CapacityPerSheetWorker(),
    new CapacityWorker_1.CapacityWorker(),
    new TargetBoxSetWorker_1.TargetBoxSetWorker(),
    new SheetFabricWorker_1.SheetFabricWorker(),
    new SheetShapeWorker_1.SheetShapeWorker(),
    new CleansingPowerWorker_1.CleansingPowerWorker(),
    new ColorWorker_1.ColorWorker(),
    new CompanyNameWorker_1.CompanyNameWorker(),
    new EwgWorker_1.EwgWorker(),
    new FinishLevelWorker_1.FinishLevelWorker(),
    new NoseStripWorker_1.NoseStripWorker(),
    new FormulationWorker_1.FormulationWorker(),
    new FunctionalityWorker_1.FunctionalityWorker(),
    new FeaturesWorker_1.FeatureWorkers(),
    new IngredientsWorker_1.IngredientsWorker(),
    new MoistureLevelWorker_1.MoistureLevelWorker(),
    new NameWorker_1.NameWorker(),
    new NonAdditivesWorker_1.NonAdditivesWorker(),
    new OrderQuantityWorker_1.OrderQuantityWorker(),
    new OtherRequestWorker_1.OtherRequestWorker(),
    new PhBalancedWorker_1.PhBalancedWorker(),
    new ProducerInformationWorker_1.ProducerInformationWorker(),
    new ScentWorker_1.ScentWorker(),
    new SunscreenTypeWorker_1.SunscreenTypeWorker(),
    new ContainerPumpingWorker_1.ContainerPumpingWorker(),
    new FabricColorWorker_1.FabricColorWorker(),
    new TargetBoxWorker_1.TargetBoxWorker(),
    new TargetContainerWorker_1.TargetContainerWorker(),
    new TargetCountriesToSellWorker_1.TargetCountriesToSellWorker(),
    new TargetCustomerAgeWorker_1.TargetCustomerAgeWorker(),
    new TargetCustomerGenderWorker_1.TargetCustomerGenderWorker(),
    new TargetDistributionChannelWorker_1.TargetDistributionChannelWorker(),
    new TargetLaunchDateWorker_1.TargetLaunchDateWorker(),
];
