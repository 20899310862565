import { observer } from 'mobx-react';
import React, { useCallback, useMemo } from 'react';

import { TemplateItem } from '@bringk/shared';

import CheckBoxes from 'src/components/CheckBoxes';
import useStore from 'src/hooks/useStore';

interface Props {
  templateItem: TemplateItem;
  selectableItems: any[];
}

const CheckBoxWrapper = ({ templateItem, selectableItems }: Props) => {
  const { orderStore } = useStore();
  const targetProperty = useMemo(() => templateItem.property, [templateItem]);

  const selectedItemKeys: string[] = orderStore.product[targetProperty] ?? [];

  const handleItemAdded = useCallback(
    (itemKey?: string) => {
      if (!itemKey) return;
      const newSelectedItemKeys = selectedItemKeys.concat(itemKey);
      orderStore.setProductProperty(targetProperty, newSelectedItemKeys);
    },
    [selectedItemKeys, targetProperty, orderStore],
  );

  const handleItemRemoved = useCallback(
    (itemKey?: string) => {
      const newSelectedItemKeys = selectedItemKeys.filter(prevValue => prevValue !== itemKey);
      orderStore.setProductProperty(targetProperty, newSelectedItemKeys);
    },
    [selectedItemKeys, targetProperty, orderStore],
  );

  return (
    <CheckBoxes {...{ selectedItemKeys, handleItemAdded, handleItemRemoved, selectableItems }} />
  );
};

export default observer(CheckBoxWrapper);
