import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames/bind';
import React, { useState, useCallback } from 'react';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface Props {
  selected: boolean;
  label: string;
  handleClicked?: () => void;
}

const Check = ({ selected, label, handleClicked }: Props) => {
  return (
    <div className={cx('container')} onClick={handleClicked}>
      <div className={cx('mark', { selected: selected })}>
        <FontAwesomeIcon
          className={cx('icon', { selected: selected })}
          icon={faCheck}
          size="1x"
          color="white"
        />
      </div>
      {label}
    </div>
  );
};

export default Check;
