"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const TemplateItemType_1 = require("../TemplateItemType");
const AbstractTemplateItemWorker_1 = require("./AbstractTemplateItemWorker");
const ValidationChainer_1 = require("./ValidationChainer");
class TargetLaunchDateWorker extends AbstractTemplateItemWorker_1.AbstractTemplateItemWorker {
    support(templateItem) {
        return templateItem.type === TemplateItemType_1.TemplateItemType.TARGET_LAUNCH_DATE;
    }
    initialize(templateItem, producer, product) {
        product.targetLaunchDate = 0;
    }
    validate(templateItem, producer, product) {
        ValidationChainer_1.ValidationChainer.property(product.targetLaunchDate)
            .propertyName('target launch date')
            .number();
        ValidationChainer_1.ValidationChainer.property(product.description['targetLaunchDate'])
            .string()
            .stringLimit();
    }
}
exports.TargetLaunchDateWorker = TargetLaunchDateWorker;
