import firebase from 'firebase';

import { CONSTANTS } from '@bringk/shared';

export default class FirebaseUtils {
  static async getTokenAndUser(): Promise<{
    token: string;
    currentUser: firebase.User;
  }> {
    const currentUser = firebase.auth().currentUser;
    if (!currentUser || !currentUser.uid) {
      throw new Error('current user is not exist');
    }
    const token = await currentUser.getIdToken(true);
    return {
      token,
      currentUser,
    };
  }

  static getDownloadURL(filePath: string, filePostFix = '') {
    const encodedUrl = encodeURIComponent(filePath + filePostFix);
    const mediaType = '?alt=media';
    return `https://firebasestorage.googleapis.com/v0/b/${CONSTANTS.BUCKET}/o/${encodedUrl}${mediaType}`;
  }
}
