import classNames from 'classnames/bind';
import { observer } from 'mobx-react';
import React, { useCallback, useMemo } from 'react';

import { TemplateItem } from '@bringk/shared';

import CheckBoxes from 'src/components/CheckBoxes';
import TextArea from 'src/components/TextArea';
import useFormat from 'src/hooks/useFormat';
import useStore from 'src/hooks/useStore';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface Props {
  templateItem: TemplateItem;
}

const TargetDistributionChannel = ({ templateItem }: Props) => {
  const { format, formatPrefix } = useFormat();
  const { orderStore } = useStore();
  const targetProperty = useMemo(() => templateItem.property, [templateItem]);
  const selectableOnlineItems = [
    {
      key: 'Internet',
      label: `${formatPrefix('Channel.', 'Internet')}`,
    },
    {
      key: 'HomeShopping',
      label: `${formatPrefix('Channel.', 'HomeShopping')}`,
    },
    {
      key: 'SNS',
      label: `${formatPrefix('Channel.', 'SNS')}`,
    },
  ];
  const selectableOfflineItems = [
    {
      key: 'H&B',
      label: `${formatPrefix('Channel.', 'H&B')}`,
    },
    {
      key: 'DepartmentStore',
      label: `${formatPrefix('Channel.', 'DepartmentStore')}`,
    },
    {
      key: 'BrandSoleStore',
      label: `${formatPrefix('Channel.', 'BrandSoleStore')}`,
    },
    {
      key: 'DoorToDoor',
      label: `${formatPrefix('Channel.', 'DoorToDoor')}`,
    },
    {
      key: 'Mart',
      label: `${formatPrefix('Channel.', 'Mart')}`,
    },
  ];

  const values: any[] = orderStore.product[targetProperty] ?? [];

  const handleItemAdded = useCallback(
    (itemKey?: string) => orderStore.setProductProperty(targetProperty, values.concat(itemKey)),
    [values, orderStore, targetProperty],
  );

  const handleItemRemoved = useCallback(
    (itemKey?: string) =>
      orderStore.setProductProperty(
        targetProperty,
        values.filter(prevValue => prevValue !== itemKey),
      ),
    [values, orderStore, targetProperty],
  );

  const handleTextAreaChanged = useCallback(
    event => {
      orderStore.setProductDescriptionProperty(targetProperty, event.currentTarget.value);
    },
    [targetProperty, orderStore],
  );

  return (
    <>
      <div>
        <div className={cx('channels')}>{formatPrefix('Channel.', 'Online')}</div>
        <CheckBoxes
          selectedItemKeys={values}
          handleItemAdded={handleItemAdded}
          handleItemRemoved={handleItemRemoved}
          selectableItems={selectableOnlineItems}
        />
      </div>
      <div>
        <div className={cx('channels')}>{formatPrefix('Channel.', 'Offline')}</div>
        <CheckBoxes
          selectedItemKeys={values}
          handleItemAdded={handleItemAdded}
          handleItemRemoved={handleItemRemoved}
          selectableItems={selectableOfflineItems}
        />
      </div>
      {templateItem.isDescriptable && (
        <TextArea
          value={orderStore.product.description[targetProperty]}
          onChange={handleTextAreaChanged}
          placeholder={format('Tooltips.OtherRequest')}
        />
      )}
    </>
  );
};

export default observer(TargetDistributionChannel);
