import { observer } from 'mobx-react';
import React, { useCallback } from 'react';

import { TemplateItem, TemplateItemType } from '@bringk/shared';

// import classNames from 'classnames/bind';

import TextArea from 'src/components/TextArea';
import useFormat from 'src/hooks/useFormat';
import useStore from 'src/hooks/useStore';

// import styles from './index.module.scss';

// const cx = classNames.bind(styles);

interface Props {
  templateItem: TemplateItem;
}

const Name = (props: Props) => {
  const { orderStore } = useStore();
  const { formatPrefix } = useFormat();
  const { type: targetType, property: targetProperty } = props.templateItem;
  const handleTextAreaChanged = useCallback(
    event => {
      orderStore.setProductProperty(targetProperty, event.currentTarget.value);
    },
    [targetProperty, orderStore],
  );
  const tooltipKey = targetType === TemplateItemType.COMPANY_NAME ? 'CompanyName' : 'ProductName';
  return (
    <TextArea
      value={orderStore.product[targetProperty] || ''}
      onChange={handleTextAreaChanged}
      placeholder={formatPrefix('Tooltips.', tooltipKey)}
    />
  );
};

export default observer(Name);
