"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const TemplateItemType_1 = require("../TemplateItemType");
const AbstractTemplateItemWorker_1 = require("./AbstractTemplateItemWorker");
const ValidationChainer_1 = require("./ValidationChainer");
class ColorWorker extends AbstractTemplateItemWorker_1.AbstractTemplateItemWorker {
    support(templateItem) {
        return templateItem.type === TemplateItemType_1.TemplateItemType.COLOR;
    }
    initialize(templateItem, producer, product) {
        product.contentColor = null;
        product.contentImage = null;
        product.contentRefBrand = null;
        product.contentRefProduct = null;
    }
    validate(templateItem, producer, product) {
        ValidationChainer_1.ValidationChainer.property(product.contentColor)
            .propertyName('content color')
            .stringOptional()
            .stringLimit();
        ValidationChainer_1.ValidationChainer.property(product.contentImage)
            .propertyName('content image')
            .stringOptional()
            .stringLimit();
        ValidationChainer_1.ValidationChainer.property(product.contentRefBrand)
            .propertyName('content reference brand')
            .stringOptional()
            .stringLimit();
        ValidationChainer_1.ValidationChainer.property(product.contentRefProduct)
            .propertyName('content reference product')
            .stringOptional()
            .stringLimit();
    }
}
exports.ColorWorker = ColorWorker;
