import React from 'react';

import { TemplateItem, StringUtils, TemplateItemType } from '@bringk/shared';

import ItemFormatter from 'src/pages/order/templates/ItemFormatter';
import AbstractTemplateItemSupporter from 'src/utils/template/AbstractTemplateItemSupporter';

import ColorSupporter from './template/ColorSupporter';
import CommonNameSupporter from './template/CommonNameSupporter';
import CommonSupporter from './template/CommonSupporter';
import FabricColorSupporter from './template/FabricColorSupporter';
import FeaturesSupporter from './template/FeaturesSupporter';
import IngredientsSupporter from './template/IngredientsSupporter';
import OrderQuantitySupporter from './template/OrderQuantitySupporter';
import OtherRequestSupporter from './template/OtherRequestSupporter';
import ProducerInformationSupporter from './template/ProducerInformationSupporter';
import TargetBoxSupporter from './template/TargetBoxSupporter';
import TargetContainerSupporter from './template/TargetContainerSupporter';
import TargetCountriesToSellSupporter from './template/TargetCountriesToSellSupporter';
import TargetCustomerAgeSupporter from './template/TargetCustomerAgeSupporter';
import TargetCustomerGenderSupporter from './template/TargetCustomerGenderSupporter';
import TargetDistributionChannelSupporter from './template/TargetDistributionChannelSupporter';
import TargetLaunchDateSupporter from './template/TargetLaunchDateSupporter';

export default class TemplateItemUtils {
  static readonly supporters: AbstractTemplateItemSupporter[] = [
    new ColorSupporter(),
    new CommonNameSupporter(),
    new CommonSupporter(),
    new FabricColorSupporter(),
    new FeaturesSupporter(),
    new IngredientsSupporter(),
    new OrderQuantitySupporter(),
    new OtherRequestSupporter(),
    new ProducerInformationSupporter(),
    new TargetBoxSupporter(),
    new TargetContainerSupporter(),
    new TargetCountriesToSellSupporter(),
    new TargetCustomerAgeSupporter(),
    new TargetCustomerGenderSupporter(),
    new TargetDistributionChannelSupporter(),
    new TargetLaunchDateSupporter(),
  ];

  static render(templateItem: TemplateItem | undefined): React.ReactNode {
    if (!templateItem) return <></>;
    return (
      TemplateItemUtils.supporters
        .find(supporter => supporter.support(templateItem.type))
        ?.render(templateItem) || <ItemFormatter templateItem={templateItem} />
    );
  }

  static renderSummary(templateItem: TemplateItem | null): React.ReactNode | null {
    if (!templateItem) {
      return null;
    }
    return TemplateItemUtils.supporters
      .find(supporter => supporter.support(templateItem.type))
      ?.renderSummary(templateItem);
  }

  static renderReceipt(templateItem: TemplateItem | null): React.ReactNode | null {
    if (!templateItem) {
      return null;
    }
    return TemplateItemUtils.supporters
      .find(supporter => supporter.support(templateItem.type))
      ?.renderReceipt(templateItem);
  }

  static sort(templateItems: TemplateItem[]): TemplateItem[] {
    return templateItems.sort((left, right) => {
      let leftIndex = TemplateItemUtils.supporters.findIndex(supporter =>
        supporter.support(left.type),
      );
      let rightIndex = TemplateItemUtils.supporters.findIndex(supporter =>
        supporter.support(right.type),
      );
      leftIndex = leftIndex === -1 ? Number.MAX_VALUE : leftIndex;
      rightIndex = rightIndex === -1 ? Number.MAX_VALUE : leftIndex;
      return leftIndex - rightIndex;
    });
  }

  private static getTitleKey(templateItemType: TemplateItemType): string {
    return templateItemType.split('_').map(StringUtils.capitalize).join('');
  }

  static getTitle(templateItemType: TemplateItemType): string {
    return `Order.Template.${this.getTitleKey(templateItemType)}.Title`;
  }

  static getShortTitle(templateItemType: TemplateItemType): string {
    return `Order.Template.${this.getTitleKey(templateItemType)}.ShortTitle`;
  }
}
