import classNames from 'classnames/bind';
import React, { useState, useCallback } from 'react';

import { ReactComponent as CheckSvg } from 'src/images/svg/icon_check_small.svg';
import { ReactComponent as FoldingHideSvg } from 'src/images/svg/icon_folding_hide.svg';
import { ReactComponent as FoldingShowSvg } from 'src/images/svg/icon_folding_show.svg';

import styles from './picker.module.scss';

const cx = classNames.bind(styles);

export interface Item {
  key: string;
  element?: JSX.Element;
}

interface Props {
  title: string;
  items: Item[];
  selectedKeys: string[];
  minWidth?: number;
  isMultipleChoice?: boolean;
  onClick?: (key: string) => void;
}

const IngredientPickerPickerComponent = ({
  title,
  items,
  selectedKeys,
  isMultipleChoice,
  minWidth = 160,
  onClick,
}: Props) => {
  const [isFold, setFold] = useState<boolean>(true);

  const toggleFold = useCallback(() => {
    setFold(fold => !fold);
  }, [setFold]);

  return (
    <div className={cx('container')} onMouseLeave={() => setFold(true)} style={{ minWidth }}>
      <div className={cx('box')}>
        <div className={cx('header')} onClick={toggleFold}>
          <div className={cx('title')}>{title}</div>

          <div className={cx('icon')}>{isFold ? <FoldingHideSvg /> : <FoldingShowSvg />}</div>
        </div>
        {!isFold && (
          <div className={cx('content')}>
            <div className={cx('border')} />
            {items.map(item => {
              const isSelected = selectedKeys.includes(item.key);
              return (
                <div key={item.key} className={cx('item')} onClick={() => onClick?.(item.key)}>
                  <div
                    className={cx(isMultipleChoice ? 'checkbox' : 'radio', {
                      selected: isSelected,
                    })}
                  >
                    {isMultipleChoice && <CheckSvg />}
                  </div>
                  {item.element}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default IngredientPickerPickerComponent;
