import React, { useContext } from 'react';

import { TemplateItem, TemplateItemType } from '@bringk/shared';

import ImageFailover from 'src/components/ImageFailover/index';
import ReceiptRecord from 'src/components/ReceiptRecord/index';
import useFirebaseStorage from 'src/hooks/useFirebaseStorage';
import useFormat from 'src/hooks/useFormat';
import KeyValueInfo from 'src/pages/order/summary/KeyValueInfo';
import ProducerInformation from 'src/pages/order/templates/ProducerInformation';
import AbstractTemplateItemSupporter from 'src/utils/template/AbstractTemplateItemSupporter';

import { OrderSourceContext } from './OrderSource';

const ProducerInformationSummaryComponent = () => {
  const { formatPrefix } = useFormat();
  const { producer } = useContext(OrderSourceContext);
  const contents = [
    { key: formatPrefix('Producer.', 'NameShort'), value: producer.name },
    { key: formatPrefix('Producer.', 'Email'), value: producer.email },
    { key: formatPrefix('Producer.', 'Country'), value: producer.country },
    { key: formatPrefix('Producer.', 'Telephone'), value: producer.telephone },
    {
      key: formatPrefix('Producer.', 'BusinessRegistrationNumber'),
      value: producer.businessRegistrationNumber,
    },
    {
      key: formatPrefix('Producer.', 'BusinessRegistrationNumberAttachment'),
      value: producer.businessRegistrationNumberAttachment,
    },
    {
      key: formatPrefix('Producer.', 'BusinessCardAttachment'),
      value: producer.businessCardAttachment,
    },
    {
      key: formatPrefix('Producer.', 'BusinessHomepage'),
      value: producer.businessHomepage,
    },
  ].filter(content => Boolean(content.value));
  return <KeyValueInfo contents={contents} />;
};

const ProducerInformationReceiptComponent = () => {
  const { formatPrefix } = useFormat();
  const { producer } = useContext(OrderSourceContext);
  const businessCardAttachmentUrl = useFirebaseStorage(producer.businessCardAttachment).url;
  const businessRegistrationNumberAttachment = useFirebaseStorage(
    producer.businessRegistrationNumberAttachment,
  ).url;

  const items: { key: string; value: any }[] = [
    { key: 'NameShort', value: producer.name },
    { key: 'Email', value: producer.email },
    { key: 'Country', value: producer.country },
    { key: 'Telephone', value: producer.telephone },
    { key: 'BusinessRegistrationNumber', value: producer.businessRegistrationNumber },
    {
      key: 'BusinessRegistrationNumberAttachment',
      value: <ImageFailover src={businessRegistrationNumberAttachment || undefined} />,
    },
    {
      key: 'BusinessCardAttachment',
      value: <ImageFailover src={businessCardAttachmentUrl || undefined} />,
    },
    { key: 'BusinessHomepage', value: producer.businessHomepage },
  ];

  return (
    <>
      {items.map(item => (
        <ReceiptRecord
          key={`producer-${item.key}`}
          label={formatPrefix('Producer.', item.key)}
          value={item.value}
        />
      ))}
    </>
  );
};

export default class ProducerInfomationSupporter extends AbstractTemplateItemSupporter {
  support(templateItemType: TemplateItemType): boolean {
    return templateItemType === TemplateItemType.PRODUCER_INFORMATION;
  }
  render(templateItem: TemplateItem): React.ReactNode {
    return <ProducerInformation {...{ templateItem }} />;
  }
  renderSummary(): React.ReactNode | null {
    return <ProducerInformationSummaryComponent />;
  }
  renderReceipt(): React.ReactNode | null {
    return <ProducerInformationReceiptComponent />;
  }
  getTitleId(): string {
    return 'Order.Template.ProducerInformation.Title';
  }
  getShortTitleId(): string {
    return 'Order.Template.ProducerInformation.ShortTitle';
  }
}
