import { StringUtils, ArrayUtils, Category } from '@bringk/shared';

export class CategoryUtils {
  private static readonly KEY_DELIMITER = '/';
  private static readonly VALUE_DELIMITER = '_';

  static isLast(category?: Category | null): boolean {
    return CategoryUtils.getChildren(category).length === 0;
  }

  static getDepth(category?: Category | null): number {
    if (!category) return 0;
    return StringUtils.count(category, CategoryUtils.KEY_DELIMITER) + 1;
  }

  static getChildren(parent?: Category | null): Category[] {
    if (!parent) return [Category.SKIN_CARE];

    const parentValue = parent!.valueOf();
    const parentDepth = StringUtils.count(parentValue, CategoryUtils.KEY_DELIMITER);
    return ArrayUtils.enumKeyStream(Category)
      .map(key => Category[key])
      .filter(child => {
        const childValue = child.valueOf();
        const childDepth = StringUtils.count(childValue, CategoryUtils.KEY_DELIMITER);
        return childValue.startsWith(parentValue) && childDepth === parentDepth + 1;
      });
  }

  static getParentsAndSelf(child?: Category | null): Category[] {
    let ref = child;
    const categories: Category[] = [];
    while (ref) {
      categories.push(ref);
      ref = this.getParent(ref);
    }
    return categories.reverse();
  }

  static getParent(child?: Category | null): Category | null {
    if (!child) return null;

    const components = child.valueOf().split('/');
    if (ArrayUtils.isEmpty(components)) return null;
    components.pop();
    const expectedParentValue = components.join('/');
    const parent = ArrayUtils.enumKeyStream(Category)
      .map(key => Category[key])
      .find(parent => {
        const parentValue = parent.valueOf();
        return expectedParentValue === parentValue;
      });
    return parent || null;
  }

  static getTopParent(child?: Category | null): Category | null {
    if (!child) return null;

    const components = child.valueOf().split('/');
    if (ArrayUtils.isEmpty(components)) return null;
    const expectedParentValue = components[0];
    const parent = ArrayUtils.enumKeyStream(Category)
      .map(key => Category[key])
      .find(parent => {
        const parentValue = parent.valueOf();
        return expectedParentValue === parentValue;
      });
    return parent || null;
  }

  static getUpperLevel(category: Category): Category | null {
    const levels = category.valueOf().split(CategoryUtils.KEY_DELIMITER);
    if (levels.length === 1) return null;
    const upperLevel = levels
      .slice(0, levels.length - 1)
      .reduce((accumulate, current) => accumulate + CategoryUtils.KEY_DELIMITER + current);
    const parent = ArrayUtils.enumKeyStream(Category)
      .map(key => Category[key])
      .find(category => category.valueOf() === upperLevel);
    return parent || null;
  }

  static parseAll(category: Category | null): Category[] {
    if (!category) return [];
    const result: Category[] = [];
    const levels = category.split(CategoryUtils.KEY_DELIMITER);
    for (let i = 1; i <= levels.length; i++) {
      const categoryItem = levels.slice(0, i).join(CategoryUtils.VALUE_DELIMITER);
      result.push(Category[categoryItem]);
    }
    return result;
  }
}
