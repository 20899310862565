import React, { useState, useCallback, useEffect } from 'react';

interface Props extends React.HTMLProps<HTMLImageElement> {
  width: number;
  height: number;
  failover: string;
}

const DEFAULT_FAILOVER_IMAGE = '/assets/not-found.png';

const ImageFailover = (props: Props) => {
  const [src, setSrc] = useState<string>(props.src || '');

  const onError = useCallback(() => {
    if (!props.failover) setSrc(DEFAULT_FAILOVER_IMAGE);
    else setSrc(props.failover);
  }, [props.failover]);

  useEffect(() => {
    setSrc(props.src || '');
  }, [props.src]);

  return (
    <img
      className={props.className}
      style={{ width: `${props.width}px`, height: `${props.height}px`, objectFit: 'contain' }}
      src={src}
      alt={props.alt}
      onError={onError}
    />
  );
};

ImageFailover.defaultProps = {
  width: 200,
  height: 200,
  failover: DEFAULT_FAILOVER_IMAGE,
};

export default ImageFailover;
