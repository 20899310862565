import { isString } from 'lodash';
import React, { useMemo } from 'react';

import { TemplateItem } from '@bringk/shared';

import useFormat from 'src/hooks/useFormat';

import CheckBoxWrapper from './CheckBoxesWrapper';
// import styles from './index.module.scss';
import RadioButtonsWrapper from './RadioButtonsWrapper';
import TextAreaWrapper from './TextAreaWrapper';

// const cx = classNames.bind(styles);

interface Props {
  templateItem: TemplateItem;
}

const ItemFormatter = ({ templateItem }: Props) => {
  const { format, formatKeyword } = useFormat();
  const selectableItems = useMemo(
    () =>
      templateItem.choiceValues?.map(choiceValue => {
        if (isString(choiceValue)) {
          return {
            key: choiceValue,
            label: formatKeyword(choiceValue),
            tooltip: templateItem.getTooltip(choiceValue),
          };
        } else {
          const { value, image } = choiceValue;
          return {
            key: value,
            image,
            label: formatKeyword(value),
            tooltip: templateItem.getTooltip(value),
          };
        }
      }) || [],
    [formatKeyword, templateItem],
  );

  return (
    <>
      {templateItem.isMultipleChoice ? (
        <CheckBoxWrapper {...{ templateItem, selectableItems }} />
      ) : (
        <RadioButtonsWrapper {...{ templateItem, selectableItems }} />
      )}
      {templateItem.isDescriptable && (
        <TextAreaWrapper {...{ templateItem }} placeholder={format('Tooltips.OtherRequest')} />
      )}
    </>
  );
};

export default ItemFormatter;
