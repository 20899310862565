import classNames from 'classnames/bind';
import { observer } from 'mobx-react';
import React, { useCallback } from 'react';
import { ColorResult } from 'react-color';

import { TemplateItem } from '@bringk/shared';

import ColorPallet from 'src/components/ColorPallet';
import ColorPickerComponent from 'src/components/ColorPicker';
import TextArea from 'src/components/TextArea';
import useFormat from 'src/hooks/useFormat';
import useStore from 'src/hooks/useStore';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface Props {
  templateItem: TemplateItem;
}

const Color = (props: Props) => {
  const targetProperty = props.templateItem.property;
  const { format } = useFormat();
  const { orderStore } = useStore();
  const color = orderStore.product[targetProperty] !== null && orderStore.product[targetProperty];

  const handleColorChanged = useCallback(
    (value: string) => {
      orderStore.setProductProperty(targetProperty, value);
    },
    [targetProperty, orderStore],
  );

  const handleColorPickerChanged = useCallback(
    (value: ColorResult) => {
      orderStore.setProductProperty(targetProperty, value.hex);
    },
    [targetProperty, orderStore],
  );

  const handleTextAreaChanged = useCallback(
    event => {
      orderStore.setProductDescriptionProperty(targetProperty, event.currentTarget.value);
    },
    [targetProperty, orderStore],
  );

  return (
    <div className={cx('container')}>
      <div className={cx('color-container')}>
        <div className={cx('color-picker')}>
          <ColorPickerComponent color={color} onChange={handleColorPickerChanged} />
          <ColorPallet onChange={handleColorChanged} />
        </div>
        <TextArea
          value={orderStore.product.description[targetProperty]}
          onChange={handleTextAreaChanged}
          placeholder={format('Tooltips.OtherRequest')}
        />
      </div>
    </div>
  );
};

export default observer(Color);
