import { getTimeZones } from '@vvo/tzdb';
import React, { useMemo, useState } from 'react';

import SearchBox from 'src/components/SearchBox/index';

interface OwnProps {
  timeZoneKey?: string | null;
  onTimeZoneChanged?: (timeZoneKey: string) => void;
}

const TimeZonePicker = ({ timeZoneKey, onTimeZoneChanged }: OwnProps) => {
  const [searchKeyword, setSearchKeyword] = useState<string>('');

  const timeZones = useMemo(() => getTimeZones(), []);

  const timeZone = useMemo(() => timeZones.find(timeZone => timeZone.name === timeZoneKey), [
    timeZones,
    timeZoneKey,
  ]);

  const filteredTimeZones = useMemo(
    () =>
      searchKeyword.length === 0
        ? timeZones
        : timeZones.filter(timeZone =>
            timeZone.currentTimeFormat.toLowerCase().includes(searchKeyword.toLowerCase()),
          ),
    [timeZones, searchKeyword],
  );

  return (
    <SearchBox
      items={filteredTimeZones.map(timeZone => ({
        label: timeZone.currentTimeFormat,
        key: timeZone.name,
      }))}
      searchKeyword={searchKeyword}
      placeholder={timeZone?.currentTimeFormat || 'Select your timeZone'}
      onSearchKeywordChanged={setSearchKeyword}
      onFoldChanged={isFold => isFold && setSearchKeyword('')}
      onClick={key => onTimeZoneChanged?.(key)}
    />
  );
};

export default TimeZonePicker;
