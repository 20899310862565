import React, { useContext } from 'react';

import { TemplateItem, TemplateItemType } from '@bringk/shared';

import ReceiptRecord from 'src/components/ReceiptRecord/index';
import useFormat from 'src/hooks/useFormat';
import TextInfo from 'src/pages/order/summary/TextInfo';
import OrderQuantity from 'src/pages/order/templates/OrderQuantity';
import AbstractTemplateItemSupporter from 'src/utils/template/AbstractTemplateItemSupporter';
import TemplateItemUtils from 'src/utils/TemplateItemUtils';

import { OrderSourceContext } from './OrderSource';

interface Props {
  templateItem: TemplateItem;
}

const OrderQuantitySummaryComponent = () => {
  const {
    product: { orderQuantity: value },
  } = useContext(OrderSourceContext);
  if (Boolean(value)) {
    return <TextInfo value={value} />;
  }
  return null;
};

const OrderQuantityReceiptComponent = ({ templateItem }: Props) => {
  const { format } = useFormat();
  const {
    product: {
      orderQuantity: value,
      description: { orderQuantity: description },
    },
  } = useContext(OrderSourceContext);
  const shortTitleId = TemplateItemUtils.getShortTitle(templateItem.type);
  return <ReceiptRecord label={format(shortTitleId)} value={value} description={description} />;
};

export default class OrderQuantitySupporter extends AbstractTemplateItemSupporter {
  support(templateItemType: TemplateItemType): boolean {
    return templateItemType === TemplateItemType.ORDER_QUANTITY;
  }
  render(templateItem: TemplateItem): React.ReactNode {
    return <OrderQuantity templateItem={templateItem} />;
  }
  renderSummary(): React.ReactNode | null {
    return <OrderQuantitySummaryComponent />;
  }
  renderReceipt(templateItem: TemplateItem): React.ReactNode | null {
    return <OrderQuantityReceiptComponent templateItem={templateItem} />;
  }
  getTitleId(): string {
    return 'Order.Template.OrderQuantity.Title';
  }
  getShortTitleId(): string {
    return 'Order.Template.OrderQuantity.ShortTitle';
  }
}
