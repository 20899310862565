import classNames from 'classnames/bind';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';

import Receipt from 'src/components/Receipt';
import useFormat from 'src/hooks/useFormat';
import useStore from 'src/hooks/useStore';
import OrderStepTitle from 'src/pages/order/title/index';
import { OrderSourceContext } from 'src/utils/template/OrderSource';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface Props {
  orderId: string;
}

const OrderHistoryPage = (props: RouteComponentProps<Props>) => {
  const orderId = props.match.params.orderId;
  const { format, formatPrefix } = useFormat();
  const { orderHistoryStore } = useStore();
  const [isFetchDone, setIsFetchDone] = useState<boolean>(false);

  const onModifyClick = useCallback(async () => {}, []);

  useEffect(() => {
    setIsFetchDone(false);
    orderHistoryStore.get(orderId).finally(() => setIsFetchDone(true));
  }, [orderId, orderHistoryStore]);

  console.log(orderHistoryStore);

  return (
    <div className={cx('container')}>
      {orderHistoryStore.id ? (
        <OrderSourceContext.Provider value={orderHistoryStore}>
          <div className={cx('receipt')}>
            <OrderStepTitle title={formatPrefix('Texts.', 'OrderHistory')} />
            <Receipt
              product={orderHistoryStore.product}
              producer={orderHistoryStore.producer}
              requiredTemplateItemTypes={orderHistoryStore.requiredTemplateItems.map(
                templateItem => templateItem.type,
              )}
              unrequiredTemplateItemTypes={orderHistoryStore.unrequiredTemplateItems.map(
                templateItem => templateItem.type,
              )}
            />
            {/* TODO: Next feature */}
            {/* <div className={cx('modify')}>
              <div className={cx('modify-button')} onClick={onModifyClick}>
                {format('Keywords.Modify')}
              </div>
            </div> */}
          </div>
        </OrderSourceContext.Provider>
      ) : isFetchDone ? (
        <div className={cx('not-found-order')}>{format('Texts.NotFoundOrderHistory')}</div>
      ) : (
        <Dimmer active={true}>
          <Loader size={'huge'} />
        </Dimmer>
      )}
    </div>
  );
};

export default observer(OrderHistoryPage);
