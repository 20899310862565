import classNames from 'classnames/bind';
import { observer } from 'mobx-react';
import React from 'react';

import useFormat from 'src/hooks/useFormat';
import useOrderTemplate, { StepValidationError } from 'src/hooks/useOrderTemplate';
import useStore from 'src/hooks/useStore';
import TemplateLayout from 'src/pages/order/templates/Layout/index';
import TemplateItemUtils from 'src/utils/TemplateItemUtils';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface Props {
  subStepId: number;
  validationError: StepValidationError | null;
}

const OrderStep02 = ({ subStepId, validationError }: Props) => {
  const stepId = 2;
  const { formatPrefix } = useFormat();
  const { orderStore } = useStore();
  const { getTemplateItemByStep, getSubStepMaxId } = useOrderTemplate(orderStore.product.category);
  const currentTemplateItem = getTemplateItemByStep(stepId, subStepId);
  return (
    currentTemplateItem && (
      <div className={cx('container')}>
        <TemplateLayout
          templateItem={currentTemplateItem}
          currentIndex={subStepId}
          lastIndex={getSubStepMaxId(stepId)}
          errorMessage={
            validationError &&
            formatPrefix('Errors.', validationError.messageId, validationError.messagePayload)
          }
        >
          {TemplateItemUtils.render(currentTemplateItem)}
        </TemplateLayout>
      </div>
    )
  );
};

export default observer(OrderStep02);
