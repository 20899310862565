import { isString } from 'lodash';
import { observer } from 'mobx-react';
// import classNames from 'classnames/bind';
import React, { useCallback } from 'react';

import { TemplateItem } from '@bringk/shared';

import RadioButtons from 'src/components/RadioButtons';
import TextArea from 'src/components/TextArea';
import useFormat from 'src/hooks/useFormat';
import useStore from 'src/hooks/useStore';

// import styles from './index.module.scss';

// const cx = classNames.bind(styles);

interface Props {
  templateItem: TemplateItem;
}

const OrderQuantity = ({ templateItem }: Props) => {
  const { format } = useFormat();
  const { orderStore } = useStore();
  const targetProperty = templateItem.property;
  const selectableItems = templateItem.choiceValues!.filter(isString).map(choiceValue => {
    return {
      key: choiceValue,
      label: `${choiceValue}${format('Unit.Numbering')}`,
      tooltip: templateItem.getTooltip(choiceValue),
    };
  });

  const handleItemSelected = useCallback(
    (itemKey?: string) => {
      orderStore.setProductProperty(targetProperty, itemKey);
    },
    [targetProperty, orderStore],
  );

  const handleTextAreaChanged = useCallback(
    event => {
      orderStore.setProductDescriptionProperty(targetProperty, event.currentTarget.value);
    },
    [targetProperty, orderStore],
  );

  return (
    <>
      <RadioButtons
        selectedItemKey={orderStore.product[targetProperty]}
        selectableItems={selectableItems}
        handleItemSelected={handleItemSelected}
      />
      <TextArea
        value={orderStore.product.description[targetProperty]}
        onChange={handleTextAreaChanged}
        placeholder={format('Tooltips.OtherRequest')}
      />
    </>
  );
};

export default observer(OrderQuantity);
