import classNames from 'classnames/bind';
import { observer } from 'mobx-react';
import React, { useCallback, useState } from 'react';

import { TemplateItem } from '@bringk/shared';

import Check from 'src/components/Check';
import CountrySelector from 'src/components/CountrySelector';
import ImageUploader from 'src/components/ImageUploader/index';
import InputText from 'src/components/InputText';
import Label from 'src/components/Label';
import PolicyModal from 'src/components/PolicyModal';
import useFormat from 'src/hooks/useFormat';
import useStore from 'src/hooks/useStore';
import CountryUtils from 'src/utils/CountryUtils';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface Props {
  templateItem: TemplateItem;
}

const ProducerInformation = (props: Props) => {
  const [policyModalOpen, setPolicyModalOpen] = useState<boolean>(false);
  const { formatPrefix } = useFormat();
  const { orderStore } = useStore();
  const { country } = orderStore.producer;

  const handlePolicyModalToggle = useCallback(() => {
    setPolicyModalOpen(value => !value);
  }, []);

  const handleProducerNameChanged = useCallback(
    event => {
      orderStore.setProducerProperty('name', event.currentTarget.value);
    },
    [orderStore],
  );

  const handleProducerEmailChanged = useCallback(
    event => orderStore.setProducerProperty('email', event.currentTarget.value),
    [orderStore],
  );

  const handleProducerTelephoneChanged = useCallback(
    event => {
      const telephone = event.currentTarget.value;
      if (country) {
        const countryEntity = CountryUtils.findByName(country);
        const dialCode = countryEntity ? countryEntity.countryCodes.join(' ') : '';
        if (!telephone.startsWith(`+${dialCode})`)) {
          return;
        }
      }
      orderStore.setProducerProperty('telephone', telephone);
    },
    [orderStore, country],
  );

  const handleProducerCountryChanged = useCallback(
    value => {
      orderStore.setProducerProperty('country', value);
      const countryEntity = CountryUtils.findByName(value);
      const dialCode = countryEntity ? countryEntity.countryCodes.join(' ') : '';
      orderStore.setProducerProperty('telephone', `+${dialCode}) `);
    },
    [orderStore],
  );

  const handleProducerAcceptanceChanged = useCallback(
    value => {
      orderStore.setProducerProperty('acceptance', value);
    },
    [orderStore],
  );

  const handleProducerBusinessRegistrationNumberChanged = useCallback(
    event =>
      orderStore.setProducerProperty('businessRegistrationNumber', event.currentTarget.value),
    [orderStore],
  );

  const handleProducerBusinessHomepageChanged = useCallback(
    event => orderStore.setProducerProperty('businessHomepage', event.currentTarget.value),
    [orderStore],
  );

  return (
    <div className={cx('container')}>
      <Label message={formatPrefix('Producer.', 'Name')} required={true} />
      <InputText value={orderStore.producer.name ?? ''} onChange={handleProducerNameChanged} />
      <Label message={formatPrefix('Producer.', 'Email')} required={true} />
      <InputText value={orderStore.producer.email ?? ''} onChange={handleProducerEmailChanged} />
      <div className={cx('tooltips-producer-email')}>
        {formatPrefix('Tooltips.', 'ProducerEmail')}
      </div>
      <Label message={formatPrefix('Producer.', 'Country')} required={true} />
      <CountrySelector
        selectedCountry={orderStore.producer.country ?? ''}
        onCountryChange={handleProducerCountryChanged}
      />
      <Label message={formatPrefix('Producer.', 'Telephone')} required={true} />
      <InputText
        value={orderStore.producer.telephone ?? ''}
        onChange={handleProducerTelephoneChanged}
      />
      <Label message={formatPrefix('Producer.', 'BusinessRegistrationNumber')} required={true} />
      <InputText
        value={orderStore.producer.businessRegistrationNumber ?? ''}
        onChange={handleProducerBusinessRegistrationNumberChanged}
      />
      <Label message={formatPrefix('Producer.', 'BusinessRegistrationNumberAttachment')} />
      <ImageUploader
        className={cx('image-uploader')}
        storagePath={orderStore.producer.businessRegistrationNumberAttachment}
        storagePathSuffix="/business-registrations/"
        onUploadSuccess={path =>
          orderStore.setProducerProperty('businessRegistrationNumberAttachment', path)
        }
        onUploadFailure={error => alert(error.toString())}
      />
      <Label message={formatPrefix('Producer.', 'BusinessCardAttachment')} required={true} />
      <ImageUploader
        className={cx('image-uploader')}
        storagePath={orderStore.producer.businessCardAttachment}
        storagePathSuffix="/business-cards/"
        onUploadSuccess={path => orderStore.setProducerProperty('businessCardAttachment', path)}
        onUploadFailure={error => alert(error.toString())}
      />
      <Label message={formatPrefix('Producer.', 'BusinessHomepage')} />
      <InputText
        value={orderStore.producer.businessHomepage ?? ''}
        onChange={handleProducerBusinessHomepageChanged}
      />
      <div className={cx('acceptance')}>
        <Check
          selected={orderStore.producer.acceptance}
          label={formatPrefix('Producer.', 'Acceptance')}
          handleClicked={() => {
            handlePolicyModalToggle();
            handleProducerAcceptanceChanged(false);
          }}
        />
        <PolicyModal
          open={policyModalOpen}
          handleAccept={() => handleProducerAcceptanceChanged(true)}
          handleToggle={handlePolicyModalToggle}
        />
      </div>
    </div>
  );
};

export default observer(ProducerInformation);
