import classNames from 'classnames/bind';
import React, { useState, useCallback, useEffect } from 'react';

import { SelectableItem } from '@bringk/shared';

import TooltipButton from 'src/components/TooltipButton';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface Props {
  selectedItemKey?: string;
  selectableItems: SelectableItem[];
  handleItemSelected?: (itemKey?: string) => void;
}

const RadioButtons = (props: Props) => {
  const [selectedItemKey, setSelectedItemKey] = useState<string>();
  const onClicked = useCallback(
    itemKey => {
      if (selectedItemKey === itemKey) {
        setSelectedItemKey(undefined);
        props.handleItemSelected?.(undefined);
      } else {
        setSelectedItemKey(itemKey);
        props.handleItemSelected?.(itemKey);
      }
    },
    [selectedItemKey, props.handleItemSelected],
  );

  useEffect(() => {
    setSelectedItemKey(props.selectedItemKey);
  }, [props.selectedItemKey]);

  return (
    <>
      {props.selectableItems.map(selectableItem => {
        const isSelected = selectedItemKey && selectedItemKey === selectableItem.key;
        return (
          <div
            key={selectableItem.label}
            className={cx('container', { selected: isSelected })}
            onClick={() => onClicked(selectableItem.key)}
          >
            <div className={cx('mark')}>
              <div className={cx('icon')} color="white" />
            </div>
            <div className={cx('label')}>{selectableItem.label}</div>
            {selectableItem.tooltip && <TooltipButton tooltips={[selectableItem.tooltip]} />}
          </div>
        );
      })}
    </>
  );
};

export default RadioButtons;
