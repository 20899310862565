import classNames from 'classnames/bind';
import React, { useMemo } from 'react';

import { TemplateItem } from '@bringk/shared';

import useFormat from 'src/hooks/useFormat';
import OrderStepPageNumber from 'src/pages/order/pageNumber';
import OrderStepTitle from 'src/pages/order/title';
import TemplateItemUtils from 'src/utils/TemplateItemUtils';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface Props extends React.HTMLProps<HTMLDivElement> {
  templateItem?: TemplateItem;
  currentIndex: number;
  lastIndex: number;
  errorMessage?: string;
}

const TemplateLayout = (props: Props) => {
  const { format } = useFormat();

  const title = useMemo(() => {
    if (!props.templateItem) {
      return null;
    }
    return format(TemplateItemUtils.getTitle(props.templateItem.type));
  }, [props.templateItem, format]);

  return (
    <div className={cx('container')}>
      <div className={cx('title')}>
        {title && <OrderStepTitle title={title} templateItem={props.templateItem} />}
      </div>
      <div className={cx('content')}>{props.children}</div>
      {props.errorMessage && <div className={cx('error-message')}>{props.errorMessage}</div>}
      <OrderStepPageNumber current={props.currentIndex} total={props.lastIndex} />
    </div>
  );
};

export default TemplateLayout;
