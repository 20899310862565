"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const TemplateItemType_1 = require("../TemplateItemType");
const AbstractTemplateItemWorker_1 = require("./AbstractTemplateItemWorker");
const ValidationChainer_1 = require("./ValidationChainer");
class TargetCustomerGenderWorker extends AbstractTemplateItemWorker_1.AbstractTemplateItemWorker {
    support(templateItem) {
        return templateItem.type === TemplateItemType_1.TemplateItemType.TARGET_CUSTOMER_GENDER;
    }
    initialize(templateItem, producer, product) {
        product.targetCustomerGender = '';
    }
    validate(templateItem, producer, product) {
        ValidationChainer_1.ValidationChainer.property(product.targetCustomerGender)
            .propertyName("target customer's gender")
            .string()
            .stringLimit();
    }
}
exports.TargetCustomerGenderWorker = TargetCustomerGenderWorker;
