import React, { useContext } from 'react';
import ReactCountryFlag from 'react-country-flag';

import { TemplateItem, ArrayUtils, TemplateItemType } from '@bringk/shared';

import ReceiptRecord from 'src/components/ReceiptRecord/index';
import useFormat from 'src/hooks/useFormat';
import TextInfo from 'src/pages/order/summary/TextInfo';
import TargetCountriesToSell from 'src/pages/order/templates/TargetCountriesToSell';
import CountryUtils from 'src/utils/CountryUtils';
import AbstractTemplateItemSupporter from 'src/utils/template/AbstractTemplateItemSupporter';
import TemplateItemUtils from 'src/utils/TemplateItemUtils';

import { OrderSourceContext } from './OrderSource';

interface Props {
  templateItem: TemplateItem;
}

const TargetCountriesToSellSummaryComponent: React.FC = ({ children }) => {
  if (!children) {
    return null;
  }
  return <TextInfo value={<>{children}</>} />;
};

const TargetCountriesToSellReceiptComponent: React.FC<Props> = ({ children, templateItem }) => {
  if (!children) {
    return null;
  }
  const { format } = useFormat();
  const {
    product: {
      description: { targetCountriesToSell: description },
    },
  } = useContext(OrderSourceContext);
  const shortTitleId = TemplateItemUtils.getShortTitle(templateItem.type);
  return (
    <ReceiptRecord label={format(shortTitleId)} value={<>{children}</>} description={description} />
  );
};

export default class TargetCountriesToSellSupporter extends AbstractTemplateItemSupporter {
  support(templateItemType: TemplateItemType): boolean {
    return templateItemType === TemplateItemType.TARGET_COUNTRIES_TO_SELL;
  }
  render(templateItem: TemplateItem): React.ReactNode {
    return <TargetCountriesToSell templateItem={templateItem} />;
  }
  private getComponents(): JSX.Element[] | null {
    const {
      product: { targetCountriesToSell: value },
    } = useContext(OrderSourceContext);
    if (ArrayUtils.isEmpty(value)) {
      return null;
    }
    return value.map(key => (
      <>
        {CountryUtils.findByISOCode2(key)?.countryName}{' '}
        <ReactCountryFlag
          countryCode={key}
          style={{
            fontSize: '1.3em',
            lineHeight: '1.3em',
          }}
        />
      </>
    ));
  }
  renderSummary(): React.ReactNode | null {
    return (
      <TargetCountriesToSellSummaryComponent>
        {this.getComponents()}
      </TargetCountriesToSellSummaryComponent>
    );
  }
  renderReceipt(templateItem: TemplateItem): React.ReactNode | null {
    return (
      <TargetCountriesToSellReceiptComponent templateItem={templateItem}>
        {this.getComponents()}
      </TargetCountriesToSellReceiptComponent>
    );
  }
}
