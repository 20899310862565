"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Product {
    // 특성별 상세 정보를 담기위한 객체
    // ex.
    // {
    //   color?: string;
    // }
    constructor(category) {
        this.category = category;
        this.targetCountriesToSell = [];
        this.targetDistributionChannel = [];
        this.targetCustomerGender = '';
        this.targetCustomerAge = [];
        this.targetLaunchDate = 0;
        this.orderQuantity = '';
        this.description = {};
    }
}
exports.Product = Product;
