import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Category } from '@bringk/shared';

import useOrderTemplate, { StepValidationError } from 'src/hooks/useOrderTemplate';
import useStore from 'src/hooks/useStore';
import { CategoryUtils } from 'src/utils/CategoryUtils';

interface Props {
  stepId: number;
  subStepId: number;
  candidateCategory?: Category | null;
}

const useOrderStep = (props?: Props) => {
  const { stepId = 1, subStepId = 0, candidateCategory } = props || {};
  const prefixPath = '/order';
  const history = useHistory();
  const { orderStore } = useStore();
  const { getSubStepMaxId, getSubStepValidationError } = useOrderTemplate(
    orderStore.product.category,
  );
  const [validationError, setValidationError] = useState<StepValidationError | null>(null);
  const productCategory = orderStore.product?.category || null;

  const setStepToHistory = useCallback(
    (stepId: number, subStepId: number) => {
      const path = [prefixPath, stepId.toString(), subStepId.toString()].join('/');
      history.push(path);
    },
    [history],
  );

  const setStep = useCallback(
    (stepId, subStepId) => {
      if (stepId === 1) {
        const depth = CategoryUtils.getDepth(candidateCategory || productCategory);
        if (subStepId >= 0) {
          setStepToHistory(stepId, Math.min(subStepId, depth));
        } else {
          setStepToHistory(stepId, depth + subStepId);
        }
      } else {
        if (subStepId >= 0) {
          setStepToHistory(stepId, subStepId);
        } else {
          setStepToHistory(stepId, getSubStepMaxId(stepId) + subStepId + 1);
        }
      }
    },
    [candidateCategory, productCategory, setStepToHistory, getSubStepMaxId],
  );

  const nextSubStep = useCallback(() => {
    if (stepId === 1) {
      if (candidateCategory) {
        if (
          CategoryUtils.getDepth(candidateCategory) === subStepId + 1 &&
          CategoryUtils.isLast(candidateCategory)
        ) {
          orderStore?.initialize(candidateCategory);
          setStep(stepId + 1, 0);
        } else {
          setStep(stepId, subStepId + 1);
        }
      }
    } else {
      const error = getSubStepValidationError(stepId, subStepId, orderStore);
      if (error) {
        console.error(error);
        setValidationError(error);
        return;
      } else if (subStepId < getSubStepMaxId(stepId)) {
        setStep(stepId, subStepId + 1);
      } else {
        setStep(stepId + 1, 0);
      }
    }
    setValidationError(null);
  }, [
    stepId,
    candidateCategory,
    subStepId,
    orderStore,
    setStep,
    getSubStepValidationError,
    getSubStepMaxId,
  ]);

  const prevSubStep = useCallback(() => {
    if (subStepId > 0) {
      setStep(stepId, subStepId - 1);
    } else if (stepId > 1) {
      setStep(stepId - 1, -1);
    }
    setValidationError(null);
  }, [stepId, subStepId, setStep, setValidationError]);

  const pageKey = useMemo(() => {
    if (stepId === 1) {
      const category = CategoryUtils.getParentsAndSelf(candidateCategory)[subStepId - 1];
      return `${category}:${stepId}:${subStepId}`;
    } else {
      return `${productCategory}:${stepId}:${subStepId}`;
    }
  }, [candidateCategory, productCategory, stepId, subStepId]);

  return {
    pageKey,
    productCategory,
    validationError,
    setStep,
    nextSubStep,
    prevSubStep,
  };
};

export default useOrderStep;
