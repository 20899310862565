import classNames from 'classnames/bind';
import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { Category } from '@bringk/shared';

import ScrollableContainer from 'src/components/ScrollableContainer/index';
import useOrderStep from 'src/hooks/useOrderStep';
import Navigation from 'src/pages/order/navigation/index';
import OrderStep01 from 'src/pages/order/step01';
import OrderStep02 from 'src/pages/order/step02';
import OrderStep03 from 'src/pages/order/step03';
import OrderStep04 from 'src/pages/order/step04';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface Props {
  stepId: number;
  subStepId: number;
}

const OrderSteps = ({ stepId, subStepId }: Props) => {
  const [candidateCategory, setCandidateCategory] = useState<Category | null>(null);
  const { pageKey, nextSubStep, prevSubStep, validationError } = useOrderStep({
    stepId,
    subStepId,
    candidateCategory,
  });

  const isFirst = stepId <= 1 && subStepId <= 0;
  const isLast = stepId >= 4;

  return (
    <div className={cx('container')}>
      <Navigation
        isFirst={isFirst}
        isFinal={isLast}
        stepId={stepId}
        subStepId={subStepId}
        handlePrevClick={prevSubStep}
        handleNextClick={nextSubStep}
      />
      <ScrollableContainer
        className={cx('scroll-container')}
        gradientColor={'#fafafa'}
        pageKey={pageKey}
      >
        <div className={cx('step')}>
          {!(2 <= stepId && stepId <= 4) && (
            <OrderStep01
              subStepId={subStepId}
              candidateCategory={candidateCategory}
              onCandidateCategoryChanged={setCandidateCategory}
            />
          )}
          {stepId === 2 && <OrderStep02 subStepId={subStepId} validationError={validationError} />}
          {stepId === 3 && <OrderStep03 subStepId={subStepId} validationError={validationError} />}
          {stepId === 4 && <OrderStep04 />}
        </div>
      </ScrollableContainer>
    </div>
  );
};

export default observer(OrderSteps);
