import React, { useCallback, useMemo, useState } from 'react';
import { Stage, Layer, Image, Rect } from 'react-konva';
import useImage from 'use-image';

import { getImagePaths as getBoxImagePaths } from 'src/utils/BoxImagesUtils';
import { getImagePaths as getContainerImagePaths } from 'src/utils/ContainerImagesUtils';

const DebugImageRects = () => {
  const lists: string[] = useMemo(() => [...getBoxImagePaths(), ...getContainerImagePaths()], []);

  const [offset, setOffset] = useState<number>(0);
  const [start, setStart] = useState<{ x: number; y: number } | null>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [end, setEnd] = useState<{ x: number; y: number } | null>(null);

  const imagePath = lists[offset];
  const [image] = useImage(imagePath);

  const handlePrev = useCallback(() => setOffset(offset => Math.max(0, offset - 1)), []);
  const handleNext = useCallback(() => {
    setOffset(offset => Math.min(lists.length - 1, offset + 1));
    setStart(null);
    setEnd(null);
  }, [lists, setStart, setEnd]);

  const handleMouseDown = useCallback(
    event => {
      const {
        evt: { layerX: x, layerY: y },
      } = event;
      const position = { x, y };
      setStart(position);
      setEnd(position);
      setIsDragging(true);
    },
    [setStart, setEnd, setIsDragging],
  );

  const handleMouseMove = useCallback(
    event => {
      const {
        evt: { layerX: x, layerY: y },
      } = event;
      const position = { x, y };
      if (!isDragging) {
        return;
      }
      setEnd(position);
      setIsDragging(true);
    },
    [isDragging, setEnd],
  );

  const { width = 0, height = 0 } = image || {};

  const minX = Math.min(start?.x || 0, end?.x || 0);
  const minY = Math.min(start?.y || 0, end?.y || 0);
  const maxX = Math.max(start?.x || 0, end?.x || 0);
  const maxY = Math.max(start?.y || 0, end?.y || 0);

  const rect = {
    x: minX,
    y: minY,
    width: maxX - minX,
    height: maxY - minY,
  };

  const handleMouseUp = useCallback(
    event => {
      console.log(`case ${imagePath}: return ${JSON.stringify(rect)};`);
      setIsDragging(false);
    },
    [setIsDragging, rect],
  );

  return (
    <div>
      <Stage {...{ width, height }}>
        <Layer>
          {/* <Rect {...{ x: 0, y: 0, width, height, stroke: 'black', strokeWidth: 1, fill: 'red' }} /> */}
          <Image
            x={0}
            y={0}
            image={image}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
          />
          <Rect
            {...rect}
            {...{
              stroke: 'black',
              strokeWidth: 1,
              fill: 'red',
              listening: false,
            }}
          />
        </Layer>
      </Stage>
      {start && (
        <>
          start x:{start.x} y:{start.y}
        </>
      )}
      {end && (
        <>
          end x:{end.x} y:{end.y}
        </>
      )}
      <div>
        <button onClick={handlePrev}>prev</button>
        <button onClick={handleNext}>next</button>
      </div>
    </div>
  );
};

export default DebugImageRects;
