import { RefObject, useCallback, useEffect } from 'react';

const useClickOutside = (ref: RefObject<HTMLElement>, onClickOutside: () => void) => {
  const handleClickOutside = useCallback(
    event => {
      if (ref.current?.contains(event.target) === false) {
        onClickOutside();
      }
    },
    [ref, onClickOutside],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchdown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchdown', handleClickOutside);
    };
  }, [handleClickOutside]);
};

export default useClickOutside;
