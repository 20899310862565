import classNames from 'classnames/bind';
import React from 'react';

import { ReactComponent as SearchSvg } from 'src/images/svg/icon_search.svg';

import styles from './searchBar.module.scss';

const cx = classNames.bind(styles);

interface Props {
  placeholder?: string;
  onChange?: (value: string) => void;
}

const IngredientPickerSearchBarComponent = ({ placeholder, onChange }: Props) => {
  return (
    <div className={cx('container')}>
      <div className={cx('icon')}>
        <SearchSvg />
      </div>
      <input
        className={cx('value')}
        placeholder={placeholder}
        onChange={event => onChange?.(event.target.value)}
      ></input>
    </div>
  );
};

export default IngredientPickerSearchBarComponent;
