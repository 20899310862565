import classNames from 'classnames/bind';
import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import ReactCountryFlag from 'react-country-flag';

import { SelectableItem, ArrayUtils } from '@bringk/shared';

import ScrollableContainer from 'src/components/ScrollableContainer/index';
import { ReactComponent as SearchSvg } from 'src/images/svg/icon_search.svg';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface OwnProps {
  placeholder?: string;
  useFlag?: boolean;
  items: SelectableItem[];
  searchKeyword?: string;
  onFoldChanged?: (isFold: boolean) => void;
  onSearchKeywordChanged?: (keyword: string) => void;
  onClick?: (key: string) => void;
}

type Props = OwnProps;

const SearchBox = ({
  placeholder,
  useFlag,
  items,
  searchKeyword,
  onFoldChanged,
  onSearchKeywordChanged,
  onClick,
}: Props) => {
  const [searchRect, setSearchRect] = useState<DOMRect>(new DOMRect());
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isFold, setIsFold] = useState<boolean>(true);

  const [isFocusing, setFocusing] = useState<boolean>(false);

  // isFold 변경점 notify
  useEffect(() => {
    onFoldChanged?.(isFold);
  }, [isFold, onFoldChanged]);

  // searchKeyword 와 input sync
  useEffect(() => {
    if (
      searchKeyword !== undefined &&
      inputRef.current &&
      inputRef.current.value !== searchKeyword
    ) {
      inputRef.current.value = searchKeyword || '';
    }
  }, [inputRef, searchKeyword]);

  const updateSearchRect = useCallback(() => {
    const input = inputRef.current;
    input && setSearchRect(input.getBoundingClientRect());
  }, [inputRef, setSearchRect]);

  // 0.3 초 포커스 잃을때까지는 창 유지
  useEffect(() => {
    if (isFocusing) {
      updateSearchRect();
      setIsFold(false);
    } else {
      const timerId = setTimeout(() => setIsFold(true), 300);
      return () => clearTimeout(timerId);
    }
  }, [isFocusing, setIsFold, updateSearchRect]);

  useEffect(() => {
    window.addEventListener('resize', updateSearchRect);
    return () => window.removeEventListener('resize', updateSearchRect);
  }, [updateSearchRect]);

  const handleSearchChanged = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget;
      onSearchKeywordChanged?.(value);
    },
    [onSearchKeywordChanged],
  );

  const handleClick = useCallback(
    key => () => {
      setIsFold(true);
      setFocusing(false);
      onClick?.(key);
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    },
    [onClick, setIsFold, setFocusing, inputRef],
  );

  const results = useMemo(
    () =>
      items.map(item => (
        <div key={item.key} className={cx('result')} onClick={handleClick(item.key)}>
          {item.label}
          {useFlag && (
            <>
              {' '}
              <ReactCountryFlag
                countryCode={item.key}
                style={{
                  fontSize: '1.5em',
                  lineHeight: '1.5em',
                }}
              />
            </>
          )}
        </div>
      )),
    [useFlag, items, handleClick],
  );

  const HEIGHT = 400;

  const isUpside = searchRect.y + searchRect.height + HEIGHT > window.innerHeight;
  const resultsStyle = isUpside
    ? {
        left: searchRect.x,
        top: searchRect.y - HEIGHT,
        width: searchRect.width,
        height: HEIGHT,
      }
    : {
        left: searchRect.x,
        top: searchRect.height + searchRect.y,
        width: searchRect.width,
        height: HEIGHT,
      };

  return (
    <div className={cx('container')}>
      <div className={cx('box')}>
        <SearchSvg />
        <div className={cx('input-box', { folded: isFold, upside: isUpside })}>
          <input
            ref={inputRef}
            onInput={handleSearchChanged}
            placeholder={placeholder}
            className={cx('search')}
            onFocus={() => setFocusing(true)}
            onBlur={() => setFocusing(false)}
          />
          {!isFold && (
            <div style={{ ...resultsStyle, position: 'absolute' }}>
              <ScrollableContainer containerClassName={cx('results')}>
                {results}
                {ArrayUtils.isEmpty(items) && <div className={cx('empty')}>No Result</div>}
              </ScrollableContainer>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchBox;
