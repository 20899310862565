import classNames from 'classnames/bind';
import debounce from 'lodash/debounce';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useRef } from 'react';
import { useRouteMatch } from 'react-router-dom';

import useFormat from 'src/hooks/useFormat';
import useOrderTemporarySave from 'src/hooks/useOrderTemporarySave';
import useStore from 'src/hooks/useStore';
import { CategoryUtils } from 'src/utils/CategoryUtils';
import { Phase } from 'src/utils/PhaseUtil';
import { OrderSourceContext } from 'src/utils/template/OrderSource';

import styles from './index.module.scss';
import OrderSteps from './steps';
import OrderSummary from './summary';

const cx = classNames.bind(styles);

const parseRangedNumber = (str: string, from: number, to: number) => {
  const value = Number(str);
  if (Number.isNaN(value)) {
    return from;
  } else {
    return Math.max(from, Math.min(to, value));
  }
};

const OrderPage = () => {
  const match = useRouteMatch<{ stepId: string; subStepId?: string }>(`/order/:stepId/:subStepId?`);
  const { stepId: stepIdStr = '', subStepId: subStepIdStr = '' } = match?.params || {};
  const stepId = parseRangedNumber(stepIdStr, 1, 4);
  const subStepId = parseRangedNumber(subStepIdStr, 0, 100);

  const { formatKeyword } = useFormat();
  const { orderStore } = useStore();
  const {
    hasData,
    saveData,
    loadData,
    clearData,
    goSavedPath,
    goFirstPath,
  } = useOrderTemporarySave();
  const { product, producer } = orderStore;

  const debounced = useRef(debounce(handler => handler(), 1000));

  useEffect(() => {
    if (!orderStore.product.category && hasData()) {
      const userConfirmed = Phase.isDev ? true : window.confirm(formatKeyword('HasTemporarySave'));
      if (userConfirmed) {
        loadData();
      } else {
        clearData();
        goFirstPath();
      }
    } else {
      if (hasData()) {
        goSavedPath();
      } else {
        goFirstPath();
      }
    }
  }, []);

  useEffect(() => {
    debounced.current(() => saveData(stepId, subStepId));
  }, [product, producer, stepId, subStepId, saveData]);

  // OrderSummary 렌더링 과정에 template 별 useFormat 을 사용중.
  // Category 변경시 hooks 순서 변경이 감지가 제대로 안되면서 페이지가 뻗는 문제가 발생.
  // 따라서 카테고리 선택화면에서는 Summary 를 렌더링 하지 않습니다.
  const isCategoryStep = stepId === 1;
  const isSummaryVisible = !isCategoryStep && CategoryUtils.isLast(product.category);
  return (
    <OrderSourceContext.Provider value={orderStore}>
      <div className={cx('container')}>
        <div className={cx('content')}>
          {isSummaryVisible && <OrderSummary stepId={stepId} subStepId={subStepId} />}
          <OrderSteps stepId={stepId} subStepId={subStepId} />
        </div>
      </div>
    </OrderSourceContext.Provider>
  );
};

export default observer(OrderPage);
