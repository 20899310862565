import React, { useContext } from 'react';

import { TemplateItem, TemplateItemType } from '@bringk/shared';

import ReceiptRecord from 'src/components/ReceiptRecord/index';
import useFormat from 'src/hooks/useFormat';
import TextInfo from 'src/pages/order/summary/TextInfo';
import TargetCustomerGender from 'src/pages/order/templates/TargetCustomerGender';
import AbstractTemplateItemSupporter from 'src/utils/template/AbstractTemplateItemSupporter';
import TemplateItemUtils from 'src/utils/TemplateItemUtils';

import { OrderSourceContext } from './OrderSource';

interface Props {
  templateItem: TemplateItem;
}

const TargetCustomerGenderSummaryComponent = () => {
  const { formatPrefix } = useFormat();
  const {
    product: { targetCustomerGender: value },
  } = useContext(OrderSourceContext);
  if (Boolean(value)) {
    return <TextInfo value={formatPrefix('Gender.', value)} />;
  }
  return null;
};

const TargetCustomerGenderReceiptComponent = ({ templateItem }: Props) => {
  const { format, formatPrefix } = useFormat();
  const {
    product: {
      targetCustomerGender: value,
      description: { targetCustomerGender: description },
    },
  } = useContext(OrderSourceContext);
  const shortTitleId = TemplateItemUtils.getShortTitle(templateItem.type);
  return (
    <ReceiptRecord
      label={format(shortTitleId)}
      value={formatPrefix('Gender.', value)}
      description={description}
    />
  );
};

export default class TargetCustomerGenderSupporter extends AbstractTemplateItemSupporter {
  support(templateItemType: TemplateItemType): boolean {
    return templateItemType === TemplateItemType.TARGET_CUSTOMER_GENDER;
  }
  render(templateItem: TemplateItem): React.ReactNode {
    return <TargetCustomerGender templateItem={templateItem} />;
  }
  renderSummary(): React.ReactNode | null {
    return <TargetCustomerGenderSummaryComponent />;
  }
  renderReceipt(templateItem: TemplateItem): React.ReactNode | null {
    return <TargetCustomerGenderReceiptComponent templateItem={templateItem} />;
  }
  getTitleId(): string {
    return 'Order.Template.TargetCustomerGender.Title';
  }
  getShortTitleId(): string {
    return 'Order.Template.TargetCustomerGender.ShortTitle';
  }
}
