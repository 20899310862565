"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const TemplateItemType_1 = require("../TemplateItemType");
const AbstractTemplateItemWorker_1 = require("./AbstractTemplateItemWorker");
const ValidationChainer_1 = require("./ValidationChainer");
class IngredientsWorker extends AbstractTemplateItemWorker_1.AbstractTemplateItemWorker {
    support(templateItem) {
        return templateItem.type === TemplateItemType_1.TemplateItemType.INGREDIENTS;
    }
    initialize(templateItem, producer, product) {
        product.mainConceptIngredients = null;
        product.subConceptIngredients = null;
    }
    validate(templateItem, producer, product) {
        ValidationChainer_1.ValidationChainer.property(product.mainConceptIngredients)
            .propertyName('Main concept ingredients')
            .stringArrayOptional()
            .stringLimit();
        ValidationChainer_1.ValidationChainer.property(product.subConceptIngredients)
            .propertyName('Sub concept ingredients')
            .stringArrayOptional()
            .stringLimit();
    }
}
exports.IngredientsWorker = IngredientsWorker;
